import { TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CURRENCIES } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";

interface SubcontractorOrderFileCreateSecondStepProps {
  control: any;
}

const SubcontractorOrderFileCreateThirdStep: React.FC<
  SubcontractorOrderFileCreateSecondStepProps
> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-4 max-w-xl">
      <Controller
        name="subcontractor_freight_rate"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.rate")}
            variant="outlined"
            className="w-64"
            type="number"
          />
        )}
      />
      <Controller
        name="subcontractor_currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("orderDetails.carrier.createOrderFile.currency")}
            variant="outlined"
            className="w-64"
          >
            {CURRENCIES.map((currencyOption) => (
              <MenuItem key={currencyOption} value={currencyOption}>
                {getCurrencySymbol(currencyOption)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="subcontractor_payment_days"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.dueDays")}
            variant="outlined"
            type="number"
            className="w-64"
          />
        )}
      />
    </div>
  );
};

export default SubcontractorOrderFileCreateThirdStep;
