import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import ExpenseForm from "./ExpenseForm";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ParsedExpensesDialog from "./ParsedExpensesDialog";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import ExpenseList from "../../components/Expenses/ExpenseList";
import DragDropInput from "../../components/input/DragDropInput";
import {
  ParsedExpenseFormData,
  RecurringDetail,
  ExpenseHistoryEntry,
  ExpenseBaseData,
} from "../../types/expenses";

const ExpensesContainer = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();
  const [dkvUploading, setDkvUploading] = useState<boolean>(false);
  const [dkvSubmitting, setDkvSubmitting] = useState<boolean>(false);
  const [addExpenseDialogOpen, setAddExpenseDialogOpen] =
    useState<boolean>(false);
  const [parsedExpenses, setParsedExpenses] = useState<ParsedExpenseFormData[]>(
    []
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [recurringExpenses, setRecurringExpenses] = useState<RecurringDetail[]>(
    []
  );
  const [expensesHistory, setExpensesHistory] = useState<ExpenseHistoryEntry[]>(
    []
  );
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [recurringLoading, setRecurringLoading] = useState<boolean>(false);

  const fetchHistory = async () => {
    try {
      setHistoryLoading(true);
      const response = await axios.get(`/expenses/history`);
      setExpensesHistory(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  };

  const fetchRecurring = async () => {
    try {
      setRecurringLoading(true);
      const response = await axios.get(`/expenses/recurring`);
      setRecurringExpenses(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setRecurringLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
    fetchRecurring();
  }, []);

  const onSubmit = async (data: ExpenseBaseData[]) => {
    setHistoryLoading(true);
    setRecurringLoading(true);
    try {
      await axios.post(`expenses/`, data);
      toast({
        message: "Expense added",
        type: "success",
      });
      fetchHistory();
      fetchRecurring();
    } finally {
      setHistoryLoading(false);
      setRecurringLoading(false);
    }
  };

  const deleteRecurringExpense = (id: number) => {
    axios
      .delete(`expenses/recurring/${id}/`)
      .then(() => {
        toast({
          message: "Expense deleted",
          type: "success",
        });
        fetchRecurring();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteExpenseHistory = (historyId: number) => {
    axios
      .delete(`expenses/history/${historyId}/`)
      .then(() => {
        toast({
          message: "Expense deleted",
          type: "success",
        });
        fetchHistory();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDkvUpload = async (file: File) => {
    setDkvUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `integrations/dkv/cost/import/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setParsedExpenses(response.data.parsed_data);
      setDialogOpen(true);
    } finally {
      setDkvUploading(false);
    }
  };

  const handleConfirmExpenses = async (
    selectedExpenses: ParsedExpenseFormData[]
  ) => {
    setDkvSubmitting(true);

    try {
      await axios.post(`expenses/bulk-create/`, {
        expenses: selectedExpenses,
      });
      fetchHistory();
    } finally {
      setDkvSubmitting(false);
    }

    setDialogOpen(false);
  };

  const executeRecurringExpense = async (id: number, data: any) => {
    setHistoryLoading(true);
    try {
      await axios.post(`expenses/recurring/${id}/execute/`, data);
      fetchHistory();
    } finally {
      setHistoryLoading(false);
    }
  };
  return (
    <div className="p-4 text-black dark:text-white">
      <Typography variant="h3" className="mb-8 text-center">
        {t("expenseList.title")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddExpenseDialogOpen(true)}
      >
        {t("expenseList.actions.addExpense")}
      </Button>
      <Dialog
        open={addExpenseDialogOpen}
        onClose={() => setAddExpenseDialogOpen(false)}
      >
        <DialogTitle>{t("expenseList.actions.addExpense")}</DialogTitle>
        <DialogContent>
          <ExpenseForm
            onSubmit={onSubmit}
            loading={historyLoading || recurringLoading}
          />
          <Divider className="my-4" />
          <div className="my-4">
            <DragDropInput
              uploading={dkvUploading}
              name={t("expenseList.importDkvExpenses")}
              types={["csv"]}
              handleChange={handleDkvUpload}
              label={t("expenseList.importDkvExpenses")}
              multiple={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddExpenseDialogOpen(false)}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <ExpenseList
        expensesHistory={expensesHistory}
        recurringExpenses={recurringExpenses}
        historyLoading={historyLoading}
        recurringLoading={recurringLoading}
        onDeleteRecurringExpense={deleteRecurringExpense}
        onDeleteExpenseHistory={deleteExpenseHistory}
        onExecuteRecurringExpense={executeRecurringExpense}
      />
      <ParsedExpensesDialog
        open={dialogOpen}
        expenses={parsedExpenses}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmExpenses}
        loading={dkvSubmitting}
      />
    </div>
  );
};

export default ExpensesContainer;
