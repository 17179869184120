import { useAuth } from "../../contexts/AuthContext";
import useWebSocket from "react-use-websocket";
import { useEffect, useState } from "react";
import { Button, InputAdornment, Skeleton, TextField } from "@mui/material";
import { FaPaperPlane } from "react-icons/fa";
import useAxios from "../../hooks/useAxios";
import { Conversation, Message } from "../../types/chat";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MessageBox from "./MdMessageBox";


interface OrderChatProps {
  title: string;
  orderId: string;
}

interface WebsocketMessage {
  type: string;
  message: Message;
}

const OrderChat: React.FC<OrderChatProps> = ({ orderId, title }) => {
  const { token } = useAuth();
  const [inputText, setInputText] = useState<string>("");
  const axios = useAxios();
  const [chatLoading, setChatLoading] = useState<boolean>(true);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<WebsocketMessage>(
      `${import.meta.env.VITE_PUBLIC_BACKEND_URL}ws/chat/${orderId}/`,
      {
        share: false,
        onOpen: () => console.log("WebSocket connection opened."),
        onClose: (event) => {
          console.log(event);
          console.log("WebSocket connection closed.");
        },
        onError: (event) => {
          console.error("WebSocket error:", event);
          setError("An error occurred while connecting to the chat server.");
        },
        shouldReconnect: () => true,
        protocols: token ? ["authorization", token] : [],
      }
    );

  useEffect(() => {
    axios.get(`chat/${orderId}`).then((response) => {
      setChatLoading(false);
      setConversation(response.data);
    });
  }, [orderId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setConversation((prev) => prev);
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [conversation?.messages.length]);

  const sendMessage = async () => {
    setMessageSending(true);
    const message = inputText.trim();
    if (message) {
      sendJsonMessage({ message });
      setInputText("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setInputText((prevMessage) => prevMessage + "\n");
    }
  };

  const handleIncomingMessage = (message: any) => {
    setMessageSending(false);
    const newMessages = [...(conversation?.messages || []), message];
    if (conversation) {
      setConversation({ ...conversation, messages: newMessages });
    }
  };

  const handleTextCreated = (message: any) => {
    console.log(message);
  };

  const handleTextDelta = (message: any) => {
    console.log(message);
  };

  const handleMessage = (message: any, type: string) => {
    switch (type) {
      case "chat_message":
        handleIncomingMessage(message);
        break;
      case "text_created":
        handleTextCreated(message);
        break;
      case "text_delta":
        handleTextDelta(message);
        break;
      default:
        console.log("Unknown message type:", type);
    }
  };

  useEffect(() => {
    if (lastJsonMessage) {
      console.log("Received message:", lastJsonMessage);
      const { type, message } = lastJsonMessage;
      handleMessage(message, type);
    }
  }, [lastJsonMessage]);

  const getPosition = (message: Message) => {
    if (message.user_type === "AN" && !token) {
      return "right";
    }
    if (message.user_type === "EM" && token) {
      return "right";
    }
    return "left";
  };

  const getTitle = (message: Message) => {
    if (message.user_type === "AN" && !token) {
      return t("chat.you");
    }
    if (message.user_type === "EM" && token) {
      return t("chat.you");
    }
    if (message.user_type === "EM") {
      return message.user?.name || t("chat.employee");
    }
    return t(`chat.${message.user_type}`);
  };

  return (
    <div className="p-4 min-h-screen max-h-screen bg-neutral-400 rounded-3xl flex flex-col justify-between">
      <h2 className="text-2xl font-semibold">{title}</h2>

      <div className="w-full overflow-y-auto">
        <div className="flex-grow  flex w-full flex-col">
          {conversation?.messages.map((message) => (
            <MessageBox
              key={message.uuid}
              position={getPosition(message)}
              title={getTitle(message)}
              date={new Date(message.created_at)}
              avatar={message.avatar}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {message.message}
              </ReactMarkdown>
            </MessageBox>
          ))}
          {error && <div className="p-4 text-red-500 font-bold">{error}</div>}
          {(chatLoading || readyState !== 1) && (
            <MessageBox
              position="left"
              title={t("chat.AI")}
              date={new Date()}
              avatar="https://www.routelink.ai/assets/img/avatars/avatar-ai.png"
            >
              <div className="flex flex-row">
                {" "}
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="90%" />
              </div>
            </MessageBox>
          )}
        </div>
        <div className="mt-4">
          <TextField
            label={t("chat.message")}
            fullWidth
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
            multiline
            minRows={1}
            maxRows={6}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendMessage}
                    disabled={messageSending || !inputText.trim()}
                  >
                    <span className="text-white">{t("chat.send")}</span>
                    <FaPaperPlane className="ml-2" />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderChat;
