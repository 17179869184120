import {
  Autocomplete,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import CarrierSelect from "../../../components/carrierSelect/CarrierSelect";
import { useAuth } from "../../../contexts/AuthContext";
import ErrorPage from "../../../error-page";
import { OrderCreatedByAi } from "../../../types/orders";
import { useStrider } from "../../../contexts/StriderContext";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Driver } from "../../../types/driver";
import { BaseTruck } from "../../../types/truck";
import { CURRENCIES } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";
import { useToast } from "../../../hooks/useToast";
import ShipperCreate from "../ShipperCreate";
import OrderCreateStepThree from "./OrderCreateStepThree";
import { FaMagnifyingGlass } from "react-icons/fa6";
import Modal from "../../../components/modal/Modal";
import PdfPreview from "../../../components/pdfPreview/PdfPreview";
import useAxios from "../../../hooks/useAxios";

interface OrderCreateWithAIProps {
  data: OrderCreatedByAi;
  control: any;
  setValue: any;
  watch: any;
  append: any;
  remove: any;
  fields: any;
  selectedCountry: string;
  selectedOperationIndex: number;
  setSelectedOperationIndex: any;
}

const OrderCreateWithAI: React.FC<OrderCreateWithAIProps> = ({
  data,
  control,
  setValue,
  watch,
  append,
  remove,
  fields,
  selectedCountry,
  selectedOperationIndex,
  setSelectedOperationIndex,
}) => {
  const [isShipperModalOpen, setShipperModalOpen] = useState(false);
  const toast = useToast();
  const { addShipper } = useStrider();
  const { t } = useTranslation();
  const { company, companyLoading } = useAuth();
  const { carriers } = useStrider();
  const axios = useAxios();

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [trucks, setTrucks] = useState<BaseTruck[]>([]);
  const selectedCarrier = watch("carrier_id");
  const ownOrder = watch("own_order");
  const currency = watch("payment.price_currency");
  const value = watch("payment.price_value");
  const estimatedDistance = watch("estimated_distance");
  const { shippers } = useStrider();
  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [currentTransportOrderFile, setCurrentTransportOrderFile] =
    useState<string>("");
  const [ratePerKm, setRatePerKm] = useState<string>("");

  useEffect(() => {
    if (companyLoading || !company) return;
    if (ownOrder) {
      setDrivers(company.drivers);
      setTrucks(company.trucks);
    } else {
      const carrier = carriers.find((c) => c.id === selectedCarrier);
      if (carrier) {
        setDrivers(carrier.drivers);
        setTrucks(carrier.trucks);
      }
    }
  }, [ownOrder, selectedCarrier, company, companyLoading, carriers]);

  useEffect(() => {
    if (!estimatedDistance) return;
    const rate = parseFloat(value);
    const distance = parseFloat(estimatedDistance);
    console.log(rate, distance);
    if (rate && distance) {
      const result = rate / distance;
      setRatePerKm(result.toFixed(2));
    }
  }, [estimatedDistance, watch, value]);

  const handleCarrierChange = (value: number, isCompany: boolean) => {
    if (isCompany) {
      setValue("carrier_id", null);
      setValue("own_order", true);
      if (data.is_own) {
        setValue("driver", data.driver_id);
        setValue("license_plate", data.truck_id || null);
      } else {
        setValue("driver", null);
        setValue("license_plate", null);
      }
    } else {
      setValue("carrier_id", value);
      setValue("own_order", false);
      const carrier = carriers.find((c) => c.id === value);
      if (carrier) {
        if (carrier.id === data.carrier_id) {
          setValue("driver", data.driver_id);
          setValue("license_plate", data.truck_id || null);
        } else {
          setValue("driver", null);
          setValue("license_plate", null);
        }
      }
    }
  };
  const onLoadPreviewPdf = async() => {
    const response = await axios.get(`orders/read-transport-file/${data.job_id}/`);
    setCurrentTransportOrderFile(response.data.file);
    setPreviewPdfVisible(true);
  };

  if (companyLoading) return <CircularProgress />;

  if (!company) return <ErrorPage />;

  return (
    <div>
      <Button
        variant="contained"
        className="flex items-center"
        onClick={() => onLoadPreviewPdf()}
      >
        <FaMagnifyingGlass />
        <span className="ml-1">Preview</span>
      </Button>
      <div className="p-4 grid grid-cols-2 gap-2">
        <Controller
          name="ref_number"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orders.orderCreate.refNumber")}
              variant="outlined"
              fullWidth
              required
            />
          )}
        />
        <CarrierSelect
          company={company}
          carriers={carriers}
          label={t("orders.selectCarrier")}
          size="medium"
          value={
            ownOrder
              ? `company-${company.id}`
              : data.carrier_id
              ? `carrier-${data.carrier_id}`
              : null
          }
          onChange={handleCarrierChange}
        />
        <Controller
          name="truck_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.carrier.selectTruck")}
              fullWidth
              variant="outlined"
            >
              {trucks.map((truck) => (
                <MenuItem key={truck.license_plate} value={truck.license_plate}>
                  {truck.license_plate}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="driver"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.carrier.selectDriver")}
              fullWidth
              variant="outlined"
            >
              {drivers.map((driver) => (
                <MenuItem key={driver.id} value={driver.id}>
                  {driver.first_name} {driver.last_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      <p>{t("orders.orderCreate.shipper")}</p>
      <div className="p-4 grid grid-cols-2 gap-2 w-full">
        <div className="flex flex-col items-center gap-2 w-full">
          <Controller
            name="shipper_id"
            control={control}
            render={({ field }) => {
              const selectedShipper =
                shippers.find((shipper) => shipper.id === field.value) || null;
              return (
                <Autocomplete
                  {...field}
                  value={selectedShipper}
                  options={shippers}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    field.onChange(newValue?.id || null);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("orders.orderCreate.shipper")}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  )}
                />
              );
            }}
          />
          <Button
            variant="contained"
            type="button"
            onClick={() => setShipperModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("orders.orderCreate.addShipper")}
          </Button>
        </div>
      </div>
      <p className="text-center text-xl font-bold">
        {t("orders.orderCreate.payment")}
      </p>
      <div className="p-4 grid grid-cols-2 gap-2">
        <div className="space-y-4">
          <Controller
            name="payment.price_currency"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("orders.orderCreate.currency")}
                variant="outlined"
                fullWidth
                required
              >
                {CURRENCIES.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {getCurrencySymbol(currency)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="payment.price_value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.rate")}
                variant="outlined"
                fullWidth
                type="number"
                required
              />
            )}
          />
          <Controller
            name="payment.days"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.dueDays")}
                variant="outlined"
                fullWidth
                required
                type="number"
              />
            )}
          />
        </div>
      </div>
      <p className="text-center text-xl font-bold">
        {t("orders.orderCreate.operations")}
      </p>
      <div>
        <OrderCreateStepThree
          control={control}
          append={append}
          remove={remove}
          fields={fields}
          selectedCountry={selectedCountry}
          selectedOperationIndex={selectedOperationIndex}
          setSelectedOperationIndex={setSelectedOperationIndex}
          setValue={setValue}
        />
      </div>
      <p className="text-center text-xl font-bold">
        {t("orders.orderCreate.summary")}
      </p>
      <div>
        <Controller
          name="estimated_distance"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orders.orderCreate.distance")}
              variant="outlined"
              fullWidth
              required
              type="number"
            />
          )}
        />
      </div>
      <div>
        <TextField
          label={t("orders.orderCreate.estRatePerKm")}
          sx={{ mb: 2 }}
          type="number"
          fullWidth
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          value={ratePerKm}
        />
      </div>
      <ShipperCreate
        prefillData={data.shipper}
        isShipperModalOpen={isShipperModalOpen}
        onCloseShipperModal={() => setShipperModalOpen(false)}
        onCreateShipper={async (data) => {
          const shipper = await addShipper(data);
          setValue("shipper_id", shipper.id);
          setShipperModalOpen(false);
          toast({
            message: "Shipper created successfully",
            type: "success",
          });
        }}
      />
      <Modal
        isOpen={previewPdfVisible}
        onClose={() => setPreviewPdfVisible(false)}
      >
        {previewPdfVisible && currentTransportOrderFile && (
          <PdfPreview
            fileUrl={currentTransportOrderFile}
            fileId={1}
          />
        )}
      </Modal>
    </div>
  );
};

export default OrderCreateWithAI;
