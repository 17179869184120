import { CircularProgress, TextField, InputAdornment, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller, FieldArrayWithId } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/useAxios";
import { getCurrencySymbol } from "../../../utils/currencies";
import { OrderFileTemplate } from "../../../types/templates";
import { OrderInfo } from "../../../types/orders";
import PdfPreview from "../../../components/pdfPreview/PdfPreview";

interface OrderCreateSummaryProps {
  order: OrderInfo;
  control: Control<any, any>;
  fields: FieldArrayWithId<any, "operations", "id">[];
  getValues: any;
  setValue: any;
  watch: any;
}

const SubcontractorOrderFileCreateSummary: React.FC<
  OrderCreateSummaryProps
> = ({ order, control, fields, getValues, setValue, watch }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxios();
  const [ratePerKm, setRatePerKm] = useState<string>("");

  const [htmlPreviewUrl, setPdfPreviewUrl] = useState<string | null>(null);

  const onPreview = () => {
    const data = getValues();
    axios
      .post(`orders/${order.id}/subcontractor-transport-files/preview`, data)
      .then((response) => {
        console.log(response.data);
        setPdfPreviewUrl(response.data.url);
      });
  }

  const [orderFileTemplates, setOrderFileTemplates] = useState<
    OrderFileTemplate[]
  >([]);

  useEffect(() => {
    const coordinates = getValues("operations").map((operation: any) => {
      return {
        latitude: operation.latitude,
        longitude: operation.longitude,
      };
    });

    if (coordinates.length < 2) {
      setLoading(false);
      return;
    }

    const allCoordinatesValid = coordinates.every(
      (coord: any) => coord.latitude != null && coord.longitude != null
    );

    if (!allCoordinatesValid || coordinates.length < 2) {
      setLoading(false);
      return;
    }

    const fetchDistance = async () => {
      try {
        const response = await axios.post(`route/calculate/`, {
          coordinates,
        });
        const routes = response.data.routes;
        if (routes.length === 0) return;
        const distance = routes[0].summary.distance;
        const formattedDistance = (distance / 1000).toFixed(1);
        setValue("estimated_distance", formattedDistance);
      } catch (error) {
        console.error("Error fetching distance:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistance();
  }, [fields]);

  useEffect(() => {
    const fetchOrderFileTemplates = async () => {
      try {
        const response = await axios.get("template-manager/order-file/");
        const data = response.data;
        setOrderFileTemplates(data);
        if (data.length > 0) {
          setValue("order_file_template_id", data[0].id);
        }
        
      } catch (error) {
        console.error("Error fetching order file templates:", error);
      }
    };

    fetchOrderFileTemplates();
  }, []);

  const value = watch("subcontractor_freight_rate");
  const currency = watch("subcontractor_currency");
  const estimatedDistance = watch("estimated_distance");

  useEffect(() => {
    if (value && currency && estimatedDistance) {
      setRatePerKm((value / estimatedDistance).toFixed(2));
    }
  }, [value, currency, estimatedDistance]);

  return (
    <div>
      {!loading ? (
        <div>
          <Controller
            name="estimated_distance"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.estDistance")}
                sx={{ mb: 2 }}
                type="number"
                fullWidth
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">km</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            )}
          />
          <TextField
            label={t("orders.orderCreate.estRatePerKm")}
            sx={{ mb: 2 }}
            type="number"
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            value={ratePerKm}
          />
          <Controller
            name="order_file_template_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.fileTemplate")}
                select
                fullWidth
                sx={{ mb: 2 }}
              >
                {orderFileTemplates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </TextField>
            )}
          />
          <Button onClick={onPreview} variant="contained" type="button">
            {t("orders.orderCreate.preview")}
          </Button>
          {htmlPreviewUrl && (
            <div className="mt-4 items-center justify-center w-full bg-black">
              <PdfPreview fileUrl={htmlPreviewUrl} fileId={order.id} />
            </div>
          )}
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default SubcontractorOrderFileCreateSummary;
