import { Controller, useForm } from "react-hook-form";
import { OrderInfo } from "../../types/orders";
import { Button, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { hasAccess } from "../../utils/permissions";
interface RequirementsFormProps {
  order: OrderInfo;
  onFormSubmit: (data: any) => void;
}

const RequirementsForm: React.FC<RequirementsFormProps> = ({
  order,
  onFormSubmit,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      requirements: {
        type: order.requirements?.type || "",
        remarks: order.requirements?.remarks || "",
        monitoring: order.requirements?.monitoring || "",
        capacity_unit: order.requirements?.capacity_unit || "t",
        capacity_value: order.requirements?.capacity_value || 0,
      },
    },
  });

  const { account } = useAuth();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="grid grid-cols-5 gap-4 py-2">
        <Controller
          name="requirements.type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.requirements.type")}
              variant="outlined"
              className="w-full"
              disabled={!hasAccessForUpdate}
            />
          )}
        />
        <Controller
          name="requirements.remarks"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.requirements.remarks")}
              variant="outlined"
              className="w-full"
            />
          )}
        />
        <Controller
          name="requirements.monitoring"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.requirements.monitoring")}
              variant="outlined"
              className="w-full"
              disabled={!hasAccessForUpdate}
            />
          )}
        />
        <div className="grid grid-cols-4 items-center gap-2">
          <Controller
            name="requirements.capacity_value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orderDetails.requirements.capacityValue")}
                variant="outlined"
                className="col-span-3"
                disabled={!hasAccessForUpdate}
              />
            )}
          />
          <Controller
            name="requirements.capacity_unit"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orderDetails.requirements.capacityUnit")}
                variant="outlined"
                className="w-full"
                select
                disabled={!hasAccessForUpdate}
              >
                <MenuItem value="t">T</MenuItem>
              </TextField>
            )}
          />
        </div>
      </div>
      {
        hasAccessForUpdate &&
        <Button className="my-2" type="submit" variant="contained">
          {t("common.save")}
        </Button>
      }
    </form>
  );
};

export default RequirementsForm;
