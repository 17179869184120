import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";

interface InvitationFormData {
  email: string;
  password: string;
  confirm_password: string;
}

const InvitationContainer = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const axios = useAxios();
  const { control, handleSubmit, setValue, setError } =
    useForm<InvitationFormData>();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const { onSetToken } = useAuth();

  const onSubmit = handleSubmit((data) => {
    if (data.password !== data.confirm_password) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }

    axios
      .put(`/account/invitations/${token}/`, data)
      .then((res) => {
        setAuthToken(res.data.auth_token);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  useEffect(() => {
    axios
      .get(`/account/invitations/${token}/`)
      .then((res) => {
        setValue("email", res.data.email);
      })
      .catch(() => {
        console.log("Invalid token");
        setTimeout(() => {
          navigate("/sign-in", { replace: true });
        }, 5000);
      });
  }, [token]);

  useEffect(() => {
    if (authToken) {
      onSetToken(authToken);
      navigate("/", { replace: true });
    }
  }, [authToken]);

  if (!token) {
    return (
      <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-primary">
        <h1 className="font-semibold text-4xl mb-16 text-center text-white">
          {t("invitation.invalidInvitation")}
        </h1>
        <Link to="/sign-in" className="text-white">
          {t("invitation.signIn")}
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-gradient-prime">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl bg-white rounded-xl">
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="font-semibold text-4xl mb-16 text-center text-black">
              {t("invitation.title")}
            </h1>
            <div className="flex flex-col gap-4 my-4 w-full">
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("invitation.email")}
                      variant="outlined"
                      className="w-full"
                      disabled
                      autoComplete="username"
    
                    />
                  )}
                />
              </div>
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("invitation.password")}
                      variant="outlined"
                      className="w-full"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <Controller
                  name="confirm_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("invitation.confirmPassword")}
                      variant="outlined"
                      className="w-full"
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
              <Button variant="contained" type="submit" className="w-full py-2">
                {t("invitation.acceptInvitation")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvitationContainer;
