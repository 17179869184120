import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import MonthlyIncomesGraph from "./MonthlyIncomesGraph";
import { Skeleton } from "@mui/material";
import CountryIncomes from "./CountryIncomes";
import CabotageIncomes from "./CabotageIncomes";
import TruckIncomes from "./TruckIncomes";
import ShipperIncomes from "./ShipperIncomes";
import ContractCarrierIncomes from "./ContractCarrierIncomes";
import IncomesGraph from "./IncomesGraph";
import MonthlyPerKmIncomes from "./MonthlyPerKmIncomes";
import AverageIncomePerTruckType from "./AverageIncomePerTruckType";
import { format } from "date-fns-tz";
import AverageKmIncomePerTruckType from "./AverageKmIncomePerTruckType";
import CountryIncomesPerKm from "./CountryIncomesPerKm";

interface IncomesReport {
  monthly_incomes: any;
  country_incomes: any;
  cabotage_incomes: any;
  truck_incomes: any;
  shipper_incomes: any;
  contract_carrier_incomes: any;
  country_incomes_per_km: any;
  fleet_income: {
    total_income: number;
    per_km_income: number;
    avg_incomes_per_truck_type: any;
    monthly_per_km_incomes: any;
    avg_km_incomes_per_truck_type: any;
  };
  income: {
    total_income: number;
    avg_order_income: number;
  };
}

interface IncomesComponentProps {
  startDate: Date;
  endDate: Date;
}

const IncomesComponent: React.FC<IncomesComponentProps> = ({
  startDate,
  endDate,
}) => {
  const [incomesReport, setIncomesReport] = useState<IncomesReport | null>(
    null
  );
  const axios = useAxios();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/insights/reports/incomes", {
        params: {
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => {
        setIncomesReport(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  if (loading || !incomesReport) {
    return (
      <div className="grid grid-cols-1">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <IncomesGraph
        fleet_income={incomesReport.fleet_income}
        income={incomesReport.income}
      />
      <MonthlyIncomesGraph monthlyIncomes={incomesReport.monthly_incomes} />
      <div className="col-span-2">
        <CountryIncomes country_incomes={incomesReport.country_incomes} />
      </div>
      <div className="col-span-2">
        <CountryIncomesPerKm country_incomes={incomesReport.country_incomes_per_km} />
      </div>
      <CabotageIncomes country_incomes={incomesReport.cabotage_incomes} />
      <TruckIncomes truck_incomes={incomesReport.truck_incomes} />
      <div className="col-span-2">
        <ShipperIncomes shipper_incomes={incomesReport.shipper_incomes} />
      </div>
      <ContractCarrierIncomes
        contract_carrier_incomes={incomesReport.contract_carrier_incomes}
      />
      <AverageIncomePerTruckType
        avg_incomes_per_truck_type={
          incomesReport.fleet_income.avg_incomes_per_truck_type
        }
      />
      <AverageKmIncomePerTruckType
        avg_incomes_per_truck_type={
          incomesReport.fleet_income.avg_km_incomes_per_truck_type
        }
      />
      <MonthlyPerKmIncomes
        monthlyIncomes={incomesReport.fleet_income.monthly_per_km_incomes}
      />
    </div>
  );
};

export default IncomesComponent;
