import { Button } from "@mui/material";
import successImage from "../../assets/logo_full.webp";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-prime">
      <img
        src={successImage}
        alt="Subscription Successful"
        className="w-1/4 mb-8"
      />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        {t("subscriptionSuccess.successful")}
      </h1>
      <p className="text-gray-600 text-lg mb-4">
        {t("subscriptionSuccess.thankYou")}
      </p>
      <p className="text-gray-600 text-lg mb-4">
        {t("subscriptionSuccess.redirect")} {countdown}{" "}
        {t("subscriptionSuccess.seconds")}.
      </p>
      <Button onClick={() => navigate("/")}>
        {t("subscriptionSuccess.goToDashboard")}
      </Button>
    </div>
  );
};

export default SubscriptionSuccess;
