import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddDriverForm from "./AddDriverForm";
import { Link } from "react-router-dom";
import { Driver, DRIVER_STATUS_FILTER_OPTIONS } from "../../types/driver";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import { FaEdit } from "react-icons/fa";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { FaTrash } from "react-icons/fa6";

interface DriversProps {
  link: string;
  disableStatusFilter?: boolean;
}

const Drivers: React.FC<DriversProps> = ({
  link,
  disableStatusFilter = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsModalOpen] = useState(false);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const axios = useAxios();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const [selectedDriverStatuses, setSelectedDriverStatuses] = useLocalStorage(
    "selectedDriverStatuses",
    ["active", "candidate", "fired", "resigned"]
  );

  const onAddDriver = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(`${link}/`, data);
      const newEmployee = response.data;
      setDrivers((prevDrivers) => [...prevDrivers, newEmployee]);
      toast({
        message: "Driver added",
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddDriver = async (data: any) => {
    await onAddDriver(data);
    setIsModalOpen(false);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const params = new URLSearchParams();
        if (!disableStatusFilter) {
          params.append("status", selectedDriverStatuses.join(","));
        }
        const response = await axios.get(link, { params });
        setDrivers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDrivers();
  }, [link, selectedDriverStatuses]);

  const handleSelectChange = (event: any) => {
    const selectedValues = (event.target.value as string[]).map(
      (value: string) => value
    );
    setSelectedDriverStatuses(selectedValues);
  };

  const translatedDriverStatusOptions = DRIVER_STATUS_FILTER_OPTIONS.map(
    (status) => ({
      value: status,
      label: t(`driversList.status.${status}`),
    })
  );

  const onUpdateDriverStatus = async (id: number, status: string) => {
    try {
      await axios.patch(`${link}/${id}/`, { status });
      setDrivers((prevDrivers) =>
        prevDrivers.map((driver) =>
          driver.id === id ? { ...driver, status } : driver
        )
      );
      toast({
        message: "Driver status updated",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      toast({
        message: "Failed to update driver status",
        type: "error",
      });
    }
  };

  const onDeleteDriver = async (id: number) => {
    try {
      await axios.delete(`${link}/${id}`);
      if (!selectedDriverStatuses.includes("inactive")) {
        setDrivers((prevDrivers) =>
          prevDrivers.filter((driver) => driver.id !== id)
        );
      }
      toast({
        message: "Driver deleted",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      toast({
        message: "Failed to delete driver",
        type: "error",
      });
    }
  };

  return (
    <div className="p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center my-2">
        <Typography variant="h5" component="h2" gutterBottom>
          {t("driversList.title")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {" "}
          <span className="mr-2">+</span>
          {t("driversList.addDriver")}
        </Button>
      </div>

      {!disableStatusFilter && (
        <TextField
          select
          label={t("driversList.driverStatus")}
          fullWidth
          variant="outlined"
          value={selectedDriverStatuses}
          SelectProps={{
            multiple: true,
            value: selectedDriverStatuses,
            onChange: handleSelectChange,
            renderValue: (selected: any) => (
              <div className="flex flex-wrap">
                {selected.map((value: any) => (
                  <span
                    key={value}
                    className="mr-2 bg-gray-200 dark:bg-gray-700 py-1 px-2 rounded"
                  >
                    {t(`driversList.status.${value}`)}
                  </span>
                ))}
              </div>
            ),
          }}
        >
          {translatedDriverStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} dense>
              {t(`driversList.status.${option.value}`)}
            </MenuItem>
          ))}
        </TextField>
      )}

      {drivers.length > 0 ? (
        <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full my-2">
          <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
            <th className="px-4 py-2 text-left" style={{ width: "35%" }}>
              {t("driversList.tableHeader.name")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "35%" }}>
              {t("driversList.tableHeader.email")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "20%" }}>
              {t("driversList.tableHeader.phone")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "20%" }}></th>
          </thead>{" "}
          <tbody>
            {drivers.map((driver) => (
              <tr
                key={driver.id}
                className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
              >
                <td className="px-1 py-3 text-left text-sm">
                  {driver.full_name}
                </td>
                <td className="px-1 py-3 text-left text-sm">{driver.email}</td>
                <td className="px-1 py-3 text-left text-sm">{driver.phone}</td>
                <td className="px-1 py-3 text-left text-sm">
                  <div className="flex">
                    {!disableStatusFilter && (
                      <TextField
                        select
                        label={t("driversList.driverStatus")}
                        value={driver.status}
                        onChange={(e) =>
                          onUpdateDriverStatus(driver.id, e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {translatedDriverStatusOptions.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    <Link
                      to={`${link}/${driver.id}`}
                      className="flex items-center justify-end self-end w-full"
                    >
                      <IconButton>
                        <FaEdit />
                      </IconButton>
                    </Link>
                    <IconButton onClick={() => onDeleteDriver(driver.id)}>
                      <FaTrash />
                    </IconButton>
                  </div>
                </td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography variant="body1" className="my-2">
          {t("driversList.noDrivers")}
        </Typography>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("driversList.addDriverModal.title")}</DialogTitle>
        <DialogContent>
          <AddDriverForm onSubmit={handleAddDriver} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Drivers;
