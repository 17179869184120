import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import { OrderFileTemplate } from "../../types/templates";
import CreateTemplateForm from "./CreateTemplateForm";
import PureTemplateEditor from "./PureContentEditor";
import PdfPreview from "../../components/pdfPreview/PdfPreview";

const OrderFileTemplates = () => {
  const [orderFileTemplates, setOrderFileTemplates] = useState<
    OrderFileTemplate[]
  >([]);

  const [htmlPreviewUrl, setPdfPreviewUrl] = useState<string | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const axios = useAxios();

  useEffect(() => {
    axios.get("/template-manager/order-file").then((response) => {
      setOrderFileTemplates(response.data);
    });
  }, []);

  const { t } = useTranslation();
  const [openedTemplate, setOpenedTemplate] =
    useState<OrderFileTemplate | null>(null);

  const handleSave = (data: any, templateId: number) => {
    axios
      .patch(`/template-manager/order-file/${templateId}/`, data)
      .then(() => {
        onCloseTemplate();
        setOrderFileTemplates((prev) =>
          prev.map((template) =>
            template.id === templateId ? { ...template, ...data } : template
          )
        );
      });
  };

  const onPreview = (data: any, templateId: number) => {
    axios
      .post(`/template-manager/order-file/${templateId}/preview/`, data)
      .then((response) => {
        console.log(response.data);
        setPdfPreviewUrl(response.data.url);
      });
  };

  const onCloseTemplate = () => {
    setOpenedTemplate(null);
    setPdfPreviewUrl(null);
  };

  const onCreate = (data: any) => {
    axios.post("/template-manager/order-file/", data).then((response) => {
      setOrderFileTemplates((prev) => [...prev, response.data]);
      setCreateModalOpen(false);
      onCloseTemplate();
    });
  };

  const onDeleteTemplate = (templateId: number) => {
    axios.delete(`/template-manager/order-file/${templateId}/`).then(() => {
      setOrderFileTemplates((prev) =>
        prev.filter((template) => template.id !== templateId)
      );
      onCloseTemplate();
    });
  };

  return (
    <div className="p-2">
      <Button onClick={() => setCreateModalOpen(true)} variant="contained">
        {t("templates.orderFiles.createTemplate")}
      </Button>
      <div className="mt-4 border-t border-b border-gray-200">
        {orderFileTemplates.map((template) => (
          <div
            key={template.id}
            className="flex justify-between items-center border-b border-gray-200 p-2"
          >
            <h2>{template.name}</h2>
            <Button onClick={() => setOpenedTemplate(template)}>
              {t("common.view")}
            </Button>
          </div>
        ))}
      </div>

      <Dialog
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.orderFiles.createTemplate")}</DialogTitle>
        <DialogContent>
          <CreateTemplateForm
            variables={[
              "shipper",
              "payment_amount",
              "payment_currency",
              "order_number",
              "invoice_number",
              "sender_name",
              "sender_signature",
              "tracking_link",
              "delivery_date",
            ]}
            onSave={onCreate}
            disableSubject={true}
          />
          <DialogActions>
            <Button onClick={() => setCreateModalOpen(false)} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openedTemplate !== null}
        onClose={() => onCloseTemplate()}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.orderFiles.templateDetails")}</DialogTitle>
        <DialogContent>
          {openedTemplate && (
            <PureTemplateEditor
              template={openedTemplate}
              variables={[
                "shipper",
                "payment_amount",
                "payment_currency",
                "order_number",
                "invoice_number",
                "sender_name",
                "sender_signature",
                "tracking_link",
                "delivery_date",
              ]}
              placeholder={t("templates.orderFiles.placeholder")}
              handleSave={(data) => handleSave(data, openedTemplate.id)}
              onPreview={(data) => onPreview(data, openedTemplate.id)}
              onDelete={() => onDeleteTemplate(openedTemplate.id)}
            />
          )}
          {htmlPreviewUrl && openedTemplate && (
            <div className="mt-4 items-center justify-center w-full bg-black">
              <PdfPreview fileUrl={htmlPreviewUrl} fileId={openedTemplate.id} />
            </div>
          )}
          <DialogActions>
            <Button onClick={() => onCloseTemplate()} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderFileTemplates;
