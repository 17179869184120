import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStrider } from "../../../contexts/StriderContext";
import { useAuth } from "../../../contexts/AuthContext";
import ErrorPage from "../../../error-page";

interface SubcontractorOrderFileCreateFirstStepProps {
  carrierId: number | null;
  control: any;
  setValue: any;
  watch: any;
  onSetCarrierModalOpen: () => void;
}

const SubcontractorOrderFileCreateFirstStep: React.FC<
  SubcontractorOrderFileCreateFirstStepProps
> = ({ carrierId, control, onSetCarrierModalOpen, watch }) => {
  const { carriers } = useStrider();
  const { company, companyLoading } = useAuth();
  const { t } = useTranslation();

  const truckId = watch("truck_id");
  const driverId = watch("driver_id");
  const carrierAssigneeId = watch("carrier_assignee_id");

  if (companyLoading) return <CircularProgress />;

  if (!company) return <ErrorPage />;

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <Controller
          name="carrier_id"
          control={control}
          render={({ field }) => {
            const selectedCarrier =
              carriers.find((carrier) => carrier.id === field.value) || null;
            return (
              <Autocomplete
                {...field}
                value={selectedCarrier}
                options={carriers}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  event.stopPropagation();
                  field.onChange(newValue?.id || null);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("orders.orderCreate.carrier")}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
            );
          }}
        />
        <Button
          variant="contained"
          type="button"
          onClick={() => onSetCarrierModalOpen()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("orders.orderCreate.addCarrier")}
        </Button>
      </div>
      <div className="space-y-4 grid-cols-2">
        <div className="flex items-center gap-2">
          <Controller
            name="truck_id"
            control={control}
            render={({ field }) => {
              const trucks =
                carriers.find((carrier) => carrier.id === carrierId)?.trucks ||
                [];
              const selectedTruck =
                trucks.find((truck) => truck.license_plate === field.value) ||
                null;
              return (
                <Autocomplete
                  {...field}
                  value={selectedTruck}
                  options={trucks}
                  getOptionLabel={(option) => option.license_plate}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    field.onChange(newValue?.license_plate || null);
                  }}
                  disabled={!carrierId}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("orders.orderCreate.truck")}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              );
            }}
          />
          <Controller
            name="license_plate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.licensePlate")}
                fullWidth
                variant="outlined"
                required
                disabled={!(truckId === null || truckId === "")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            name="driver_id"
            control={control}
            render={({ field }) => {
              const drivers =
                carriers.find((carrier) => carrier.id === carrierId)?.drivers ||
                [];
              const selectedDriver =
                drivers.find((driver) => driver.id === field.value) || null;
              return (
                <Autocomplete
                  {...field}
                  value={selectedDriver}
                  options={drivers}
                  getOptionLabel={(option) => option.full_name}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    field.onChange(newValue?.id || null);
                  }}
                  disabled={!carrierId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("orders.orderCreate.driver")}
                      variant="outlined"
                    />
                  )}
                />
              );
            }}
          />
          <div className="grid grid-cols-3 gap-2">
            <Controller
              name="driver_first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.driverFirstName")}
                  variant="outlined"
                  required
                  disabled={!(driverId === null || driverId === "")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="driver_last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.driverLastName")}
                  variant="outlined"
                  required
                  disabled={!(driverId === null || driverId === "")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="driver_phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.driverPhone")}
                  variant="outlined"
                  required
                  disabled={!(driverId === null || driverId === "")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            name="carrier_assignee_id"
            control={control}
            render={({ field }) => {
              const employees =
                carriers.find((carrier) => carrier.id === carrierId)
                  ?.employees || [];
              const selectedEmployee =
                employees.find((employee) => employee.id === field.value) ||
                null;
              return (
                <Autocomplete
                  {...field}
                  value={selectedEmployee}
                  options={employees}
                  getOptionLabel={(option) => option.full_name}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    field.onChange(newValue?.id || null);
                  }}
                  disabled={!carrierId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("orders.orderCreate.carrierAssignee")}
                      variant="outlined"
                    />
                  )}
                />
              );
            }}
          />
          <div className="grid grid-cols-3 gap-2">
            <Controller
              name="carrier_assignee_first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.carrierAssigneeFirstName")}
                  variant="outlined"
                  required
                  disabled={
                    !(carrierAssigneeId === null || carrierAssigneeId === "")
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="carrier_assignee_last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.carrierAssigneeLastName")}
                  variant="outlined"
                  required
                  disabled={
                    !(carrierAssigneeId === null || carrierAssigneeId === "")
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="carrier_assignee_phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orders.orderCreate.carrierAssigneePhone")}
                  variant="outlined"
                  required
                  disabled={
                    !(carrierAssigneeId === null || carrierAssigneeId === "")
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubcontractorOrderFileCreateFirstStep;
