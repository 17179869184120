import CheckoutForm from "./CheckoutForm";

const SubscriptionContainer = () => {

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <main className="w-full max-w-4xl p-4 space-y-8">
        <CheckoutForm />
      </main>
      <footer className="w-full p-4 text-center text-gray-600">
        © XTrans.AI
      </footer>
    </div>
  );
};

export default SubscriptionContainer;
