import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { DelegationModel } from "../../types/delegations";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { getCurrencySymbol } from "../../utils/currencies";
import Orders from "./Orders";
import { DateTimePicker } from "@mui/x-date-pickers";
import { MdExpandMore, MdWarningAmber } from "react-icons/md";
import DelegationMap from "./DelegationMap";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "../../hooks/useToast";
import { saveAs } from "file-saver";
import { IoMdWarning } from "react-icons/io";
import BorderCrossings from "./BorderCrossings";
import DelegationStats from "./DelegationStats";
import DelegationWarnings from "./DelegationWarnings";
import DelegationSummary from "./DelegationSummary";
import { DelegationStats as DelegationStatsType } from "../../types/delegations";

const DelegationDetailContainer = () => {
  const { delegationId } = useParams<{ delegationId: string }>();
  const [delegation, setDelegation] = useState<DelegationModel | null>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();
  const [statsLoading, setStatsLoading] = useState(true);
  const [stats, setStats] = useState<DelegationStatsType | null>(null);
  const [selectedFormat, setSelectedFormat] = useState("inelo");

  const [delegationStartDate, setDelegationStartDate] = useState<Date | null>(
    null
  );
  const [delegationEndDate, setDelegationEndDate] = useState<Date | null>(null);
  const [standStartDate, setStandStartDate] = useState<Date | null>(null);

  const { company } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDelegation = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/delegations/${delegationId}/`);
        setDelegation(data);
        setDelegationStartDate(new Date(data.start_date));
        setDelegationEndDate(data.end_date ? new Date(data.end_date) : null);
        setStandStartDate(new Date(data.stand_start_date));
      } catch (error) {
        console.error(error);
        toast({
          type: "error",
          message: t("delegation.fetchError"),
        });
      } finally {
        setLoading(false);
      }
    };
    fetchDelegation();
  }, [delegationId]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setStatsLoading(true);
        const { data } = await axios.get(`/delegations/${delegationId}/stats/`);
        setStats(data);
      } catch (error) {
        console.error(error);
        toast({
          type: "error",
          message: t("delegation.fetchStatsError"),
        });
      } finally {
        setStatsLoading(false);
      }
    };
    fetchStats();
  }, [delegationId]);

  const onUpdate = async (data: Partial<DelegationModel>) => {
    try {
      const response = await axios.patch(`/delegations/${delegationId}/`, data);
      setDelegation(response.data);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.updateError"),
      });
    }
  };

  const onChangeStartDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ start_date: date?.toISOString() });
    }
  };

  const onChangeEndDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ end_date: date?.toISOString() });
    }
  };

  const onChangeStandStartDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ stand_start_date: date?.toISOString() });
    }
  };

  const onChangeTruck = async (licensePlate: string) => {
    await onUpdate({ truck: licensePlate });
  };

  const onDelete = async () => {
    try {
      await axios.delete(`/delegations/${delegationId}/`);
      toast({
        type: "success",
        message: t("delegation.deleteSuccess"),
      });
      navigate("/delegations");
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.deleteError"),
      });
    }
  };

  const exportBorderCrossings = async () => {
    try {
      const response = await axios.get(
        `/delegations/${delegationId}/export/border-crossings`,
        {
          responseType: "blob",
          params: {
            type: selectedFormat,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      saveAs(blob, `odcinki_zagraniczne_${delegation?.driver_name}.xls`);
    } catch (error) {
      console.error(error);
    }
  };

  const exportOrders = async () => {
    try {
      const response = await axios.get(
        `/delegations/${delegationId}/export/orders`,
        {
          responseType: "blob",
          params: {
            type: selectedFormat,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      saveAs(blob, `załadunki_rozładunki_${delegation?.driver_name}.xls`);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!delegation) {
    return <div>{t("delegation.notFound")}</div>;
  }

  return (
    <div className="p-4 dark:text-secondary text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/delegations">{t("breadcrumb.delegations")}</Link>
        <Typography color="text.primary">
          {t("delegation.title")} - {delegation.ref_number}
        </Typography>
      </Breadcrumbs>
      <div className="p-4 flex flex-col">
        <Button
          variant="contained"
          color="error"
          onClick={onDelete}
          className="self-end"
        >
          {t("delegation.delete")}
        </Button>
        <h1>{delegation.ref_number}</h1>
        <p>{delegation.driver_name}</p>
        <div className="flex items-center gap-4 p-4">
          <TextField
            select
            label={t("delegation.selectTruck")}
            value={delegation.truck}
            onChange={(e) => onChangeTruck(e.target.value as string)}
            variant="outlined"
          >
            {company?.trucks.map((truck) => (
              <MenuItem key={truck.license_plate} value={truck.license_plate}>
                {truck.license_plate}
              </MenuItem>
            ))}
          </TextField>
          <DateTimePicker
            label={t("delegations.standDate")}
            value={standStartDate}
            onChange={onChangeStandStartDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <DateTimePicker
            label={t("delegation.startDate")}
            value={delegationStartDate}
            onChange={onChangeStartDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <DateTimePicker
            label={t("delegation.endDate")}
            value={delegationEndDate}
            onChange={onChangeEndDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <div className="flex flex-col items-center gap-4">
            <Select
              placeholder={t("delegation.format")}
              value={selectedFormat}
              onChange={(event) => setSelectedFormat(event.target.value as string)}
              input={<OutlinedInput label={t("delegations.format")} />}

              fullWidth
              variant="outlined"
              required
            >
              <MenuItem value={"inelo"}>
                4Trans(Inelo)
              </MenuItem>
              <MenuItem value={"other"}>
                Other
              </MenuItem>
            </Select>
            <div className="flex items-center gap-4"> <Button variant="contained" onClick={exportBorderCrossings}>
              {t("delegation.exportBorderCrossings")}
            </Button>
              <Button variant="contained" onClick={exportOrders}>
                {t("delegation.exportOrders")}
              </Button></div>

          </div>
        </div>
        <DelegationSummary delegation={delegation} />
        {statsLoading && <Skeleton variant="rectangular" height={200} />}
        {!statsLoading && stats && stats.warnings.length > 0 && (
          <div className="p-4">
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                <div className="flex items-center gap-4">
                  <Typography variant="h6" component="div">
                    {t("delegation.warnings")}
                  </Typography>
                  <IoMdWarning className="text-red-500 text-2xl" />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <DelegationWarnings warnings={stats.warnings} />
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>

      <BorderCrossings delegation={delegation} />

      {stats && (
        <div className="p-4">
          <DelegationStats stats={stats} />
          <div className="p-4">
            {stats.routes.length > 0 && (
              <div className="">
                <p className="text-gray-700 dark:text-white">
                  {(stats.routes[0].summary.duration / 60 / 60).toFixed(1)} h
                </p>
              </div>
            )}
          </div>

          <p className="text-gray-700 dark:text-white pt-4">
            {t("delegation.orders")}: {stats.total_orders_count}
          </p>

          {stats.orders && (
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                <Typography variant="h6" component="div">
                  {t("delegation.orders")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Orders orders={stats.orders} />
              </AccordionDetails>
            </Accordion>
          )}

          {stats.forwarder_incomes?.map((forwarderIncome, index) => (
            <Card key={index} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {forwarderIncome.owner_assignee}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {forwarderIncome.total_income}{" "}
                  <span className="text-sm">{getCurrencySymbol("eur")}</span>
                </Typography>
              </CardContent>
            </Card>
          ))}

          <Accordion sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <Typography variant="h6" component="div">
                {t("delegation.matchedRoutes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {stats?.matched_route_warnings?.map((warning, index) => (
                <div
                  key={index}
                  className="bg-red-100 text-red-800 p-2 rounded-lg my-2"
                >
                  <MdWarningAmber className="text-red-500 text-2xl" />
                  <p>{warning.message}</p>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          <div className="px-28">
            {statsLoading && <Skeleton variant="rectangular" height={200} />}
            {!statsLoading && delegation && stats && (
              <DelegationMap delegation={delegation} stats={stats} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DelegationDetailContainer;
