import { EmailLog } from "./email";

export type BaseInvoiceItem = {
  name: string;
  quantity: number;
  unit: string;
  price: {
    net_value: number;
    vat_value: number;
    gross_value: number;
    currency: string;
    vat_rate: number | string | null;
  };
};

export type InvoicePartyType = {
  name: string;
  vat_id: string;
  country: string;
  city: string;
  street: string;
  postal_code: string;
  building_number: string;
  apartment_number: string;
}

export type BaseInvoiceType = {
  number: string;
  sell_date: string;
  issue_date: string;
  recipient: InvoicePartyType | null;
  issuer: InvoicePartyType | null;
  remarks: string;
  due_days: number;
  due_date: string;
  items: BaseInvoiceItem[];
  prices: {
    net_value: number;
    vat_value: number;
    gross_value: number;
    currency: string;
    vat_rate: number | string | null;
  }[];
  nbp_id: string;
  nbp_exchange_rate: number;
  nbp_date: string;
  bank_accounts: number[];
  contact_email: string;
  contact_phone: string;
};

export const VAT_RATE_TYPES = ["np", 23, 0];

export interface InvoiceEmail {
  email_log: EmailLog;
}

export interface InvoiceModel extends BaseInvoiceType {
  id: number;
  order_id: number;
  shipper_received_at: string | null;
  mail_tracking_id: string | null;
  is_paid: boolean;
  remaining_days: number;
  file: string;
  emails: InvoiceEmail[];
}