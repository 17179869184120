import { Controller, useForm } from "react-hook-form";
import { OrderInfo } from "../../types/orders";
import { FaFileInvoice } from "react-icons/fa6";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import { extractErrorMessages } from "../../utils/errors";
import { CURRENCIES } from "../../consts/currencies";
import { useTranslation } from "react-i18next";
import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import { getCurrencySymbol } from "../../utils/currencies";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { hasAccess } from "../../utils/permissions";

interface OrderPaymentFormProps {
  order: OrderInfo;
  onFormSubmit: (data: any) => void;
  setInvoicePreview: (data: any) => void;
  setInvoicePreviewModalOpen: (data: boolean) => void;
  onDeleteInvoice: () => void;
}

const OrderPaymentForm: React.FC<OrderPaymentFormProps> = ({
  order,
  onFormSubmit,
  setInvoicePreview,
  setInvoicePreviewModalOpen,
  onDeleteInvoice,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      payment: {
        price_value: order.payment.price_value,
        price_currency: order.payment.price_currency,
        days: order.payment.days,
      },
    },
  });
  const axios = useAxios();
  const toast = useToast();
  const { account } = useAuth();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);
  const onCreateInvoicePreview = async () => {
    const response = await axios.post(`invoices/preview/`, {
      order_id: order.id,
    });
    return response.data;
  };

  const createInvoicePreview = async () => {
    try {
      const data = await onCreateInvoicePreview();
      setInvoicePreview(data);
      setInvoicePreviewModalOpen(true);
    } catch (error: any) {
      if (error.response.status === 500) {
        toast({
          type: "error",
          message: error.message,
        });
      } else {
        const errors = extractErrorMessages(error.response.data);
        toast({
          type: "error",
          message: errors.join(" "),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="my-4">
        <h3 className="text-xl font-semibold mb-2">
          {t("orderDetails.payment.title")}
        </h3>
        <div className="grid grid-cols-6">
          <Controller
            name="payment.price_value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orderDetails.payment.rate")}
                variant="outlined"
                className="w-64"
                type="number"
                disabled={!hasAccessForUpdate}
              />
            )}
          />
          <Controller
            name="payment.price_currency"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("orderDetails.payment.currency")}
                variant="outlined"
                className="w-64"
                disabled={!hasAccessForUpdate}
              >
                {CURRENCIES.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {getCurrencySymbol(currency)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="payment.days"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orderDetails.payment.days")}
                variant="outlined"
                className="w-64"
                type="number"
                disabled={!hasAccessForUpdate}
              />
            )}
          />
        </div>
      </div>
      {
        hasAccessForUpdate &&

        <Button className="mt-4" variant="contained" type="submit">
          {t("common.save")}
        </Button>
      }
      <h3 className="text-xl font-semibold my-4">
        {t("orderDetails.payment.invoiceTitle")}
      </h3>
      {order.invoice ? (
        <div className="flex flex-col">
          <div className="flex items-center space-x-2 my-4">
            <a
              href={order.invoice.file}
              target="_blank"
              rel="noreferrer"
              className="text-accent hover:text-blue-800 flex flex-col items-center space-x-2"
            >
              <IconButton>
                <FaFileInvoice className="text-accent" />
              </IconButton>
              View Invoice
            </a>
          </div>
          {
            hasAccessForUpdate && hasAccess(account, "invoices", "delete") &&
            <div className="flex items-center space-x-2 my-4">
              <Button
                type="button"
                onClick={onDeleteInvoice}
                color="error"
                variant="contained"
              >
                {t("orderDetails.payment.invoiceDelete")}
              </Button>
            </div>
          }
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          {order.ref_number && order.status === 'done' && hasAccessForUpdate && (
            <Button
              type="button"
              onClick={createInvoicePreview}
              className="text-black hover:text-blue-800 flex items-center space-x-2"
            >
              <FaFileInvoice />
              <span>{t("orderDetails.payment.invoiceCreate")}</span>
            </Button>
          )}
          {!order.ref_number && (
            <p className="text-red-500">
              {t("orderDetails.payment.invoiceCreateRequired")}
            </p>
          )}
        </div>
      )}
    </form>
  );
};

export default OrderPaymentForm;
