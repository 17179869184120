import { useTranslation } from "react-i18next";
import Loadings from "./Cards/Loadings";
import { FiBarChart2, FiTruck } from "react-icons/fi";
import Unloadings from "./Cards/Unloadings";
import Card from "../../components/card/Card";
import { FaTruckLoading } from "react-icons/fa";
import Performance from "./Cards/Performance";
import { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import {
  MilagePerformance as MilagePerformanceType,
  Performance as PerformanceType,
} from "../../types/insights";
import IncomePerformance from "./Cards/IncomePerformance";
import MilagePerformance from "./Cards/MilagePerformance";
import FuelPerformance from "./Cards/FuelPerformance";
import Recommendations from "./Cards/Recommendations";
import IncomingEvents from "./Cards/IncomingEvents";
import CompanyUsage from "./Cards/CompanyUsage";

const DashboardContainer = () => {
  const { t } = useTranslation();
  const [performance, setPerformance] = useState<PerformanceType | null>(null);
  const [milagePerformance, setMilagePerformance] =
    useState<MilagePerformanceType | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [milageLoading, setMilageLoading] = useState<boolean>(true);
  const axios = useAxios();

  useEffect(() => {
    const fetchPerformance = async () => {
      setLoading(true);
      try {
        const response = await axios.get("insights/performance");
        setPerformance(response.data);
      } catch (error) {
        console.error("Error fetching loadings:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPerformance();
  }, []);

  useEffect(() => {
    const fetchMilagePerformance = async () => {
      setMilageLoading(true);
      try {
        const response = await axios.get("insights/milage");
        setMilagePerformance(response.data);
      } catch (error) {
        console.error("Error fetching loadings:", error);
      } finally {
        setMilageLoading(false);
      }
    };
    fetchMilagePerformance();
  }, []);

  return (
    <div className="grid grid-cols-12 text-black dark:text-white p-4 gap-4">
      <div className="col-span-6">
        <Card
          icon={<FaTruckLoading className="text-3xl text-accent" />}
          title={t("dashboard.loadings")}
        >
          <Loadings />
        </Card>
      </div>
      <div className="col-span-6">
        <Card
          icon={<FiTruck className="text-3xl text-accent" />}
          title={t("dashboard.unloadings")}
        >
          <Unloadings />
        </Card>
      </div>
      <div className="col-span-4">
        <Card
          icon={<FaTruckLoading className="text-3xl text-accent" />}
          title={t("dashboard.events")}
        >
          <IncomingEvents />
        </Card>
      </div>
      <div className="col-span-8">
        <Card
          icon={<FaTruckLoading className="text-3xl text-accent" />}
          title={t("dashboard.recommendations")}
        >
          <Recommendations />
        </Card>
      </div>
      <div className="col-span-4">
        <Card
          icon={<FaTruckLoading className="text-3xl text-accent" />}
          title={t("dashboard.companyUsage.title")}
        >
          <CompanyUsage />
        </Card>
      </div>
      <div className="col-span-12">
        <Card
          icon={<FiBarChart2 className="text-3xl text-accent" />}
          title={t("dashboard.performance.title")}
        >
          <Performance performance={performance} loading={loading} />
        </Card>
      </div>
      <div className="col-span-12">
        <Card
          icon={<FiBarChart2 className="text-3xl text-accent" />}
          title={t("dashboard.income")}
        >
          <IncomePerformance performance={performance} loading={loading} />
        </Card>
      </div>
      <div className="col-span-12">
        <Card
          icon={<FiBarChart2 className="text-3xl text-accent" />}
          title={t("dashboard.milage.title")}
        >
          <MilagePerformance
            performance={milagePerformance}
            loading={milageLoading}
          />
        </Card>
      </div>
      <div className="col-span-12">
        <Card
          icon={<FiBarChart2 className="text-3xl text-accent" />}
          title={t("dashboard.fuel.title")}
        >
          <FuelPerformance
            performance={milagePerformance}
            loading={milageLoading}
          />
        </Card>
      </div>
    </div>
  );
};

export default DashboardContainer;
