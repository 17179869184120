import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns-tz";
import { DelegationModel } from "../../types/delegations";
import { useTranslation } from "react-i18next";

interface BorderCrossingProps {
  delegation: DelegationModel;
}

const BorderCrossings: React.FC<BorderCrossingProps> = ({ delegation }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="text-md font-semibold my-2 text-gray-800 dark:text-white">
        {t("delegation.borderCrossings")}
      </h4>
      <ul className="list-disc pl-5 text-gray-700 dark:text-white">
        {delegation.border_crossings.map((crossing, index) => (
          <li key={index}>
            {crossing.old_country && (
              <ReactCountryFlag
                countryCode={crossing.old_country}
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  marginRight: "0.5em",
                }}
              />
            )}
            {crossing.title} - {crossing.duration} min{" "}
            {crossing.old_country_at && (
              <span>
                {format(
                  new Date(crossing.old_country_at),
                  "dd MMM yyyy, HH:mm"
                )}
              </span>
            )}
            <span className="mx-2">-</span>
            <ReactCountryFlag
              countryCode={crossing.new_country}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
                marginRight: "0.5em",
              }}
            />
            {crossing.new_country_at && (
              <span>
                {format(
                  new Date(crossing.new_country_at),
                  "dd MMM yyyy, HH:mm"
                )}
              </span>
            )}
          </li>
        ))}
      </ul>
      <p className="text-gray-700 dark:text-white">
        {t("delegation.borderCrossings")}:{delegation.border_crossings.length}
      </p>
    </>
  );
};

export default BorderCrossings;
