import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface ExpensesCircleGraphProps {
  expenses: { [category: string]: number };
}

const OTHER_PERCENTAGE_THRESHOLD = 3;

const ExpensesCircleGraph: React.FC<ExpensesCircleGraphProps> = ({ expenses }) => {
  const totalExpense = Object.values(expenses).reduce((acc, expense) => acc + expense, 0);

  let data = Object.keys(expenses).map((key, index) => {
    return {
      category: key,
      expense: expenses[key],
      percentage: (expenses[key] / totalExpense) * 100,
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });

  // Group categories with less than 2% into "Other"
  const otherData = data.filter(item => item.percentage < OTHER_PERCENTAGE_THRESHOLD);
  const otherExpense = otherData.reduce((acc, item) => acc + item.expense, 0);
  data = data.filter(item => item.percentage >= OTHER_PERCENTAGE_THRESHOLD);

  if (otherExpense > 0) {
    data.push({
      category: "Other",
      expense: otherExpense,
      percentage: (otherExpense / totalExpense) * 100,
      color: GRAPH_COLORS[data.length % GRAPH_COLORS.length],
    });
  }

  data.sort((a, b) => b.expense - a.expense);

  return (
    <div>
      <h2 className="text-center">Expenses by Category</h2>
      {data.length === 0 ? (
        <NoDataAvailable message="No expenses to show - add some expenses first" />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={data}
              dataKey="expense"
              nameKey="category"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip formatter={(value, name) => [`${((value as number) / totalExpense * 100).toFixed(2)}%`, name]} />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ExpensesCircleGraph;