import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { COUNTRIES, DEFAULT_COUNTRY } from "../../consts/countries";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import useAxios from "../../hooks/useAxios";
import { CompanySuggestion } from "../../types/companies";
import { CorporateDetails } from "../../types/base";

interface CarrierCreateProps {
  onCreateCarrier: (data: CorporateDetails) => Promise<void>;
  isCarrierModalOpen: boolean;
  onCloseCarrierModal: () => void;
  prefilledData?: CorporateDetails;
}

const ShipperCreate: React.FC<CarrierCreateProps> = ({
  isCarrierModalOpen,
  onCloseCarrierModal,
  onCreateCarrier,
  prefilledData,
}) => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, setValue, getValues, reset } =
    useForm<CorporateDetails>({
      defaultValues: {
        country: prefilledData?.country || DEFAULT_COUNTRY,
        name: prefilledData?.name || "",
        vat_id: prefilledData?.vat_id || "",
        regon: prefilledData?.regon || "",
        city: prefilledData?.city || "",
        street: prefilledData?.street || "",
        building_number: prefilledData?.building_number || "",
        postal_code: prefilledData?.postal_code || "",
        apartment_number: prefilledData?.apartment_number || "",
      },
    });

  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [companySuggestions, setCompanySuggestions] = useState<
  CompanySuggestion[]
>([]);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const axios = useAxios();

  const handleCreateShipper = async(data: CorporateDetails) => {
    await onCreateCarrier(data);
    reset();
  };

  useEffect(() => {
    if (prefilledData) {
      setShowOptionalFields(true);
    }
  }, [prefilledData]);

  const fetchCompanies = async () => {
    try {
      const name = getValues("name");
      const vatId = getValues("vat_id");
      const response = await axios.post("/integrations/companies/search", {
        name: name,
        nip: vatId,
      });
      if (response.data.companies.length === 0) {
        setCompanySuggestions([]);
        setIsCompanyModalOpen(true);
        return;
      }
      setCompanySuggestions(response.data.companies);
      setIsCompanyModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch companies:", error);
      setValue("country", DEFAULT_COUNTRY);
      setValue("regon", "");
      setValue("city", "");
      setValue("street", "");
      setValue("building_number", "");
      setValue("postal_code", "");
      setValue("apartment_number", "");
    }
  };

  const handleCompanySelect = (company: CompanySuggestion) => {
    console.log(company);
    setValue("name", company.name);
    setValue("vat_id", company.nip);
    const country = COUNTRIES.find(
      (country) => country === company.address.country.toLowerCase()
    );
    setValue("country", country || DEFAULT_COUNTRY);
    setValue("regon", company.regon || "");
    setValue("street", company.address.street || "");
    setValue("building_number", company.address.building || "");
    setValue("postal_code", company.address.postal_code || "");
    setValue("apartment_number", company.address.local || "");
    setValue("city", company.address.city || "");
    setShowOptionalFields(true);
    setIsCompanyModalOpen(false);
  };

  const toggleOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };

  return (
    <>
      <Dialog open={isCarrierModalOpen} onClose={onCloseCarrierModal}>
        <DialogTitle>{t("orders.orderCreate.carrierModal.title")}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(handleCreateShipper)}>
            <TextField
              label={t("orders.orderCreate.carrierModal.name")}
              {...register("name", { required: true })}
              fullWidth
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t("orders.orderCreate.carrierModal.vatId")}
              {...register("vat_id", { required: true })}
              fullWidth
              margin="dense"
            />
            <div className="flex gap-2">
              <Button
                variant="contained"
                onClick={fetchCompanies}
                sx={{ my: 2 }}
              >
                {t("orders.orderCreate.carrierModal.fetchCompanies")}
              </Button>
              {!showOptionalFields && (
                <Button onClick={toggleOptionalFields}>
                  {t("orders.orderCreate.carrierModal.addOptionalFields")}
                </Button>
              )}
            </div>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("orders.orderCreate.carrierModal.country")}
                  fullWidth
                  variant="outlined"
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country} value={country}>
                      <div className="flex items-center gap-2">
                        <ReactCountryFlag countryCode={country} svg />
                        {t(`countries.${country}`)}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {showOptionalFields && (
              <>
                <TextField
                  label={t("orders.orderCreate.carrierModal.regon")}
                  {...register("regon")}
                  fullWidth
                  margin="dense"
                />
                <TextField
                  label={t("orders.orderCreate.carrierModal.city")}
                  {...register("city")}
                  fullWidth
                  margin="dense"
                />
                <TextField
                  label={t("orders.orderCreate.carrierModal.street")}
                  {...register("street")}
                  fullWidth
                  margin="dense"
                />
                <TextField
                  label={t("orders.orderCreate.carrierModal.buildingNo")}
                  {...register("building_number")}
                  fullWidth
                  margin="dense"
                />
                <TextField
                  label={t("orders.orderCreate.carrierModal.postalCode")}
                  {...register("postal_code")}
                  fullWidth
                  margin="dense"
                />
                <TextField
                  label={t("orders.orderCreate.carrierModal.apartmentNo")}
                  {...register("apartment_number")}
                  fullWidth
                  margin="dense"
                />
              </>
            )}
            <DialogActions>
              <Button onClick={onCloseCarrierModal}>
                {t("common.cancel")}
              </Button>
              <Button type="submit">{t("common.save")}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCompanyModalOpen}
        onClose={() => setIsCompanyModalOpen(false)}
      >
        <DialogTitle>
          {t("orders.orderCreate.carrierModal.companiesModal.title")}
        </DialogTitle>
        <DialogContent>
          {companySuggestions.length === 0 ? (
            <Typography variant="body1">
              {t("orders.orderCreate.carrierModal.noCompaniesFound")}
            </Typography>
          ) : (
            companySuggestions.map((company) => (
              <MenuItem
                key={company.nip}
                onClick={() => handleCompanySelect(company)}
              >
                {company.name} - {company.nip}
              </MenuItem>
            ))
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShipperCreate;
