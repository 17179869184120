import { User } from "../types/user";

export const hasAccess = (user: User | null | undefined, scope: string, action: string) => {

  const userScope = user?.scopes[scope] || [];
  if (!userScope) {
    return false;
  }
  return userScope.includes(action);
};
