import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ExpenseHistoryEntryJson, Refuel } from "../../types/delegations";
import { groupBy } from "lodash";
import { Bar } from "react-chartjs-2";
import { MdExpandMore } from "react-icons/md";
import { format } from "date-fns-tz";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";


interface CostsByCountryProps {
  refuels: Refuel[];
  costs: ExpenseHistoryEntryJson[];
}

const useStyles = makeStyles(() => ({
  summaryContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  countryName: {
    fontWeight: "bold",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CostsByCountry: React.FC<CostsByCountryProps> = ({ refuels, costs }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const { t } = useTranslation();

  // Group refuels and costs by country
  const refuelsByCountry = groupBy(refuels, "country");
  const costsByCountry = groupBy(costs, (cost) => cost.expense.country);

  // Get a list of all countries
  const countries = Array.from(
    new Set([...Object.keys(refuelsByCountry), ...Object.keys(costsByCountry)])
  );

  return (
    <div>
      {countries.map((country) => {
        const countryRefuels = refuelsByCountry[country] || [];
        const countryCosts = costsByCountry[country] || [];

        // Calculate total expenses per currency
        const expensesByCurrency = countryCosts.reduce(
          (acc: Record<string, number>, cost) => {
            const currency = cost.expense.currency;
            const amount = parseFloat(cost.expense.net_amount);
            if (!acc[currency]) {
              acc[currency] = 0;
            }
            acc[currency] += amount;
            return acc;
          },
          {}
        );

        // Calculate total refuels
        const totalRefuelAmount = countryRefuels.reduce(
          (sum, refuel) => sum + refuel.fuel_add,
          0
        );

        // Extract fuel expenses for comparison
        const fuelExpenses = countryCosts.filter(
          (cost) => cost.expense.category.toLowerCase() === "fuel"
        );

        const totalFuelExpenseQuantity = fuelExpenses.reduce(
          (sum, cost) => sum + (cost.expense.quantity || 0),
          0
        );

        // Prepare data for comparison chart
        const comparisonData = {
          labels: ["Refuels (Liters)", "Fuel Card Expenses (Liters)"],
          datasets: [
            {
              label: "Fuel Quantity Comparison",
              data: [totalRefuelAmount, totalFuelExpenseQuantity],
              backgroundColor: ["#3f51b5", "#f50057"],
            },
          ],
        };

        return (
          <Accordion key={country}>
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <div className={classes.summaryContent}>
                <div className="flex items-center flex-col p-4">
                  <ReactCountryFlag countryCode={country} svg />
                  <Typography className={classes.countryName}>
                    {t(`countries.${country}`)}
                  </Typography>
                </div>
                <div>
                  {Object.entries(expensesByCurrency).map(
                    ([currency, total]) => (
                      <Typography variant="body2" key={currency}>
                        Total Expenses: {total.toFixed(2)} {currency}
                      </Typography>
                    )
                  )}
                  <Typography variant="body2">
                    Total Refuels: {totalRefuelAmount.toFixed(2)} Liters
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="tabs"
                >
                  <Tab label="Expenses" {...a11yProps(0)} />
                  <Tab label="Refuels" {...a11yProps(1)} />
                  <Tab label="Comparison" {...a11yProps(2)} />
                </Tabs>
                <Box>
                  <CustomTabPanel value={value} index={0}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {countryCosts.map((cost) => (
                            <TableRow key={cost.id}>
                              <TableCell>{cost.date}</TableCell>
                              <TableCell>{cost.expense.category}</TableCell>
                              <TableCell>{cost.expense.description}</TableCell>
                              <TableCell align="right">
                                {parseFloat(cost.expense.net_amount).toFixed(2)}{" "}
                                {cost.expense.currency}
                              </TableCell>
                              <TableCell align="right">
                                {cost.expense.quantity || "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <TableContainer component={Paper} style={{ marginTop: 16 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">
                              Fuel Added (Liters)
                            </TableCell>
                            <TableCell align="right">Mileage</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {countryRefuels.map((refuel, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {format(
                                  new Date(refuel.created_at),
                                  "yyyy-MM-dd HH:mm"
                                )}
                              </TableCell>
                              <TableCell>{refuel.city}</TableCell>
                              <TableCell>{refuel.address}</TableCell>
                              <TableCell align="right">
                                {refuel.fuel_add.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {refuel.mileage}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Box mt={4}>
                      <Typography variant="h6">
                        Fuel Quantity Comparison
                      </Typography>
                      <Bar data={comparisonData} />
                    </Box>
                  </CustomTabPanel>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CostsByCountry;
