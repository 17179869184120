import { CorporateDetails } from "./base";
import { Carrier } from "./carriers";
import { Company } from "./companies";
import { Driver } from "./driver";
import { EmailLog } from "./email";
import { Employee } from "./employees";
import { SuggestionType } from "./geo";
import { InvoiceModel } from "./invoices";
import { BaseTruck, TruckLocation } from "./truck";
import { User } from "./user";

export type OrderStatus =
  | "new"
  | "assigned"
  | "in_progress"
  | "done"
  | "canceled"
  | "discarded";

export const ORDER_STATUS_FILTER_OPTIONS = [
  "new",
  "assigned",
  "in_progress",
  "done",
  "canceled",
  "discarded",
];

export const ORDER_CREATE_STEPS = [
  "general",
  "payment",
  "operations",
  "summary",
];

export const ORDER_FILE_CREATE_STEPS = [
  'carrier',
  'cargo',
  'payment',
  'operations',
  'summary',
]

export type OperationStatus = "awaiting" | "in_progress" | "done";

export type OperationType = "loading" | "unloading" | "customs" | "fueling";

export interface CmrFile {
  id: number;
  cmr_file: string;
  created_at: string;
}

export interface Operation {
  id: number;
  order_id: number;
  position: number;
  locality: string;
  postal_code: string;
  country: string;
  truck?: BaseTruck;
  time_begin: string;
  time_end: string;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  operation_type: OperationType;
  remarks: string;
  completed_at: string | null;
  status: OperationStatus;
  cmrs?: CmrFile[];
  place_display: string;
  driver?: Driver;
}

export interface Payment {
  price_currency: string;
  price_value: number;
  days: number;
}

export interface Assignee {
  id: number;
  trans_id: string;
  trans_account_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export interface Requirements {
  type?: string;
  remarks?: string;
  monitoring?: string;
  capacity_unit?: string;
  capacity_value?: number;
}

export interface TransportOrderFile {
  id: number;
  file: string;
  created_at: string;
}

export interface SubcontractorOrderFile {
  id: number;
  file: string;
  created_at: string;
  ref_number: string;
}

export interface TrackingLink {
  uuid: string;
  valid_until: string;
  link: string;
}

export interface OrderEmail {
  email_log: EmailLog;
}

export type OrderInfo = {
  id: number;
  trans_ref_number: string;
  created_at: string;
  trans_date?: string;
  operations: Operation[];
  payment: Payment;
  completed_at: string | null;
  estimated_distance?: number;
  shipper: CorporateDetails;
  shipper_assignee: Assignee;
  carrier: Carrier | Company | null;
  own_order: boolean;
  requirements?: Requirements;
  transport_order_files: TransportOrderFile[];
  status: OrderStatus;
  truck?: BaseTruck;
  ref_number: string;
  invoice?: InvoiceModel | null;
  assigned_route: string | null;
  tracking_link: TrackingLink | null;
  driver?: Driver;
  carrier_assignee?: Employee;
  subcontractor_order_file: SubcontractorOrderFile | null;
  subcontractor_cargo_name: string;
  subcontractor_freight_rate: number;
  subcontractor_currency: string;
  subcontractor_payment_days: number;
  subcontractor_cargo_remarks: string;
  owner_assignee?: User;
  display: string;
  transport_order_file_uploaded: boolean;
  emails: OrderEmail[];
  created_by?: User;
};

export type TrackingOrderInfo = Omit<OrderInfo, "truck"> & {
  truck: TruckLocation;
};

export const ORDER_OPERATION_TYPES = [
  "loading",
  "unloading",
  "customs",
  "other",
];

export interface OrderOperationFormData {
  operation_type: string;
  time_end: string;
  time_begin: string;
  latitude: number | null;
  longitude: number | null;
  country: string;
  address: string | SuggestionType | null;
}

export interface OrderFormData {
  ref_number: string;
  distance: number;
  carrier_id: number | null;
  shipper_id: number;
  truck_id: string;
  driver: string;
  operations: OrderOperationFormData[];
  payment: {
    price_currency: string;
    price_value: number;
    days: number;
    period: string;
  };
  own_order: boolean;
  job_id: string;
  estimated_distance: number;
}

export interface AiShipper {
  vat_id?: string;
  legal_name?: string;
  building_number?: string;
  apartment_number?: string;
  regon?: string;
  city?: string;
  postal_code?: string;
  street?: string;
  country?: string;
}

export interface OrderCreatedByAi {
  job_id: string;
  ref_number?: string;
  shipper?: AiShipper;
  operations?: {
    operation_type: string;
    time_end: string;
    time_begin: string;
    latitude: number;
    longitude: number;
    country: string;
    locality: string;
    postal_code: string;
    name: string;
    address: string;
    remarks: string;
  }[];
  driver?: string;
  vehicle?: string;
  payment?: {
    amount?: number;
    currency?: string;
    days?: number;
  };
  shipper_id?: number;
  truck_id?: string;
  is_own?: boolean;
  carrier_id?: number;
  driver_id?: string;
  distance?: number;
}
