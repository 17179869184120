import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { BaseTruck } from "../../types/truck";
import { Driver } from "../../types/driver";
import { OrderInfo, SubcontractorOrderFile } from "../../types/orders";
import { FaDownload, FaMagnifyingGlass } from "react-icons/fa6";
import { getFileName } from "../../utils/file";
import Modal from "../../components/modal/Modal";
import PdfPreview from "../../components/pdfPreview/PdfPreview";
import { useTranslation } from "react-i18next";
import OrderCarrierDetails from "./OrderCarrierDetails/OrderCarrierDetails";
import { Employee } from "../../types/employees";
import { useStrider } from "../../contexts/StriderContext";
import CarrierSelect from "../../components/carrierSelect/CarrierSelect";
import { useAuth } from "../../contexts/AuthContext";
import ErrorPage from "../../error-page";
import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { hasAccess } from "../../utils/permissions";

interface CarrierFormProps {
  order: OrderInfo;
  loading: boolean;
  onFormSubmit: (data: any) => void;
  onCreateOrderFile: (data: any) => void;
  onDeleteSubcontractorOrderFile: (fileId: number) => void;
}

const CarrierForm: React.FC<CarrierFormProps> = ({
  order,
  loading,
  onFormSubmit,
  onCreateOrderFile,
  onDeleteSubcontractorOrderFile,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      carrier_id: order.carrier?.id,
      driver_id: order.driver?.id || null,
      license_plate: order.truck?.license_plate || null,
      carrier_assignee_id: order.carrier_assignee?.id || null,
      own_order: order.own_order,
    },
  });
  const selectedCarrier = watch("carrier_id");
  const ownOrder = watch("own_order");

  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [currentTransportOrderFile, setCurrentTransportOrderFile] =
    useState<SubcontractorOrderFile | null>(null);

  const { carriers } = useStrider();
  const { account, company, companyLoading } = useAuth();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [trucks, setTrucks] = useState<BaseTruck[]>([]);
  const [assignees, setAssignees] = useState<Employee[]>([]);

  useEffect(() => {
    if (companyLoading || !company) return;
    if (ownOrder) {
      setDrivers(company.drivers);
      setAssignees(company.employees);
      setTrucks(company.trucks);
    } else {
      const carrier = carriers.find((c) => c.id === selectedCarrier);
      if (carrier) {
        setDrivers(carrier.drivers);
        setAssignees(carrier.employees);
        setTrucks(carrier.trucks);
      }
    }
  }, [ownOrder, selectedCarrier, company, companyLoading, carriers]);

  const handleCarrierChange = (value: number, isCompany: boolean) => {
    if (isCompany) {
      setValue("carrier_id", undefined);
      setValue("own_order", true);
      setValue("carrier_assignee_id", null);
      if (order.own_order) {
        setValue("driver_id", order.driver?.id || null);
        setValue("license_plate", order.truck?.license_plate || null);
      } else {
        setValue("driver_id", null);
        setValue("license_plate", null);
      }
    } else {
      setValue("carrier_id", value);
      setValue("own_order", false);
      const carrier = carriers.find((c) => c.id === value);
      if (carrier) {
        if (carrier.id === order.carrier?.id) {
          setValue("driver_id", order.driver?.id || null);
          setValue("license_plate", order.truck?.license_plate || null);
          setValue("carrier_assignee_id", order.carrier_assignee?.id || null);
        } else {
          setValue("driver_id", null);
          setValue("license_plate", null);
          setValue("carrier_assignee_id", null);
        }
      }
    }
  };

  if (companyLoading) return <CircularProgress />;

  if (!company) return <ErrorPage />;

  return (
    <div className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white flex justify-between">
      <div>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="w-full max-w-md space-y-4"
        >
          <CarrierSelect
            disabled={!hasAccessForUpdate}
            company={company}
            carriers={carriers}
            label={t("orders.selectCarrier")}
            value={
              ownOrder
                ? `company-${company.id}`
                : selectedCarrier
                  ? `carrier-${selectedCarrier}`
                  : null
            }
            onChange={handleCarrierChange}
            size="medium"
          />
          <Controller
            name="driver_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("orderDetails.carrier.selectDriver")}
                fullWidth
                variant="outlined"
                disabled={!hasAccessForUpdate}
              >
                {drivers.map((driver) => (
                  <MenuItem key={driver.id} value={driver.id}>
                    {driver.first_name} {driver.last_name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="license_plate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("orderDetails.carrier.selectTruck")}
                fullWidth
                variant="outlined"
                disabled={!hasAccessForUpdate}
              >
                {trucks.map((truck) => (
                  <MenuItem
                    key={truck.license_plate}
                    value={truck.license_plate}
                  >
                    {truck.license_plate}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {!ownOrder && (
            <Controller
              name="carrier_assignee_id"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("orderDetails.carrier.selectCarrierAssignee")}
                  fullWidth
                  variant="outlined"
                  disabled={!hasAccessForUpdate}
                >
                  {assignees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.full_name} - {employee.email}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
          {
            hasAccessForUpdate && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {t("common.save")}
              </Button>
            )
          }

        </form>
      </div>
      <div className="w-1/2">
        <div className="flex items-center justify-between">
          <Typography variant="h5" component="h2" className="font-semibold">
            {t("orderDetails.carrier.title")}
          </Typography>
          {
            order.carrier && hasAccess(account, 'carriers', 'read') && <Link to={`/carriers/${order.carrier?.id}`}>
              <IconButton>
                <FaPencilAlt />
              </IconButton>
            </Link>
          }

        </div>
        <Divider className="my-2" />
        {!order.own_order && (
          <div className="grid grid-cols-1 gap-2">
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.carrier.name")}:
              </strong>{" "}
              {order.carrier?.name}
            </Typography>
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.carrier.vatId")}:
              </strong>{" "}
              {order.carrier?.vat_id}
            </Typography>
          </div>
        )}
        {order.own_order && (
          <div className="grid grid-cols-1 gap-2">
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.carrier.name")}:
              </strong>{" "}
              {company.name}
            </Typography>
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.carrier.vatId")}:
              </strong>{" "}
              {company.vat_id}
            </Typography>
          </div>
        )}
        {order.truck && (
          <>
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.carrier.truck.title")}
            </Typography>
            <Divider className="my-2" />
            <div className="grid grid-cols-1 gap-2">
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.truck.licensePlate")}:
                </strong>{" "}
                {order.truck.license_plate}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.truck.name")}:
                </strong>{" "}
                {order.truck.name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.truck.type")}:
                </strong>{" "}
                {order.truck.gvm}
              </Typography>
            </div>
          </>
        )}
        {order.driver && (
          <>
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.carrier.driver.title")}
            </Typography>
            <Divider className="my-2" />
            <div className="grid grid-cols-1 gap-2">
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.driver.lastName")}:
                </strong>{" "}
                {order.driver.last_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.driver.firstName")}:
                </strong>{" "}
                {order.driver.first_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.driver.phone")}:
                </strong>{" "}
                {order.driver.phone}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.driver.email")}:
                </strong>{" "}
                {order.driver.email}
              </Typography>
            </div>
          </>
        )}
        {order.carrier_assignee && (
          <>
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.carrier.assignee.title")}
            </Typography>
            <Divider className="my-2" />
            <div className="grid grid-cols-1 gap-2">
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.assignee.lastName")}:
                </strong>{" "}
                {order.carrier_assignee.last_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.assignee.firstName")}:
                </strong>{" "}
                {order.carrier_assignee.first_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.assignee.phone")}:
                </strong>{" "}
                {order.carrier_assignee.phone}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.carrier.assignee.email")}:
                </strong>{" "}
                {order.carrier_assignee.email}
              </Typography>
            </div>
          </>
        )}
        <OrderCarrierDetails
          order={order}
          onSubmit={onCreateOrderFile}
          loading={loading}
        />
        {order.subcontractor_order_file ? (
          <div className="flex items-center justify-between border border-gray-300 rounded p-2 my-4">
            <div className="flex items-center gap-2">
              <a
                href={order.subcontractor_order_file.file}
                target="_blank"
                rel="noopener noreferrer"
                className="text-accent hover:text-blue-800 flex items-center"
              >
                <FaDownload className="mr-2" />
                {getFileName(order.subcontractor_order_file.file)}
              </a>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="contained"
                className="flex items-center"
                onClick={() => {
                  setCurrentTransportOrderFile(order.subcontractor_order_file);
                  setPreviewPdfVisible(true);
                }}
              >
                <FaMagnifyingGlass />
                <span className="ml-1">
                  {t("orderDetails.carrier.createOrderFile.preview")}
                </span>
              </Button>
              <Button
                variant="contained"
                className="flex items-center ml-2"
                onClick={() => {
                  if (order.subcontractor_order_file) {
                    onDeleteSubcontractorOrderFile(
                      order.subcontractor_order_file.id
                    );
                  }
                }}
              >
                {t("common.delete")}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        isOpen={previewPdfVisible}
        onClose={() => setPreviewPdfVisible(false)}
      >
        {previewPdfVisible && currentTransportOrderFile && (
          <PdfPreview
            fileUrl={currentTransportOrderFile.file}
            fileId={currentTransportOrderFile.id}
          />
        )}
      </Modal>
    </div>
  );
};

export default CarrierForm;
