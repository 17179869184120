import { Controller, useForm } from "react-hook-form";
import { COUNTRIES, DEFAULT_COUNTRY } from "../../consts/countries";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import useAxios from "../../hooks/useAxios";
import { useState } from "react";
import { CompanySuggestion } from "../../types/companies";
import { CorporateDetails } from "../../types/base";

interface ShipperProps {
  shipper: CorporateDetails;
  onFormSubmit: (data: any) => void;
}

const ShipperForm: React.FC<ShipperProps> = ({ shipper, onFormSubmit }) => {
  const { t } = useTranslation();
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companySuggestions, setCompanySuggestions] = useState<
    CompanySuggestion[]
  >([]);
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      name: shipper.name,
      vat_id: shipper.vat_id,
      city: shipper.city,
      country: shipper.country,
      street: shipper.street,
      postal_code: shipper.postal_code,
      email: shipper.email,
      building_number: shipper.building_number,
      apartment_number: shipper.apartment_number,
      phone: shipper.phone,
      regon: shipper.regon,
    },
  });
  const axios = useAxios();

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const name = getValues("name");
      const vatId = getValues("vat_id");
      const response = await axios.post("/integrations/companies/search", {
        name: name,
        nip: vatId,
      });
      if (response.data.companies.length === 0) {
        setCompanySuggestions([]);
        setIsCompanyModalOpen(true);
        return;
      }
      setCompanySuggestions(response.data.companies);
      setIsCompanyModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch companies:", error);
      setValue("country", DEFAULT_COUNTRY);
      setValue("regon", "");
      setValue("city", "");
      setValue("street", "");
      setValue("building_number", "");
      setValue("postal_code", "");
      setValue("apartment_number", "");
    } finally {
      setIsLoading(false);
    }
  };
  const handleCompanySelect = (company: CompanySuggestion) => {
    setValue("name", company.name);
    setValue("vat_id", company.nip);
    const country = COUNTRIES.find(
      (country) => country === company.address.country.toLowerCase()
    );
    setValue("country", country || DEFAULT_COUNTRY);
    setValue("regon", company.regon);
    setValue("street", company.address.street);
    setValue("building_number", company.address.building || "");
    setValue("postal_code", company.address.postal_code || "");
    setValue("apartment_number", company.address.local || "");
    setValue("city", company.address.city || "");
    setIsCompanyModalOpen(false);
  };

  return (
    <>
      <form
        className="p-8 bg-white text-gray-700"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        {shipper && (
          <h3 className="text-2xl font-bold mb-4">
            {t("shipperDetails.title")} - {shipper.name} - {shipper.vat_id}
          </h3>
        )}
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.name")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <div className="flex items-start gap-2">
            <Controller
              name="vat_id"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("shipperDetails.vatId")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Button
              variant="contained"
              onClick={fetchCompanies}
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              {isLoading
                ? t("orders.orderCreate.shipperModal.fetchingCompanies")
                : t("orders.orderCreate.shipperModal.fetchCompanies")}
            </Button>
          </div>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.city")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.street")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="postal_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.postalCode")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.email")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="building_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.buildingNo")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="apartment_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.apartmentNo")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shipperDetails.phone")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("shipperDetails.country")}
                fullWidth
                variant="outlined"
              >
                {COUNTRIES.map((country) => (
                  <MenuItem key={country} value={country}>
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag countryCode={country} svg />
                      {t(`countries.${country}`)}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>

        <Button type="submit" variant="contained" color="primary">
          {t("shipperDetails.save")}
        </Button>
        <Dialog
          open={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
        >
          <DialogTitle>
            {t("orders.orderCreate.shipperModal.companiesModal.title")}
          </DialogTitle>
          <DialogContent>
            {companySuggestions.length === 0 ? (
              <Typography variant="body1">
                {t("orders.orderCreate.shipperModal.noCompaniesFound")}
              </Typography>
            ) : (
              companySuggestions.map((company) => (
                <MenuItem
                  key={company.nip}
                  onClick={() => handleCompanySelect(company)}
                >
                  {company.name} - {company.nip}
                </MenuItem>
              ))
            )}
          </DialogContent>
        </Dialog>
      </form>
    </>
  );
};

export default ShipperForm;
