import { TextField, MenuItem, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMPLOYMENT_TYPES, EmployeeDetails } from "../../../types/employees";

interface EmployeePaymentForm {
  employee: EmployeeDetails;
  onFormSubmit: (data: any) => void;
}

const EmployeePaymentForm: React.FC<EmployeePaymentForm> = ({
  employee,
  onFormSubmit,
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      employment_type: employee.payment_info?.employment_type,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="grid gap-4 grid-cols-2"
    >
      <Controller
        name="employment_type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("employeeDetail.payments.fields.employmentType")}
            variant="outlined"
          >
            {EMPLOYMENT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(`employmentTypes.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default EmployeePaymentForm;
