import { useCallback, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { BaseDelegation, DelegationModel } from "../../types/delegations";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { extractEndpoint, getPageNumBasedOnUrl } from "../../utils/pagination";
import { debounce, groupBy } from "lodash";
import { format } from "date-fns-tz";
import DelegationForm from "./DelegationForm";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuth } from "../../contexts/AuthContext";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const DELEGATIONS_PER_PAGE = 20;

const DelegationsContainer = () => {
  const [delegations, setDelegations] = useState<DelegationModel[]>([]);
  const { t } = useTranslation();
  const axios = useAxios();
  const [isOpen, setIsOpen] = useState(false);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [dateFrom, setDateFrom] = useState<Date>(oneMonthAgo);
  const [dateTo, setDateTo] = useState<Date>(today);
  const [drivers, setDrivers] = useState<number[]>([]);

  const { company } = useAuth();

  const [totalDelegations, setTotalDelegations] = useState<number>(0);

  const fetchDelegations = async (url: string) => {
    try {
      setLoading(true);
      const { data } = await axios.get(url);
      setTotalDelegations(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(getPageNumBasedOnUrl(url));
      setDelegations(data.results);
    } finally {
      setLoading(false);
    }
  };

  const buildUrl = () => {
    const url = "/delegations/";
    const params = new URLSearchParams();
    if (drivers.length > 0) {
      params.append("driver", drivers.join(","));
    }
    if (dateFrom) {
      params.append("start_date", format(dateFrom, "yyyy-MM-dd"));
    }
    if (dateTo) {
      params.append("end_date", format(dateTo, "yyyy-MM-dd"));
    }
    return url + "?" + params.toString();
  };

  const debouncedFetchDelegations = useCallback(
    debounce((url) => fetchDelegations(url), 500),
    []
  );

  useEffect(() => {
    const initialUrl = buildUrl();
    debouncedFetchDelegations(initialUrl);
  }, [drivers, dateFrom, dateTo, debouncedFetchDelegations]);

  const groupedDelegations = groupBy(delegations, (delegation) =>
    format(new Date(delegation.start_date), "MMMM yyyy")
  );

  const fetchDelegationsByPage = async (page: number) => {
    try {
      setLoading(true);
      const initialUrl = buildUrl();
      const url = `${initialUrl}&page=${page}`;
      const { data } = await axios.get(url);
      setDelegations(data.results);
      setTotalDelegations(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders by page:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderSmartDots = () => {
    const totalPages = Math.ceil(totalDelegations / DELEGATIONS_PER_PAGE);
    const pages: any[] = [];
    pages.push(1);

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    if (startPage > 2) {
      pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={index} className="px-2 py-1 text-black">
            ...
          </span>
        );
      } else {
        return (
          <button
            key={index}
            onClick={() => fetchDelegationsByPage(page as number)}
            className={`mx-1 px-2 py-1 rounded ${
              page === currentPage
                ? "bg-accent text-white"
                : "bg-gray-300 text-black"
            }`}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </button>
        );
      }
    });
  };

  const addDelegation = async (delegation: BaseDelegation) => {
    const response = await axios.post("/delegations/", delegation);
    setDelegations((prev) => [response.data, ...prev]);
    setIsOpen(false);
  };

  if (!company) {
    return null;
  }

  return (
    <div className="p-4">
      <Button onClick={() => setIsOpen(true)} variant="contained">
        {t("delegations.add")}
      </Button>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <DatePicker
          label={t("delegations.dateFrom")}
          value={dateFrom ? new Date(dateFrom) : null}
          format="dd/MM/yyyy"
          onChange={(date) => {
            if (date) {
              setDateFrom(date);
            }
          }}
        />
        <DatePicker
          label={t("delegations.dateTo")}
          value={dateTo ? new Date(dateTo) : null}
          format="dd/MM/yyyy"
          onChange={(date) => {
            if (date) {
              setDateTo(date);
            }
          }}
        />
        <Select
          multiple
          placeholder={t("delegations.driver")}
          value={drivers}
          onChange={(event) => setDrivers(event.target.value as number[])}
          input={<OutlinedInput label={t("delegations.driver")} />}
          renderValue={(selected) => (
            <div className="flex flex-wrap gap-2">
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    company.drivers.find((driver) => driver.id === value)
                      ?.full_name || value
                  }
                />
              ))}
            </div>
          )}
          fullWidth
          variant="outlined"
          required
          endAdornment={
            drivers.length > 0 && (
              <IconButton onClick={() => setDrivers([])}>
                <FaTimes />
              </IconButton>
            )
          }
        >
          {company.drivers.map((driver) => (
            <MenuItem key={driver.id} value={driver.id}>
              {driver.full_name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {loading ? (
        <p className="text-center text-gray-500 p-4">{t("common.loading")}</p>
      ) : (
        Object.entries(groupedDelegations)
          .sort(([monthA], [monthB]) => {
            const dateA = new Date(monthA + " 01");
            const dateB = new Date(monthB + " 01");
            return dateB.getTime() - dateA.getTime(); // Sort by newest first
          })
          .map(([month, delegations]) => (
            <div key={month} className="my-8">
              <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-4">
                {month}
              </h2>
              <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {delegations.map((delegation) => (
                  <Link
                    to={`/delegations/${delegation.id}`}
                    key={delegation.ref_number}
                    className="p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
                  >
                    <h3 className="text-lg font-medium text-gray-900">
                      {delegation.ref_number}
                    </h3>
                    <p className="text-gray-700">
                      {t("delegations.driver")}: {delegation.driver_name}
                    </p>
                    <p className="text-gray-500">{delegation.truck}</p>
                    <p className="text-gray-500">
                      {t("delegations.dateRange")}:
                      {format(new Date(delegation.start_date), "dd/MM/yyyy")} -{" "}
                      {format(new Date(delegation.end_date), "dd/MM/yyyy")}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
          ))
      )}
      <div className="flex justify-center gap-4 mt-4">
        <Button
          variant="contained"
          onClick={() => prevUrl && fetchDelegations(prevUrl)}
          disabled={!prevUrl}
        >
          {t("common.prev")}
        </Button>
        <Button
          variant="contained"
          onClick={() => nextUrl && fetchDelegations(nextUrl)}
          disabled={!nextUrl}
        >
          {t("common.next")}
        </Button>
      </div>
      <div className="flex justify-center mt-8">{renderSmartDots()}</div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{t("delegations.addDelegationModal.title")}</DialogTitle>
        <DialogContent>
          <DelegationForm onSubmit={addDelegation} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DelegationsContainer;
