import { OrderInfo, TransportOrderFile } from "../../types/orders";
import DragDropInput from "../../components/input/DragDropInput";
import { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { FaDownload, FaMagnifyingGlass } from "react-icons/fa6";
import PdfPreview from "../../components/pdfPreview/PdfPreview";
import { getFileName } from "../../utils/file";
import { Button } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { hasAccess } from "../../utils/permissions";

interface FilesFormProps {
  order: OrderInfo;
  onUploadTransportOrderFile: (files: FileList) => void;
  onDeleteTransportOrderFile: (fileId: number) => void;
  fileUploading: boolean;
}

const FilesForm: React.FC<FilesFormProps> = ({
  order,
  fileUploading,
  onDeleteTransportOrderFile,
  onUploadTransportOrderFile,
}) => {
  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [currentTransportOrderFile, setCurrentTransportOrderFile] =
    useState<TransportOrderFile | null>(null);
  const { account } = useAuth();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);

  return (
    <div className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <h3 className="text-xl font-semibold mb-4">Transport Order Files</h3>
      <div className="space-y-4">
        {order.transport_order_files.map((file, index: number) => (
          <div
            key={index}
            className="flex items-center justify-between border border-gray-300 rounded p-2"
          >
            <div className="flex items-center gap-2">
              <a
                href={file.file}
                target="_blank"
                rel="noopener noreferrer"
                className="text-accent hover:text-blue-800 flex items-center"
              >
                <FaDownload className="mr-2" />
                {getFileName(file.file)}
              </a>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="contained"
                className="flex items-center"
                onClick={() => {
                  setCurrentTransportOrderFile(file);
                  setPreviewPdfVisible(true);
                }}
              >
                <FaMagnifyingGlass />
                <span className="ml-1">Preview</span>
              </Button>
              {
                hasAccessForUpdate &&

                <Button
                  variant="contained"
                  color="warning"
                  className="flex items-center ml-2"
                  onClick={() => {
                    onDeleteTransportOrderFile(file.id);
                  }}
                >
                  Delete
                </Button>
              }
            </div>
          </div>
        ))}
      </div>
      {
        hasAccessForUpdate &&
        <div className="mt-4">
          <DragDropInput
            uploading={fileUploading}
            name="Transport Order File"
            label="Upload Transport Order File"
            types={[
              "PDF",
              "DOC",
              "DOCX",
              "ODT",
              "TXT",
              "RTF",
              "PNG",
              "JPG",
              "JPEG",
            ]}
            multiple={true}
            handleChange={onUploadTransportOrderFile}
          />
        </div>
      }
      <Modal
        isOpen={previewPdfVisible}
        onClose={() => setPreviewPdfVisible(false)}
      >
        {previewPdfVisible && currentTransportOrderFile && (
          <PdfPreview
            fileUrl={currentTransportOrderFile.file}
            fileId={currentTransportOrderFile.id}
          />
        )}
      </Modal>
    </div>
  );
};

export default FilesForm;
