import { MdWarningAmber } from "react-icons/md";

interface DelegationWarningsProps {
    warnings: string[];
}

const DelegationWarnings: React.FC<DelegationWarningsProps> = ({warnings}) => {
  return (
    <>
      {" "}
      {warnings.map((warning, index) => (
        <div
          key={index}
          className="bg-red-100 text-red-800 p-2 rounded-lg my-2"
        >
          <MdWarningAmber className="text-red-500 text-2xl" />
          <p>{warning}</p>
        </div>
      ))}
    </>
  );
};

export default DelegationWarnings;
