export const getParametersForPredefinedTruck = (
  truck: "predefined-bus" | "predefined-solo" | "predefined-semi"
) => {
  switch (truck) {
    case "predefined-bus":
      return {
        vehicle_length: 4000,
        vehicle_width: 2400,
        vehicle_height_min: 3200,
        vehicle_height_max: 3200,
        vehicle_weight: 6000,
        axle_count: 2,
        engine_ccm: 2000,
        max_speed: 130,
        gvm: 3500,
        fuel_type: "diesel",
        free_flow_speed_table: '0,0,85,0.12,90,0.14'
      };
    case "predefined-solo":
      return {
        vehicle_length: 5000,
        vehicle_width: 2400,
        vehicle_height_min: 3200,
        vehicle_height_max: 3200,
        vehicle_weight: 2000,
        axle_count: 2,
        engine_ccm: 3500,
        max_speed: 90,
        gvm: 7200,
        fuel_type: "diesel",
        free_flow_speed_table: '0,0,85,0.15,90,0.16'
      };
    case "predefined-semi":
      return {
        vehicle_length: 16000,
        vehicle_width: 2500,
        vehicle_height_min: 4000,
        vehicle_height_max: 4000,
        vehicle_weight: 40000,
        axle_count: 3,
        engine_ccm: 5000,
        max_speed: 90,
        gvm: 25000,
        fuel_type: "diesel",
        free_flow_speed_table: '0,0,85,0.16,90,0.2'
      };
  }
};
