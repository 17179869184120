import { Button, Dialog, MenuItem, TextField } from "@mui/material";
import { InvoiceModel } from "../../types/invoices";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { TemplateBase } from "../../types/templates";
import TemplatePreview from "../Template/TemplatePreview";
import { useToast } from "../../hooks/useToast";
import { format } from "date-fns-tz";
import { EmailLog } from "../../types/email";

interface InvoiceMailingProps {
  invoice: InvoiceModel;
}

const InvoiceMailing: React.FC<InvoiceMailingProps> = ({ invoice }) => {
  const { t } = useTranslation();
  const [invoiceDueTemplates, setInvoiceDueTemplates] = useState<
    TemplateBase[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateBase | null>(
    null
  );
  const [htmlPreview, setHtmlPreview] = useState<string | null>(null);
  const [htmlPreviewSubject, setHtmlPreviewSubject] = useState<string | null>(
    null
  );
  const [email, setEmail] = useState<string | null>(null);
  const axios = useAxios();
  const toast = useToast();
  const [selectedEmailLog, setSelectedEmailLog] = useState<EmailLog | null>(
    null
  );

  useEffect(() => {
    axios.get("/template-manager/invoice-due").then((response) => {
      setInvoiceDueTemplates(response.data);
      if (response.data.length > 0) {
        setSelectedTemplate(response.data[0]);
      }
    });
  }, []);

  const onClose = () => {
    setHtmlPreview(null);
    setHtmlPreviewSubject(null);
    setEmail(null);
  };

  const onPreview = () => {
    if (!selectedTemplate) {
      return;
    }
    axios
      .post(`/invoices/${invoice.id}/mail/preview/${selectedTemplate.id}/`)
      .then((response) => {
        setHtmlPreview(response.data.message);
        setHtmlPreviewSubject(response.data.title);
        setEmail(response.data.email);
      });
  };

  const sendEmail = () => {
    if (!selectedTemplate || !email) {
      return;
    }
    axios
      .post(`/invoices/${invoice.id}/mail/${selectedTemplate.id}/`, {
        email,
      })
      .then(() => {
        onClose();
        toast({
          message: t("invoices.invoiceMailModal.emailSent"),
          type: "success",
        });
      });
  };


  return (
    <div className="flex flex-col gap-2">
      {invoice.emails.map((email) => (
        <div
          key={email.email_log.uuid}
          className="border rounded-lg shadow-sm p-4 mb-4 bg-white hover:shadow-md transition-shadow duration-300"
        >
          {/* Grid Layout for Email Info */}
          <div className="grid grid-cols-2 gap-4">
            {/* Recipient and Sent Time */}
            <div>
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.recipient")}</p>
              <p className="text-sm text-gray-500">
                {email.email_log.recipient} - {email.email_log.receiver_shipper_name}
              </p>
            </div>
            <div>
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.sender")}</p>
              <p className="text-sm text-gray-500">
                {email.email_log.sender_company_name} - {email.email_log.user_username}
              </p>
            </div>
            <div>
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.sentAt")}</p>
              <p className="text-sm text-gray-500">
                {format(
                  new Date(email.email_log.sent_at),
                  "yyyy-MM-dd HH:mm:ss"
                )}
              </p>
            </div>

            {/* Opened Status */}
            <div>
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.opened")}</p>
              <p
                className={`text-sm ${
                  email.email_log.opened ? "text-green-500" : "text-red-500"
                }`}
              >
                {email.email_log.opened
                  ? t("invoices.invoiceMailModal.opened")
                  : t("invoices.invoiceMailModal.notOpened")}
              </p>
            </div>

            {/* Subject and Status Icons */}
            <div className="flex items-center space-x-2">
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.subject")}</p>
              <p className="text-sm text-gray-500">{email.email_log.subject}</p>
            </div>

            {/* Delivery Status */}
            <div className="flex items-center space-x-2">
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.delivered")}</p>
              <span
                className={
                  email.email_log.delivered ? "text-green-500" : "text-red-500"
                }
              >
                {email.email_log.delivered ? "✔️" : "❌"}
              </span>
            </div>

            {/* Clicked and Spam Complaint */}
            <div className="flex items-center space-x-2">
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.clicked")}</p>
              <span
                className={
                  email.email_log.clicked ? "text-green-500" : "text-red-500"
                }
              >
                {email.email_log.clicked ? "✔️" : "❌"}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.spamComplaint")}</p>
              <span
                className={
                  email.email_log.spam_complaint
                    ? "text-red-500"
                    : "text-green-500"
                }
              >
                {email.email_log.spam_complaint ? "⚠️" : "✔️"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedEmailLog(email.email_log);
                }}
              >
                {t("invoices.invoiceMailModal.preview")}
              </Button>
            </div>
          </div>
        </div>
      ))}

      <div className="flex items-center gap-4 py-4">
        <TextField
          label={t("invoices.invoiceMailModal.selectTemplate")}
          select
          fullWidth
          variant="outlined"
          value={selectedTemplate?.id || ""}
          onChange={(e) =>
            setSelectedTemplate(
              invoiceDueTemplates.find(
                (template) => template.id === parseInt(e.target.value)
              ) || null
            )
          }
        >
          {invoiceDueTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Button variant="contained" onClick={onPreview}>
        {t("invoices.invoiceMailModal.preview")}
      </Button>
      {htmlPreview && htmlPreviewSubject && (
        <TemplatePreview
          htmlPreview={htmlPreview}
          htmlPreviewSubject={htmlPreviewSubject}
        />
      )}
      {email && (
        <TextField
          label={t("invoices.invoiceMailModal.email")}
          value={email}
          fullWidth
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
      )}
      <Button variant="contained" onClick={sendEmail}>
        {t("invoices.invoiceMailModal.send")}
      </Button>
      <Dialog
        open={selectedEmailLog !== null}
        onClose={() => setSelectedEmailLog(null)}
      >
        {selectedEmailLog && (
          <TemplatePreview
            htmlPreview={selectedEmailLog.body}
            htmlPreviewSubject={selectedEmailLog.subject}
          />
        )}
        {selectedEmailLog && (
          <div className="p-4">
            {" "}
            <p className="font-bold text-gray-700">{t("invoices.invoiceMailModal.textVersion")}</p>
            <p className="text-sm text-gray-500">{selectedEmailLog.body_txt}</p>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default InvoiceMailing;
