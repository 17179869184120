import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Skeleton } from "@mui/material";
import MonthlyExpensesGraph from "./MonthlyExpensesGraph";
import ExpensesGraph from "./ExpensesGraph";
import TruckExpenses from "./TruckExpenses";
import { format } from "date-fns-tz";
import ExpensesCircleGraph from "./ExpensesCircleGraph";

interface ExpensesReport {
  monthly_expenses: any;
  truck_expenses: any;
  expenses: any;
}
interface ExpensesComponentProps {
  startDate: Date;
  endDate: Date;
}

const ExpensesComponent: React.FC<ExpensesComponentProps> = ({
  startDate,
  endDate,
}) => {
  const [expensesReport, setExpensesReport] = useState<ExpensesReport | null>(
    null
  );
  const axios = useAxios();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/insights/reports/expenses", {
        params: {
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => {
        setExpensesReport(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  if (loading || !expensesReport) {
    return (
      <div className="grid grid-cols-1">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 graph-container">
      <MonthlyExpensesGraph monthlyExpenses={expensesReport.monthly_expenses} />
      <ExpensesGraph expenses={expensesReport.expenses} />
      <ExpensesCircleGraph expenses={expensesReport.expenses} />
      <TruckExpenses truck_expenses={expensesReport.truck_expenses} />
    </div>
  );
};

export default ExpensesComponent;
