import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import InvoiceDueTemplates from "./InvoiceDueTemplates";
import OrderTemplates from "./OrderTemplates";
import OrderFileTemplates from "./OrderFileTemplates";

const TemplateContainer = () => {
  const { t } = useTranslation();
  const tabToIndex = {
    invoiceDue: 0,
    orders: 1,
    orderFiles: 2
  };

  const indexToTab = {
    0: "invoiceDue",
    1: "orders",
    2: 'orderFiles'
  };

  const [query, setQuery] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const tab = query.get("tab");
    if (tab && tabToIndex[tab as keyof typeof tabToIndex] !== undefined) {
      setSelectedTab(tabToIndex[tab as keyof typeof tabToIndex]);
    }
  }, [query]);

  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
    setQuery({ tab: indexToTab[index as keyof typeof indexToTab] });
  };

  return (
    <div className="p-4 text-black dark:text-white">
      <Tabs onSelect={handleTabSelect} selectedIndex={selectedTab}>
        <TabList>
          <Tab>{t("templates.invoiceDue.title")}</Tab>
          <Tab>{t("templates.orders.title")}</Tab>
          <Tab>{t("templates.orderFiles.title")}</Tab>
        </TabList>
        <TabPanel>
          <InvoiceDueTemplates />
        </TabPanel>
        <TabPanel>
          <OrderTemplates />
        </TabPanel>
        <TabPanel>
          <OrderFileTemplates />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TemplateContainer;
