import { useEffect, useState } from "react";
import { OrderTemplate } from "../../types/templates";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import TemplateEditor from "./TemplateEditor";
import TemplatePreview from "./TemplatePreview";
import CreateTemplateForm from "./CreateTemplateForm";

const OrderTemplates = () => {
  const [orderTemplates, setOrderTemplates] = useState<OrderTemplate[]>([]);

  const [htmlPreview, setHtmlPreview] = useState<string | null>(null);
  const [htmlPreviewSubject, setHtmlPreviewSubject] = useState<string | null>(
    null
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const axios = useAxios();

  useEffect(() => {
    axios.get("/template-manager/orders").then((response) => {
      setOrderTemplates(response.data);
    });
  }, []);

  const { t } = useTranslation();
  const [openedTemplate, setOpenedTemplate] = useState<OrderTemplate | null>(
    null
  );

  const handleSave = (data: any, templateId: number) => {
    axios.patch(`/template-manager/orders/${templateId}/`, data).then(() => {
      onCloseTemplate();
      setOrderTemplates((prev) =>
        prev.map((template) =>
          template.id === templateId ? { ...template, ...data } : template
        )
      );
    });
  };

  const onPreview = (data: any, templateId: number) => {
    axios
      .post(`/template-manager/orders/${templateId}/preview/`, data)
      .then((response) => {
        setHtmlPreview(response.data.message);
        setHtmlPreviewSubject(response.data.title);
      });
  };

  const onCloseTemplate = () => {
    setOpenedTemplate(null);
    setHtmlPreview(null);
    setHtmlPreviewSubject(null);
  };

  const handleAttachCmrs = () => {
    axios.patch(`/template-manager/orders/${openedTemplate?.id}/`, {
      attach_cmrs: !openedTemplate?.attach_cmrs,
    });
    const updatedTemplates = orderTemplates.map((template) =>
      template.id === openedTemplate?.id
        ? {
            ...template,
            attach_cmrs: !template.attach_cmrs,
          }
        : template
    );
    setOrderTemplates(updatedTemplates);
    setOpenedTemplate((prev) => {
      if (prev) {
        return {
          ...prev,
          attach_cmrs: !prev.attach_cmrs,
        };
      }
      return prev;
    });
  };
  const handleAttachInvoices = () => {
    axios.patch(`/template-manager/orders/${openedTemplate?.id}/`, {
      attach_invoices: !openedTemplate?.attach_invoices,
    });
    setOrderTemplates((prev) =>
      prev.map((template) =>
        template.id === openedTemplate?.id
          ? {
              ...template,
              attach_invoices: !template.attach_invoices,
            }
          : template
      )
    );
    setOpenedTemplate((prev) => {
      if (prev) {
        return {
          ...prev,
          attach_invoices: !prev.attach_invoices,
        };
      }
      return prev;
    });
  };

  const onCreate = (data: any) => {
    axios.post("/template-manager/orders/", data).then((response) => {
      setOrderTemplates((prev) => [...prev, response.data]);
      setCreateModalOpen(false);
      onCloseTemplate();
    });
  }

  const onDelete = (templateId: number) => {
    axios
      .delete(`/template-manager/orders/${templateId}/`)
      .then(() => {
        setOrderTemplates((prev) =>
          prev.filter((template) => template.id !== templateId)
        );
        onCloseTemplate();
      });
  }

  return (
    <div className="p-2">
      <Button onClick={() => setCreateModalOpen(true)} variant="contained">
        {t("templates.orders.createTemplate")}
      </Button>
      <div className="mt-4 border-t border-b border-gray-200">
        {orderTemplates.map((template) => (
          <div key={template.id} className="flex justify-between items-center border-b border-gray-200 p-2">
            <h2>{template.name}</h2>
            <Button onClick={() => setOpenedTemplate(template)}>
              {t("common.view")}
            </Button>
          </div>
        ))}
      </div>

      <Dialog
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.orders.createTemplate")}</DialogTitle>
        <DialogContent>
          <CreateTemplateForm
            variables={[
              "shipper",
              "payment_amount",
              "payment_currency",
              "order_number",
              "invoice_number",
              "sender_name",
              "sender_signature",
              "tracking_link",
              "delivery_date",
            ]}
            onSave={onCreate}
          />
          <DialogActions>
            <Button onClick={() => setCreateModalOpen(false)} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openedTemplate !== null}
        onClose={() => onCloseTemplate()}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.orders.templateDetails")}</DialogTitle>
        <DialogContent>
          {openedTemplate && (
            <TemplateEditor
              template={openedTemplate}
              variables={[
                "shipper",
                "payment_amount",
                "payment_currency",
                "order_number",
                "invoice_number",
                "sender_name",
                "sender_signature",
                "tracking_link",
                "delivery_date",
              ]}
              placeholder={t("templates.orders.placeholder")}
              subjectPlaceholder={t("templates.orders.subjectPlaceholder")}
              handleSave={(data) => handleSave(data, openedTemplate.id)}
              onPreview={(data) => onPreview(data, openedTemplate.id)}
              onDelete={() => onDelete(openedTemplate.id)}
            />
          )}
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={openedTemplate?.attach_cmrs}
                    onChange={handleAttachCmrs}
                    color="primary"
                  />
                }
                label={t("templates.orders.attachCmrs")}
                sx={{ color: "text.primary" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={openedTemplate?.attach_invoices}
                    onChange={handleAttachInvoices}
                    color="primary"
                  />
                }
                label={t("templates.orders.attachInvoices")}
                sx={{ color: "text.primary" }}
              />
            </FormGroup>
          </FormControl>
          {htmlPreview && htmlPreviewSubject && (
            <TemplatePreview
              htmlPreview={htmlPreview}
              htmlPreviewSubject={htmlPreviewSubject}
            />
          )}
          <DialogActions>
            <Button onClick={() => onCloseTemplate()} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderTemplates;
