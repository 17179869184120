import { useTranslation } from "react-i18next";
import { CARRIER_DOCUMENT_TYPES } from "../../types/carriers";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, MenuItem, TextField } from "@mui/material";
import { Document } from "../../types/documents";

interface CarrierDocumentEditProps {
  document: Document;
  onUpdateDocument: (document: any) => void;
}

const CarrierDocumentEdit: React.FC<CarrierDocumentEditProps> = ({
  document,
  onUpdateDocument,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      type: document.type,
      end_date: document.end_date ? new Date(document.end_date) : null,
      description: document.description,
    },
  });

  const handleUpdate = (data: any) => {
    const payload = {
      ...data,
      end_date: data.end_date ? data.end_date.toISOString() : null,
    };
    onUpdateDocument(payload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleUpdate)}
      className="grid grid-cols-2 gap-4 p-4"
    >
      <Controller
        name="end_date"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            format="dd/MM/yyyy"
            label={t("carrierDetail.documents.addDocumentModal.validTo")}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("carrierDetail.documents.addDocumentModal.description")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("carrierDetail.documents.addDocumentModal.documentType")}
            variant="outlined"
            fullWidth
            select
          >
            {CARRIER_DOCUMENT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(`documentTypes.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default CarrierDocumentEdit;
