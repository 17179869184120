import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useToast } from "../hooks/useToast";
import { extractErrorMessages } from "../utils/errors";
import { changeLanguage } from "i18next";
import { User } from "../types/user";
import { Company } from "../types/companies";

interface UserContextType {
  account?: User | null;
  token?: string | null;
  accountLoading: boolean;
  company?: Company | null;
  companyLoading: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  onSetToken: (token: string) => void;
  registerUser: (data: any) => void;
  resetPassword: (data: any) => void;
  resetConfirm: (data: any) => Promise<boolean>;
  setAccount: (account: User | null) => void;
  registerUserSecondStep: (data: any) => void;
}

const AuthContext = createContext<UserContextType>({
  account: null,
  token: null,
  accountLoading: true,
  company: null,
  companyLoading: true,
  login: () => {},
  logout: () => {},
  onSetToken: () => {},
  registerUser: () => {},
  resetPassword: () => {},
  resetConfirm: () => Promise.resolve(true),
  setAccount: () => {},
  registerUserSecondStep: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useLocalStorage<string | null>("token", null);

  const [account, setAccount] = useState<User | null>(null);
  const [company, setCompany] = useState<Company | null>(null);

  const [accountLoading, setAccountLoading] = useState<boolean>(true);
  const [companyLoading, setCompanyLoading] = useState<boolean>(true);

  const toast = useToast();
  const navigate = useNavigate();

  const onSetToken = (token: string) => {
    setToken(token);
  };

  console.log(account);

  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_PUBLIC_API_URL}account/login/`,
        {
          username,
          password,
        }
      );
      if (response.data) {
        setToken(response.data.token);
        navigate("/", { replace: true });
      } else {
        console.error("error logging in");
        toast({
          type: "error",
          message: "Error logging in",
        });
      }
    } catch (err) {
      console.error("error logging in", err);
      toast({
        type: "error",
        message: "Error logging in",
      });
    }
  };

  const registerUser = async (data: any) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_PUBLIC_API_URL}account/register/`,
        data
      );
      if (response.data) {
        navigate("/thank-you-for-registering", { replace: true });
      } else {
        console.error("error registering");
        toast({
          type: "error",
          message: "Error registering",
        });
      }
    } catch (err) {
      console.error("error registering", err);
      toast({
        type: "error",
        message: "Error registering",
      });
    }
  };

  const registerUserSecondStep = async (data: any) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_PUBLIC_API_URL}account/register-second-step/`,
        data
      );
      if (response.data) {
        setToken(response.data.auth_token);
        navigate("/", { replace: true });
      } else {
        console.error("error registering");
        toast({
          type: "error",
          message: "Error registering",
        });
      }
    } catch (err: any) {
      console.error("error registering", err);
      toast({
        type: "error",
        message: extractErrorMessages(err.response.data).join(" "),
      });
    }
  };

  const resetPassword = async (data: any) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_PUBLIC_API_URL}account/reset-password/`,
        data
      );
      if (response.data) {
        toast({
          type: "success",
          message: "Password reset email sent",
        });

        navigate("/", { replace: true });
      } else {
        console.error("error resetting password");
        toast({
          type: "error",
          message: "Error resetting password",
        });
      }
    } catch (err) {
      console.error("error resetting password", err);
      toast({
        type: "error",
        message: "Error resetting password",
      });
    }
  };

  const resetConfirm = async (data: any) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_PUBLIC_API_URL}account/reset-password-confirm/`,
        data
      );
      if (response.data) {
        setToken(response.data.token);
        navigate("/", { replace: true });
        return true;
      } else {
        console.error("error registering");
        toast({
          type: "error",
          message: "Error registering",
        });
        return false;
      }
    } catch (err: any) {
      console.error("error registering", err);
      const errorMessages = extractErrorMessages(err.response.data);
      toast({
        type: "error",
        message: errorMessages.join(" "),
      });
      return false;
    }
  };

  const logout = async () => {
    setToken(null);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    const verifyProfile = async () => {
      try {
        setAccountLoading(true);
        if (!token) {
          return;
        }
        const response = await axios.get(
          `${import.meta.env.VITE_PUBLIC_API_URL}account/profile/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        if (response.data?.preferences?.language) {
          changeLanguage(response.data.preferences.language);
        }
        setAccount(response.data);
      } catch (err) {
        if (
          axios.isAxiosError(err) &&
          err.response &&
          err.response.status === 401
        ) {
          setToken(null);
        } else {
          console.error("Error fetching user profile", err);
        }
      } finally {
        setAccountLoading(false);
      }
    };
    verifyProfile();
  }, [token]);

  useEffect(() => {
    const fetchCompany = async () => {
      setCompanyLoading(true);
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_PUBLIC_API_URL}companies/my-company`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setCompany(response.data);
      } catch (err) {
        console.error("Error fetching company", err);
      } finally {
        setCompanyLoading(false);
      }
    };

    if (token) {
      fetchCompany();
    }
  }, [token]);

  const value = useMemo(
    () => ({
      token,
      account,
      company,
      login,
      accountLoading,
      companyLoading,
      logout,
      onSetToken,
      registerUser,
      resetPassword,
      resetConfirm,
      setAccount,
      registerUserSecondStep,
    }),
    [token, accountLoading, account, company, companyLoading]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
