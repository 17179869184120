export const CARRIER_EXPENSE_TYPES = [
  "office_rent",
  "utilities",
  "insurance",
  "marketing",
  "legal",
  "software",
  "training",
  "travel",
  "taxes",
  "loan",
  "other",
];

export const TRUCK_EXPENSE_TYPES = [
  "fuel",
  "adblue",
  "toll",
  "parking",
  "repair",
  "wash",
  "fine",
  "insurance",
  "other",
  "leasing",
];

export const UNIT_TYPES = ["svc", "pcs", "l"];

export const RECURRING_INTERVALS = ["monthly", "yearly"];
