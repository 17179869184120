import { TextField, MenuItem, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { COUNTRIES } from "../../consts/countries";
import { Carrier } from "../../types/carriers";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

interface CompanyDetailsFormProps {
  carrier: Carrier;
  onSubmit: (data: any) => void;
}

interface CarrierFormData {
  city: string;
  country: string;
  vat_id: string;
  street: string;
  postal_code: string;
  building_number: string;
  apartment_number: string;
  regon: string;
  email: string;
}

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
  carrier,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<CarrierFormData>({
    defaultValues: {
      city: carrier.city,
      country: carrier.country,
      vat_id: carrier.vat_id,
      street: carrier.street,
      postal_code: carrier.postal_code,
      building_number: carrier.building_number,
      apartment_number: carrier.apartment_number,
      regon: carrier.regon,
    },
  });

  return (
    <form
     className="p-4 text-black dark:text-white"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="text-2xl font-bold">
        {t("account.companyDetails.title")}
      </h1>
      <div className="grid grid-cols-3 gap-8 p-4">
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("account.companyDetails.country")}
              fullWidth
              variant="outlined"
            >
              {COUNTRIES.map((country) => (
                <MenuItem key={country} value={country}>
                  <div className="flex items-center gap-2">
                    <ReactCountryFlag countryCode={country} svg />
                    {t(`countries.${country}`)}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.city")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="vat_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.vatId")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="regon"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.regon")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="street"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.street")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="postal_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.postalCode")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="building_number"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.buildingNo")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="apartment_number"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.apartmentNo")}
              variant="outlined"
            />
          )}
        />{" "}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("account.companyDetails.email")}
              variant="outlined"
              type="email"
            />
          )}
        />
      </div>{" "}
      
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default CompanyDetailsForm;
