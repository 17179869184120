import { GRAY, getRouteColors } from "./maps";

export const ROUTE_WIDTH = 8;
export const ACTIVE_ROUTE_WIDTH = 8;
export const HOVERED_ROUTE_WIDTH = 10;
export const ACTIVE_SECTION_WIDTH = 10;
export const HOVERED_SECTION_WIDTH = 12;

export const ACTIVE_ROUTE_OPACITY = "1";
export const INACTIVE_ROUTE_OPACITY = "0.5";

export const ALTERNATIVE_ROUTE_COLOR = "#909090";

export const hexToRgba = (hex: string, opacity: string) => {
  const hexValue = hex.replace("#", "");
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

export const getSectionStyle = (
  baseColor: string,
  isActiveRoute: boolean,
  isActiveSection: boolean,
  isHoveredRoute: boolean,
  isHoveredSection: boolean,
  isAlternativeRoute: boolean,
  index: number
) => {
  return {
    strokeColor: getColorWidthForSection(
      baseColor,
      isActiveRoute,
      isAlternativeRoute,
      index
    ),
    lineWidth: getLineWidthForSection(
      isHoveredRoute,
      isActiveRoute,
      isHoveredSection,
      isActiveSection
    ),
  };
};

export const getLineWidthForSection = (
  isHoveredRoute: boolean,
  isActiveRoute: boolean,
  isHoveredSection: boolean,
  isActiveSection: boolean
) => {
  let routeWidth = ROUTE_WIDTH;

  if (isActiveSection) {
    routeWidth = HOVERED_ROUTE_WIDTH;
  }
  if (!isActiveRoute && isHoveredRoute) {
    routeWidth = HOVERED_ROUTE_WIDTH;
  }
  if (isActiveRoute && isHoveredSection) {
    routeWidth = HOVERED_ROUTE_WIDTH;
  }
  return routeWidth;
};

export const getColorWidthForSection = (
  baseColor: string,
  isActiveRoute: boolean,
  isAlternativeRoute: boolean,
  index: number
) => {
  const opacity = getOpacity(isActiveRoute, isAlternativeRoute);
  let routeColors;
  if (isAlternativeRoute && !isActiveRoute) {
    routeColors = getRouteColors(GRAY);
  } else {
    routeColors = getRouteColors(baseColor);
  }
  let color = routeColors[index % routeColors.length];
  if(!color) {
    color = getRouteColors(GRAY)[index % getRouteColors(GRAY).length];
  }
  return hexToRgba(color, opacity);
};

export const getOpacity = (
  isActiveRoute: boolean,
  isAlternativeRoute: boolean
) => {
  if (isActiveRoute || isAlternativeRoute) {
    return ACTIVE_ROUTE_OPACITY;
  }
  return INACTIVE_ROUTE_OPACITY;
};

export const getAlternativeRouteSectionStyle = () => {
  return {
    lineWidth: ROUTE_WIDTH,
    strokeColor: ALTERNATIVE_ROUTE_COLOR,
  };
};
