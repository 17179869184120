import React from "react";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { CircularProgress } from "@mui/material";
import { Carrier } from "../../types/carriers";

interface CarriersTableProps {
  carriers: Carrier[];
  loading: boolean;
}

const CarriersTable: React.FC<CarriersTableProps> = ({ carriers, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full my-2">
        <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
          <tr>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.name")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.vatId")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.country")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.trucks")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.drivers")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.employees")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("carriers.tableHeader.actions")}
            </th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan={10} className="text-center py-4">
                <CircularProgress />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-gray-600 dark:text-white">
            {carriers.map((carrier) => (
              <tr
                key={carrier.id}
                className="border-b hover:bg-gray-100 transition duration-150 ease-in-out"
              >
                <td className="px-4 py-3 text-left">{carrier.name}</td>
                <td className="px-4 py-3 text-left">{carrier.vat_id}</td>
                <td className="px-4 py-3 text-left">
                  {carrier.country && (
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag countryCode={carrier.country} svg />
                      {t(`countries.${carrier.country}`)}
                    </div>
                  )}
                </td>
                <td className="px-4 py-3 text-left">{carrier.trucks.length}</td>
                <td className="px-4 py-3 text-left">
                  {carrier.drivers.length}
                </td>
                <td className="px-4 py-3 text-left">
                  {carrier.employees.length}
                </td>
                <td className="px-4 py-3 text-left">
                  <Link to={`/carriers/${carrier.id}`}>
                    <button className="text-white font-bold py-1 px-4 rounded text-sm bg-danger">
                      <FaPencilAlt className="h-5 w-5 text-accent" />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CarriersTable;
