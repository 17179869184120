import React from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import { LANGS } from "../../types/langs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TemplateBase } from "../../types/templates";
import TemplateMdEditor from "./TemplateMdEditor";

interface TemplateEditorProps {
  template: TemplateBase;
  placeholder: string;
  subjectPlaceholder: string;
  variables: string[];
  disableSubject?: boolean;
  handleSave: (data: any) => void;
  onPreview?: (data: any) => void;
  onDelete?: () => void;
}

const TemplateEditor: React.FC<TemplateEditorProps> = ({
  template,
  placeholder,
  subjectPlaceholder,
  variables,
  handleSave,
  onPreview,
  disableSubject = false,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      name: template.name,
      language: template.language,
      subject: template.subject,
      message: template.message,
    },
  });

  const onChange = (content: string) => {
    setValue("message", content);
  };

  const onSubjectChange = (subject: string) => {
    setValue("subject", subject);
  };

  const handlePreview = () => {
    if (!onPreview) {
      return;
    }
    onPreview(getValues());
  };

  return (
    <form
      className="p-4 grid grid-cols-1 gap-2"
      onSubmit={handleSubmit(handleSave)}
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <TextField
            {...field}
            label={t("templates.name")}
            variant="outlined"
            fullWidth
            required
          />
        )}
      />
      <Controller
        control={control}
        name="language"
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("templates.language")}
            variant="outlined"
            fullWidth
            required
          >
            {LANGS.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {t(`langs.${lang}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <TemplateMdEditor
        subject={template.subject}
        variables={variables}
        content={template.message}
        onChange={onChange}
        placeholder={placeholder}
        onSubjectChange={disableSubject ? undefined : onSubjectChange}
        subjectPlaceholder={subjectPlaceholder}
      />
      <div className="flex justify-end space-x-2">
        {onDelete && (
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            {t("common.delete")}
          </Button>
        )}
        {onPreview && (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={handlePreview}
          >
            {t("templates.preview")}
          </Button>
        )}
        <Button type="submit" variant="contained" color="primary">
          {t("common.save")}
        </Button>
      </div>
    </form>
  );
};

export default TemplateEditor;
