export type TemplateBase = {
  id: number;
  name: string;
  language: string;
  subject: string;
  message: string;
}

export type OrderTemplate = TemplateBase & {
  attach_cmrs: boolean;
  attach_invoices: boolean;
}

export type OrderFileTemplate = {
  id: number;
  name: string;
  language: string;
  content: string;
}

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

export interface TemplateMdEditorProps {
  subject?: string;
  subjectPlaceholder?: string;
  content: string;
  variables: string[];
  placeholder: string;
  onChange: (content: string) => void;
  onSubjectChange?: (subject: string) => void;
}

export interface RenderElementProps {
  attributes: any;
  children: any;
  element: any;
}

export interface LeafProps {
  attributes: any;
  children: any;
  leaf: any;
}