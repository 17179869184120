import { MenuItem, TextField, ListSubheader } from "@mui/material";
import { Carrier } from "../../types/carriers";
import { Company } from "../../types/companies";
import { useTranslation } from "react-i18next";

interface CarrierSelectProps {
  company: Company;
  carriers: Carrier[];
  label: string;
  value: string | null;
  onChange: (value: number, isCompany: boolean) => void;
  size?: "small" | "medium";
  disabled?: boolean;
}

const CarrierSelect: React.FC<CarrierSelectProps> = ({
  carriers,
  company,
  label,
  value,
  onChange,
  size = "small",
  disabled = false,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    const isCompany = selectedValue.startsWith("company-");
    const id = parseInt(selectedValue.split("-")[1], 10);
    onChange(id, isCompany);
  };
  const { t } = useTranslation();

  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      size={size}
      disabled={disabled}
    >
      <MenuItem
        key={`company-${company.id}`}
        value={`company-${company.id}`}
        style={{ fontWeight: "bold", color: "blue" }}
      >
        {company.name}
      </MenuItem>
      <ListSubheader>{t("carrierSelect.carriers")}</ListSubheader>
      {carriers.map((c) => (
        <MenuItem key={`carrier-${c.id}`} value={`carrier-${c.id}`}>
          {c.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CarrierSelect;
