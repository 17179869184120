import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Skeleton } from "@mui/material";
import MonthlyForwardersGraph from "./MonthlyForwardersGraph";
import ContractorCarrierMarginsGraph from "./ContractorCarrierMarginsGraph";
import ForwardersGraph from "./ForwardersGraph";

interface ForwardersReport {
  monthly_forwarders: any;
  forwarders: any;
  contractor_carriers_margins: any;
}

const ForwardersComponent = () => {
  const [forwardersReport, setIncomesReport] =
    useState<ForwardersReport | null>(null);
  const axios = useAxios();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/insights/reports/forwarders")
      .then((response) => {
        setIncomesReport(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading || !forwardersReport) {
    return (
      <div className="grid grid-cols-1">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 graph-container">
      <MonthlyForwardersGraph
        monthlyForwarderIncomes={forwardersReport.monthly_forwarders}
      />
      <ContractorCarrierMarginsGraph
        margins={forwardersReport.contractor_carriers_margins}
      />
      <ForwardersGraph forwarders={forwardersReport.forwarders} />
    </div>
  );
};

export default ForwardersComponent;
