export const EMPLOYMENT_TYPES = ["b2b", "mandate", "contract"];

export const EMPLOYEE_DOCUMENT_TYPES = [
  "contract",
  "id_card",
  "passport",
  "other",
];
export const EMPLOYEE_PAYMENT_CATEGORY_TYPES = [
  "advance",
  "bonus",
  "salary",
  "other",
];

export const DEFAULT_EMPLOYEE_PAYMENT = "salary";

export interface EmployeeDocument {
  id: number;
  file: string;
  description: string;
  created_at: string;
  type: string;
  end_date: string | null;
}

export type EmployeeDocumentFormData = Omit<
  EmployeeDocument,
  "id" | "created_at" | "file"
>;

export type Employee = {
  id: number;
  email: string;
  full_name: string;
  first_name: string;
  last_name: string;
  phone: string;
  pesel: string;
  status: string;
};

export type EmployeePaymentHistory = {
  id: number;
  date: string;
  taxable_net_pay: number;
  net_pay: number;
  gross_pay: number;
  vat_amount: number;
  date_start: string;
  date_end: string;
  payment_date: string;
  notes: string;
};

export type EmployeePayment = {
  base_taxable_net_daily_pay: number;
  base_net_daily_pay: number;
  currency: string;
  employment_type: string;
  vat_rate: number;
};

export type EmployeeDetails = Employee & {
  payment_info?: EmployeePayment;
  payment_history: EmployeePaymentHistory[];
  documents: EmployeeDocument[];
};

export const EMPLOYEE_STATUS_FILTER_OPTIONS = [
  "active",
  "candidate",
  "fired",
  "resigned",
  "inactive",
];
