import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const exportTypeOptions = [
  'pdf',
  'jpk_v7m',
]

const getExportTypeExtension = (exportType: string) => {
  switch (exportType) {
    case 'jpk_v7m':
      return 'xml';
    default:
      return 'pdf';
  }
}

const getFileName = (exportType: string, sellDateStart: Date, sellDateEnd: Date) => {
  const now = new Date();
  // format this date to dd-mm-yyyy-hh-mm-ss
  const formattedDate = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
  switch (exportType) {
    case 'jpk_v7m':
      return `jpk-v7m-${formattedDate}`;
    default:
      return `invoices_${sellDateStart.toISOString().split("T")[0]}_${sellDateEnd.toISOString().split("T")[0]
        }`;
  }
}

const ExportInvoices = () => {
  const axios = useAxios();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [exportType, setExportType] = useLocalStorage<string>("exportType", "pdf");
  const [error, setError] = useState<string | null>(null);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      setLoading(true);
      const params = exportType === 'jpk_v7m'
        ? { year, month, export_type: exportType }
        : {
            sell_date_start: sellDateStart.toISOString().split("T")[0],
            sell_date_end: sellDateEnd.toISOString().split("T")[0],
            export_type: exportType,
          };

      const response = await axios.get("/invoices/export", {
        params: params,
        responseType: "blob", // Important to handle file response
      });

      // Create a blob URL and trigger download
      const extension = getExportTypeExtension(exportType);
      const blob = new Blob([response.data], { type: `application/${extension}` });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const filename = getFileName(exportType, sellDateStart, sellDateEnd) + `.${extension}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      // if status 404 - then display no invoices
      if (err.response?.status === 404) {
        setError(t("invoices.noInvoices"));
      } else {
        setError(t("invoices.exportError"));
      }
    } finally {
      setLoading(false);
    }
  };

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  startDate.setDate(1);

  const endDate = new Date();
  endDate.setMonth(endDate.getMonth(), 0);

  const [sellDateStart, setSellDateStart] = useState<Date>(startDate);
  const [sellDateEnd, setSellDateEnd] = useState<Date>(endDate);

  return (
    <div className="p-6 w-full max-w-xl flex flex-col">
      <h2 className="text-2xl font-bold mb-4">
        {t("invoices.exportInvoices")}
      </h2>
      {error && (
        <div className="bg-red-100 text-red-700 p-2 rounded mb-4">{error}</div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4 flex flex-col w-full">
        <Select
          label={t("invoices.exportType")}
          value={exportType}
          onChange={(e) => setExportType(e.target.value)}
          fullWidth
        >
          {exportTypeOptions.map((type) => (
            <MenuItem key={type} value={type}>{t(`invoices.exportType.${type}`)}</MenuItem>
          ))}
        </Select>
        {exportType === 'jpk_v7m' ? (
          <div className="flex gap-2 w-full">
            <FormControl fullWidth>
              <InputLabel>{t("invoices.selectMonth")}</InputLabel>
              <Select
              fullWidth
                value={month}
                onChange={(e) => setMonth(Number(e.target.value))}
              >
                {[...Array(12)].map((_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {t(`months.${i + 1}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>{t("invoices.selectYear")}</InputLabel>
              <Select
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
              >
                {[...Array(5)].map((_, i) => (
                  <MenuItem key={i} value={new Date().getFullYear() - i}>
                    {new Date().getFullYear() - i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div className="flex gap-2">
            <DatePicker
              label={t("invoices.sellDateStart")}
              value={sellDateStart}
              format="yyyy-MM-dd"
              onChange={(date) => date && setSellDateStart(date)}
            />
            <DatePicker
              label={t("invoices.sellDateEnd")}
              value={sellDateEnd}
              format="yyyy-MM-dd"
              onChange={(date) => date && setSellDateEnd(date)}
            />
          </div>
        )}

        <div>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
          >
            {loading ? t("invoices.loading") : t("invoices.export")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ExportInvoices;
