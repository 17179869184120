import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import useAxios from "../../hooks/useAxios";
import { Button, CircularProgress } from "@mui/material";
import { format } from "date-fns-tz";

const CheckoutForm = () => {
  const { t } = useTranslation();
  const { company, companyLoading } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const axios = useAxios();

  const navigteToDashboard = () => {
    window.location.href = "/";
  }

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post(
        "/subscriptions/create-checkout-session/"
      );
      window.location.href = response.data.url;
    } catch (err) {
      setError("Failed to create checkout session. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (companyLoading) {
      return <CircularProgress />;
    }

    if (!company || !company.subscription) {
      return (
        <div className="bg-white p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {t("subscription.trialTitle")}
          </h2>
          <p className="text-gray-600 mb-4">
            {t("subscription.trialDescription")}
          </p>
          <Button
            variant="contained"
            onClick={handleCheckout}
            disabled={loading}
          >
            {loading ? t("common.loading") : t("subscription.trialCta")}
          </Button>
        </div>
      );
    }

    const { status, is_in_trial, is_active, stripe_url } = company.subscription;

    if (is_active) {
      return (
        <div className="bg-green-100 p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {t("subscription.active.title")}
          </h2>
          <p className="text-gray-600 mb-4">
            {t("subscription.active.description")}
          </p>
          {stripe_url && (
            <a
              href={stripe_url}
              target="_blank"
              rel="noopener noreferrer"
              className="block text-center py-2 px-4 mb-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
            >
              {t("subscription.manage")}
            </a>
          )}
        </div>
      );
    }

    if (is_in_trial) {
      return (
        <div className="bg-yellow-100 p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {t("subscription.inFreeTrial")}
          </h2>
          <p className="text-gray-600 mb-4">
            {t("subscription.inFreeTrialDescription")}: {format(new Date(company.subscription.trial_end), "dd/MM/yyyy HH:mm", { timeZone: "Europe/Warsaw" })}
          </p>
          <Button
            onClick = {navigteToDashboard}
            variant="contained"
            >
            {t("subscription.dashboard")}
            </Button>
        </div>
      );
    }

    if (status === "expired_trial") {
      return (
        <div className="bg-red-100 p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {t("subscription.expired_trial.title")}
          </h2>
          <p className="text-gray-600 mb-4">
            {t("subscription.expired_trial.description")}
          </p>
          <button
            onClick={handleCheckout}
            disabled={loading}
            className={`w-full py-2 px-4 rounded-md text-white ${
              loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
            } transition duration-200`}
          >
            {loading ? "Processing..." : t("subscription.checkout.cta")}
          </button>
        </div>
      );
    }

    if (status === "expired_subscription") {
      return (
        <div className="bg-red-100 p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {t("subscription.expired_subscription.title")}
          </h2>
          <p className="text-gray-600 mb-4">
            {t("subscription.expired_subscription.description")}
          </p>
          {stripe_url && (
            <a
              href={stripe_url}
              target="_blank"
              rel="noopener noreferrer"
              className="block text-center py-2 px-4 mb-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
            >
              {t("subscription.manage")}
            </a>
          )}
          <button
            onClick={handleCheckout}
            disabled={loading}
            className={`w-full py-2 px-4 rounded-md text-white ${
              loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
            } transition duration-200`}
          >
            {loading ? "Processing..." : t("subscription.checkout.cta")}
          </button>
        </div>
      );
    }

    return (
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {t("subscription.renew")}
        </h2>
        <p className="text-gray-600 mb-4">
          {t("subscription.renewDescription")}
        </p>
        <Button
          onClick={handleCheckout}
          disabled={loading}
          variant="contained"
        >
          {loading ? "Processing..." : t("subscription.checkout.cta")}
        </Button>
      </div>
    );
  };

  return (
    <div className="max-w-lg mx-auto mt-10">
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      {renderContent()}
    </div>
  );
};

export default CheckoutForm;
