import { Button, CardContent, Typography, Grid } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import {
  MdCheckCircle,
  MdErrorOutline,
} from "react-icons/md";
import { format } from "date-fns-tz";

const Subscription = () => {
  const axios = useAxios();
  const { company } = useAuth();
  const { t } = useTranslation();

  const onCreateCustomerPortalSession = async () => {
    try {
      const response = await axios.post(
        "/subscriptions/create-customer-portal-session"
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  if (!company || !company.subscription) {
    return <div>{t("account.subscription.noSubscription")}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-secondary text-black rounded-md shadow-lg">
      <Typography variant="h4" className="mb-4">
        {t("account.subscription.subscriptionDetails")}
      </Typography>

      <div>
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t("account.subscription.status")}
            </Typography>
            <Typography variant="body1" className="flex items-center">
              {company.subscription.status === "active" ? (
                <MdCheckCircle className="text-green-500 mr-2" />
              ) : (
                <MdErrorOutline className="text-red-500 mr-2" />
              )}
              {company?.subscription?.status}
            </Typography>
          </CardContent>
        </Grid>
        <p className="text-3xl font-bold text-black">
          {t("account.subscription.isInTrial")}:{" "}
          {company?.subscription?.is_in_trial
            ? t("common.yes")
            : t("common.no")}
        </p>
        <Grid item xs={12} md={6}>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6" gutterBottom>
              {company?.subscription?.is_in_trial
                ? t("account.subscription.trialEnd")
                : t("account.subscription.currentPeriodEnd")}
            </Typography>
            <p className="text-3xl font-bold">
              {company?.subscription?.is_in_trial
                ? format(
                    new Date(company.subscription?.trial_end),
                    "dd/MM/yyyy"
                  )
                : format(
                    new Date(company.subscription.current_period_end),
                    "dd/MM/yyyy"
                  )}
            </p>
          </CardContent>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.freightOrdersAILimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.subscription.freight_orders_ai_limit}{" "}
            </p>
          </CardContent>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.monthlyOrderLimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.usage.orders_count_this_month /
company.subscription.monthly_order_limit}
            </p>
          </CardContent>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.orderFileSizeLimitMB")}
            </Typography>
            <p className="text-3xl font-bold">
              {(company.usage.order_file_size_this_month / 1024 / 1024).toFixed(
                2
              )}{" "}
              /{company.subscription.monthly_order_file_size_limit_mb} MB
            </p>
          </CardContent>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.truckLimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.usage.trucks_count} /{company.subscription.truck_limit}
            </p>
          </CardContent>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.employeeLimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.usage.employees_count} /
              {company.subscription.employees_limit}
            </p>
          </CardContent>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.driversLimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.usage.drivers_count} /
              {company.subscription.drivers_limit}
            </p>
          </CardContent>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6">
              {t("account.subscription.userLimit")}
            </Typography>
            <p className="text-3xl font-bold">
              {company.usage.users_count} /{company.subscription.user_limit}
            </p>
          </CardContent>
        </Grid>

        <Grid item xs={12}>
          <CardContent className="flex justify-between items-center">
            <Typography variant="h6" gutterBottom>
              {t("account.subscription.totalFileStorageLimitMB")}
            </Typography>
            <p className="text-3xl font-bold">
              {(company.usage.total_file_storage_mb / 1024 / 1024).toFixed(2)} /
              {company.subscription.total_file_storage_limit_mb} MB
            </p>
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateCustomerPortalSession}
            className="w-full"
          >
            {t("account.subscription.manageSubscription")}
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default Subscription;
