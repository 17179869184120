import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import MonthlyProfitGraph from "./MonthlyProfitGraph";
import { format } from "date-fns-tz";
import { getCurrencySymbol } from "../../../utils/currencies";
import { useTranslation } from "react-i18next";
import TruckProfits from "./TruckProfits";
import CarrierContractorMargins from "./CarrierContractorMargins";

interface ProfitReport {
  monthly_profit: any;
  profit_metrics: {
    margin: number;
    total_profit: number;
    total_expenses: number;
    total_revenue: number;
  };
  truck_profits: any;
  contractor_carriers_margins: any;
}
interface ProfitComponentProps {
  startDate: Date;
  endDate: Date;
}

const ProfitComponent: React.FC<ProfitComponentProps> = ({
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const [profitsReport, setProfitsReport] = useState<ProfitReport | null>(null);
  const axios = useAxios();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/insights/reports/profit", {
        params: {
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => {
        setProfitsReport(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  if (loading || !profitsReport) {
    return (
      <div className="grid grid-cols-1">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 graph-container">
      <MonthlyProfitGraph monthlyProfits={profitsReport.monthly_profit} />
      <div>
        <h2 className="text-center font-bold">
          {t("insights.profit.metrics.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Card style={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {t("insights.profit.metrics.profit")}
              </Typography>
              <Typography variant="h4" component="p" color="textSecondary">
                {profitsReport.profit_metrics.total_profit.toFixed(2)}{" "}
                {getCurrencySymbol("eur")}
              </Typography>
            </CardContent>
          </Card>
          <Card style={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {t("insights.profit.metrics.margin")}
              </Typography>
              <Typography variant="h4" component="p" color="textSecondary">
                {profitsReport.profit_metrics.margin.toFixed(2)}%
              </Typography>
            </CardContent>
          </Card>
          <Card style={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {t("insights.profit.metrics.expenses")}
              </Typography>
              <Typography variant="h4" component="p" color="textSecondary">
                {profitsReport.profit_metrics.total_expenses.toFixed(2)}{" "}
                {getCurrencySymbol("eur")}
              </Typography>
            </CardContent>
          </Card>
          <Card style={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {t("insights.profit.metrics.revenue")}
              </Typography>
              <Typography variant="h4" component="p" color="textSecondary">
                {profitsReport.profit_metrics.total_revenue.toFixed(2)}{" "}
                {getCurrencySymbol("eur")}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
      <TruckProfits truck_profits={profitsReport.truck_profits} />
      <CarrierContractorMargins
        carrier_contractor_margins={profitsReport.contractor_carriers_margins}
      />
    </div>
  );
};

export default ProfitComponent;
