import { Button, TextField } from "@mui/material";
import { BaseTruck } from "../../../types/truck";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface LoadingInfoFormProps {
  truck: BaseTruck;
  onSubmit: (data: TruckFormData) => void;
}

interface TruckFormData {
  vin: string;
  brand: string;
  model: string;
  parameters: {
    fuel_type: string;
    free_flow_speed_table: string;
    truck_type: string;
  };
  loading_info: {
    loading_width: number;
    loading_length: number;
    loading_height_min: number;
    loading_height_max: number;
    cargo_unit_max_weight: number;
    cargo_unit_max_height: number;
    cargo_volume: number;
    real_max_payload_weight: number;
  };
}

const LoadingInfoForm: React.FC<LoadingInfoFormProps> = ({
  truck,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<TruckFormData>({
    defaultValues: {
      vin: truck.vin,
      brand: truck.brand,
      model: truck.model,
      parameters: {
        fuel_type: truck.parameters?.fuel_type || "diesel",
        truck_type: truck.parameters?.truck_type || "straightTruck",
        free_flow_speed_table:
          truck.parameters?.free_flow_speed_table || "80,0.13,90,0.15",
      },
      loading_info: {
        loading_width: truck.loading_info?.loading_width || 0,
        loading_length: truck.loading_info?.loading_length || 0,
        loading_height_min: truck.loading_info?.loading_height_min || 0,
        loading_height_max: truck.loading_info?.loading_height_max || 0,
        cargo_unit_max_weight: truck.loading_info?.cargo_unit_max_weight || 0,
        cargo_unit_max_height: truck.loading_info?.cargo_unit_max_height || 0,
        cargo_volume: truck.loading_info?.cargo_volume || 0,
        real_max_payload_weight:
          truck.loading_info?.real_max_payload_weight || 0,
      }
    },
  });

  const onFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <form
      onSubmit={onFormSubmit}
      className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white"
    >
      <div className="flex justify-between items-center border-b p-2 my-2">
        <p className="text-lg font-bold">{truck.name}</p>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <Controller
          name="loading_info.loading_width"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.loadingWidth")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.loading_length"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.loadingLength")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.loading_height_min"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.loadingHeightMin")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.loading_height_max"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.loadingHeightMax")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.cargo_unit_max_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.cargoUnitMaxWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.cargo_unit_max_height"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.cargoUnitMaxHeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.cargo_volume"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.cargoVolume")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="loading_info.real_max_payload_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.loadingInfo.realMaxPayloadWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <Button variant="contained" type="submit" className="py-2 mt-2">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default LoadingInfoForm;
