import React, { useState } from "react";
import { VAT_RATE_TYPES, BaseInvoiceType } from "../../types/invoices";
import { CURRENCIES } from "../../consts/currencies";
import { COUNTRIES, DEFAULT_COUNTRY } from "../../consts/countries";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import {
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../utils/currencies";
import ReactCountryFlag from "react-country-flag";
import { BankAccount, CompanySuggestion } from "../../types/companies";
import useAxios from "../../hooks/useAxios";
import BankAccountForm from "./BankAccountForm";
import { MdAdd } from "react-icons/md";
import { useBankAccounts } from "../../contexts/BankAccountsContext";
import { useToast } from "../../hooks/useToast";

interface InvoicePreviewProps {
  invoicePreview: BaseInvoiceType;
  onCreateInvoice: (invoice: BaseInvoiceType) => void;
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({
  invoicePreview,
  onCreateInvoice,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues, reset, watch } =
    useForm<BaseInvoiceType>({
      defaultValues: {
        ...invoicePreview,
        recipient: {
          ...invoicePreview.recipient,
          country: invoicePreview.recipient?.country || DEFAULT_COUNTRY,
        },
        items: invoicePreview.items || [],
        prices: invoicePreview.prices.map((price) => ({
          ...price,
          vat_rate: price.vat_rate === null ? "np" : price.vat_rate,
        })),
      },
    });
  const axios = useAxios();
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [bankAccountModalOpen, setBankAccountModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [companySuggestions, setCompanySuggestions] = useState<
    CompanySuggestion[]
  >([]);
  const toast = useToast();

  const { bankAccounts, addBankAccount } = useBankAccounts();

  const onSubmit = handleSubmit((data) => {
    try {
      const parsedData = {
        ...data,
        prices: data.prices.map((price) => ({
          ...price,
          vat_rate: price.vat_rate === "np" ? null : price.vat_rate,
        })),
        items: data.items.map((item) => ({
          ...item,
          price: {
            ...item.price,
            vat_rate: item.price.vat_rate === "np" ? null : item.price.vat_rate,
          },
        })),
      };
      setCreateLoading(true);
      onCreateInvoice(parsedData);
    } finally {
      setCreateLoading(false);
    }
  });

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const name = getValues("recipient.name");
      const vatId = getValues("recipient.vat_id");
      const response = await axios.post("/integrations/companies/search", {
        name: name,
        nip: vatId,
      });
      if (response.data.companies.length === 0) {
        setCompanySuggestions([]);
        setIsCompanyModalOpen(true);
        return;
      }
      setCompanySuggestions(response.data.companies);
      setIsCompanyModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch companies:", error);
      setValue(
        "recipient.country",
        invoicePreview.recipient?.country || DEFAULT_COUNTRY
      );
      setValue("recipient.city", invoicePreview.recipient?.city || "");
      setValue("recipient.street", invoicePreview.recipient?.street || "");
      setValue(
        "recipient.building_number",
        invoicePreview.recipient?.building_number || ""
      );
      setValue(
        "recipient.postal_code",
        invoicePreview.recipient?.postal_code || ""
      );
      setValue(
        "recipient.apartment_number",
        invoicePreview.recipient?.apartment_number || ""
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanySelect = (company: CompanySuggestion) => {
    setValue("recipient.name", company.name);
    setValue("recipient.vat_id", company.nip);
    const country = COUNTRIES.find(
      (country) => country === company.address.country.toLowerCase()
    );
    setValue("recipient.country", country || DEFAULT_COUNTRY);
    setValue("recipient.street", company.address.street || "");
    setValue("recipient.building_number", company.address.building || "");
    setValue("recipient.postal_code", company.address.postal_code || "");
    setValue("recipient.apartment_number", company.address.local || "");
    setValue("recipient.city", company.address.city || "");
    setIsCompanyModalOpen(false);
  };

  const { fields } = useFieldArray({
    control,
    name: "prices",
  });

  const onAddBankAccount = async (data: BankAccount) => {
    const parsedData = {
      ...data,
      account_number: data.account_number.replace(/\s/g, ""),
    };
    try {
      await addBankAccount(parsedData);
      toast({
        type: "success",
        message: "Bank account added",
      });
    } catch (error) {
      console.error("Failed to add bank account:", error);
      toast({
        type: "error",
        message: "Failed to add bank account",
      });
    }
    setBankAccountModalOpen(false);
    reset();
  };

  const onFetchNbp = async () => {
    try {
      const date = new Date(getValues("nbp_date")).toISOString().split("T")[0];
      const params = {
        date: date,
        currency_to: getValues("prices.0.currency"),
        currency_from: getValues("prices.1.currency"),
      }
      const response = await axios.get("/integrations/nbp/exchange-rate", { params });
      setValue("nbp_id", response.data.nbp_id);
      setValue("nbp_date", response.data.nbp_date);
      setValue("nbp_exchange_rate", response.data.exchange_rate);
    } catch (error) {
      toast({
        type: "error",
        message: "Failed to fetch NBP exchange rate",
      });
      console.error("Failed to fetch NBP exchange rate:", error);
    }
  };

  const nbp_id_watch = watch("nbp_id");
  const nbp_exchange_rate_watch = watch("nbp_exchange_rate");

  return (
    <>
      <form className="p-4 bg-white shadow-lg rounded-lg" onSubmit={onSubmit}>
        <div className="mb-4">
          <h2 className="text-lg font-bold my-2">
            {t("orderDetails.payment.invoicePreview.title")}
          </h2>
          <div className="grid grid-cols-2 gap-2">
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.number")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="sell_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.sellDate")}
                  sx={{ mb: 2 }}
                  format="dd.MM.yyyy"
                  value={field.value ? new Date(field.value) : null}
                />
              )}
            />
            {
              nbp_id_watch && nbp_exchange_rate_watch &&<div className="col-span-2 flex border-2 border-gray-200 p-2">
              <div className="w-2/3">
                {" "}
                <p className="text-md font-semibold my-2">
                  <p className="text-md font-semibold my-2">
                    {t("orderDetails.payment.invoicePreview.nbp.exchangeRate")}:
                    {nbp_id_watch}
                  </p>
                  <p className="text-md font-semibold my-2">
                    {t(
                      "orderDetails.payment.invoicePreview.nbp.exchangeRateValue"
                    )}
                    :{nbp_exchange_rate_watch}
                  </p>
                </p>
              </div>
              <div className="w-1/2 flex gap-2 flex-col">
                <Controller
                  name="nbp_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={field.value ? new Date(field.value) : null}
                      format="yyyy-MM-dd"
                      label={t("orderDetails.payment.invoicePreview.nbp.date")}
                    />
                  )}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onFetchNbp()}
                  type="button"
                >
                  {t("orderDetails.payment.invoicePreview.nbp.fetch")}
                </Button>
              </div>
            </div>
            }
            
            <DatePicker
              label={t("orderDetails.payment.invoicePreview.issueDate")}
              sx={{ mb: 2 }}
              format="dd.MM.yyyy"
              value={new Date(invoicePreview.issue_date)}
              onChange={(newValue) => {
                if (newValue) {
                  const parsedDataString = dayjs(newValue).format("DD.MM.YYYY");
                  setValue("issue_date", parsedDataString);
                }
              }}
            />
            <Controller
              name="due_days"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.dueDays")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.remarks")}
                  variant="outlined"
                  fullWidth
                  rows={4}
                  multiline
                />
              )}
            />
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-md font-semibold my-2">
            {t("orderDetails.payment.invoicePreview.product.title")}
          </h3>
          <Controller
            name={`items.0.name`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orderDetails.payment.invoicePreview.product.name")}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <div className="grid grid-cols-2 gap-2 my-2">
            <Controller
              name="items.0.price.net_value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.product.rate")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="items.0.price.vat_value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.product.vatValue"
                  )}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="items.0.quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.product.quantity"
                  )}
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="items.0.price.currency"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t(
                    "orderDetails.payment.invoicePreview.product.currency"
                  )}
                  variant="outlined"
                  className="w-64"
                >
                  {CURRENCIES.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {getCurrencySymbol(currency)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="items.0.price.vat_rate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t(
                    "orderDetails.payment.invoicePreview.product.vatRate"
                  )}
                  variant="outlined"
                  className="w-64"
                  value={field.value === "" ? "" : field.value}
                >
                  {VAT_RATE_TYPES.map((vat_rate) => (
                    <MenuItem key={vat_rate} value={vat_rate}>
                      {vat_rate}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <div>
            <p className="text-md font-semibold my-2">
              {t("orderDetails.payment.invoicePreview.total")}
            </p>
            {fields.map((price, index) => (
              <div key={price.id} className="grid grid-cols-5 gap-2 my-2">
                <Controller
                  name={`prices.${index}.net_value`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "orderDetails.payment.invoicePreview.product.rate"
                      )}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`prices.${index}.vat_value`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "orderDetails.payment.invoicePreview.product.vatValue"
                      )}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`prices.${index}.gross_value`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "orderDetails.payment.invoicePreview.product.grossValue"
                      )}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />{" "}
                <Controller
                  name={`prices.${index}.currency`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t(
                        "orderDetails.payment.invoicePreview.product.currency"
                      )}
                      variant="outlined"
                    >
                      {CURRENCIES.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {getCurrencySymbol(currency)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name={`prices.${index}.vat_rate`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t(
                        "orderDetails.payment.invoicePreview.product.vatRate"
                      )}
                      variant="outlined"
                      value={field.value === "" ? "" : field.value}
                    >
                      {VAT_RATE_TYPES.map((vat_rate) => (
                        <MenuItem key={vat_rate} value={vat_rate}>
                          {vat_rate}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="text-md font-semibold my-2">
            {t("orderDetails.payment.invoicePreview.bankAccount.title")}
          </p>
          <div className="flex items-center gap-2">
            <p className="text-md font-semibold my-2">
              {t("orderDetails.payment.invoicePreview.bankAccount.add")}
            </p>
            <IconButton
              onClick={() => setBankAccountModalOpen(true)}
              color="primary"
            >
              <MdAdd />
            </IconButton>
          </div>

          <Controller
            name="bank_accounts"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t(
                  "orderDetails.payment.invoicePreview.bankAccount.select"
                )}
                fullWidth
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
              >
                {bankAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.bank_name} - {account.account_number} -{" "}
                    {account.currency.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>

        <div>
          <h3 className="text-md font-semibold my-4">
            {t("orderDetails.payment.invoicePreview.shipper.title")}
          </h3>
          <div className="grid grid-cols-2 gap-2">
            <Controller
              name="recipient.name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.shipper.name")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <div className="flex items-start gap-2">
              <Controller
                name="recipient.vat_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t(
                      "orderDetails.payment.invoicePreview.shipper.vatId"
                    )}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <Button
                variant="contained"
                onClick={fetchCompanies}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}
              >
                {isLoading
                  ? t("orders.orderCreate.shipperModal.fetchingCompanies")
                  : t("orders.orderCreate.shipperModal.fetchCompanies")}
              </Button>
            </div>
            <Controller
              name="recipient.street"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.shipper.street"
                  )}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="recipient.building_number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.shipper.buildingNo"
                  )}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="recipient.apartment_number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.shipper.apartmentNo"
                  )}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="recipient.postal_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(
                    "orderDetails.payment.invoicePreview.shipper.postalCode"
                  )}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="recipient.city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.payment.invoicePreview.shipper.city")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="recipient.country"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t(
                    "orderDetails.payment.invoicePreview.shipper.country"
                  )}
                  fullWidth
                  variant="outlined"
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country} value={country}>
                      <div className="flex items-center gap-2">
                        <ReactCountryFlag countryCode={country} svg />
                        {t(`countries.${country}`)}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
          disabled={createLoading}
        >
          {t("orderDetails.payment.invoicePreview.create")}
        </Button>
        <Dialog
          open={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
        >
          <DialogTitle>
            {t("orders.orderCreate.shipperModal.companiesModal.title")}
          </DialogTitle>
          <DialogContent>
            {companySuggestions.length === 0 ? (
              <Typography variant="body1">
                {t("orders.orderCreate.shipperModal.noCompaniesFound")}
              </Typography>
            ) : (
              companySuggestions.map((company) => (
                <MenuItem
                  key={company.nip}
                  onClick={() => handleCompanySelect(company)}
                >
                  {company.name} - {company.nip}
                </MenuItem>
              ))
            )}
          </DialogContent>
        </Dialog>
      </form>

      <Dialog
        open={bankAccountModalOpen}
        onClose={() => setBankAccountModalOpen(false)}
      >
        <DialogTitle>
          {t("orderDetails.payment.invoicePreview.bankAccount.add")}
        </DialogTitle>
        <DialogContent>
          <BankAccountForm onSubmit={onAddBankAccount} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InvoicePreview;
