import { TextField, MenuItem, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DriverDetails } from "../../../types/driver";
import { useTranslation } from "react-i18next";
import { EMPLOYMENT_TYPES } from "../../../types/employees";

interface DriverPaymentForm {
  driver: DriverDetails;
  onFormSubmit: (data: any) => void;
}

const DriverPaymentForm: React.FC<DriverPaymentForm> = ({
  driver,
  onFormSubmit,
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      employment_type: driver.payment_info?.employment_type,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="grid gap-4 grid-cols-2"
    >
      <Controller
        name="employment_type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("driverDetail.payments.fields.employmentType")}
            variant="outlined"
          >
            {EMPLOYMENT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(`employmentTypes.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default DriverPaymentForm;
