import { useTranslation } from "react-i18next";
import { DelegationModel } from "../../types/delegations";
import { useEffect, useState } from "react";

interface DelegationSummaryProps {
  delegation: DelegationModel;
}

const DelegationSummary: React.FC<DelegationSummaryProps> = ({
  delegation,
}) => {
  const { t } = useTranslation();
  const [standDays, setStandDays] = useState<number>(0);
  const [days, setDays] = useState<number>(0);

  useEffect(() => {
    const dayDiff = Math.abs(
      new Date(delegation.end_date).getTime() -
        new Date(delegation.start_date).getTime()
    );
    const standDayDiff = Math.abs(
      new Date(delegation.stand_start_date).getTime() -
        new Date(delegation.start_date).getTime()
    );
    setDays(Math.ceil(dayDiff / (1000 * 3600 * 24)));
    setStandDays(Math.ceil(standDayDiff / (1000 * 3600 * 24)));

  }, [delegation]);

  return (
    <div>
      <p className="text-gray-700 dark:text-white text-lg font-bold">
        {t("delegation.days")}: {days}
      </p>
      <p className="text-gray-700 dark:text-white text-lg font-bold">
        {t("delegation.standDays")}: {standDays}
      </p>
    </div>
  );
};

export default DelegationSummary;
