import React from "react";
import { Route } from "../../types/routes";
import { calculateRouteDifference } from "../../utils/routes";

interface RouteSummaryProps {
  name: string;
  route: Route;
  activeRoute: Route;
  hoveredRoute: Route | null;
  onSetActiveRoute: () => void;
  onHoverRoute: () => void;
  onLeaveHoverRoute: () => void;
}

const RouteSummary: React.FC<RouteSummaryProps> = ({
  name,
  route,
  activeRoute,
  hoveredRoute,
  onSetActiveRoute,
  onHoverRoute,
  onLeaveHoverRoute,
}) => {
  const { difference } = calculateRouteDifference(route, activeRoute);

  const getDifferenceDisplay = (differenceValue: number) => {
    if (differenceValue > 0) {
      return { sign: "+", className: "text-green-500" };
    } else if (differenceValue < 0) {
      return { sign: "", className: "text-red-500" };
    } else {
      return { sign: "", className: "text-white" };
    }
  };

  const getTimeDisplay = (days: number, hours: number, minutes: number) => {
    const totalDaysString = days > 0 ? `${days}d` : "";
    const totalHoursString = hours > 0 ? `${hours}h` : "";
    const totalMinutesString = minutes > 0 ? `${minutes}m` : "";
    return `${totalDaysString} ${totalHoursString} ${totalMinutesString}`;
  };

  return (
    <div
      className={`w-full grid grid-cols-4 text-sm text-secondary  px-4 py-2 hover:bg-neutral-400 cursor-pointer
          ${hoveredRoute && hoveredRoute.id === route.id ? "bg-slate-800" : ""}
        `}
      onClick={onSetActiveRoute}
      onMouseEnter={onHoverRoute}
      onMouseLeave={onLeaveHoverRoute}
    >
      <div>
        <span className="font-semibold">{name}</span>
      </div>
      <div className="text-center">
        <span className="font-semibold">
          {route.summary.total_tolls.toFixed(2)}€
        </span>
        <span
          className={`font-semibold ${
            getDifferenceDisplay(-difference.totalTollsDifference).className
          }`}
        >
          {getDifferenceDisplay(difference.totalTollsDifference).sign}
          {difference.totalTollsDifference.toFixed(2) !== "0.00"
            ? `${difference.totalTollsDifference.toFixed(2)}€`
            : ""}{" "}
        </span>
      </div>
      <div className="text-center">
        <span className="font-semibold">
          {(route.summary.distance / 1000).toFixed(1)}km
        </span>
        <span
          className={`font-semibold ${
            getDifferenceDisplay(-difference.totalDistanceDifference).className
          }`}
        >
          {difference.totalDistanceDifference.toFixed(1) !== "0.0" && (
            <>
              {getDifferenceDisplay(difference.totalDistanceDifference).sign}
              {(difference.totalDistanceDifference / 1000).toFixed(1)} km
            </>
          )}
        </span>
      </div>
      <div className="text-center">
        <span className="font-semibold">
          {(route.summary.duration / 3600).toFixed(1)}h
        </span>
        <span
          className={`font-semibold ${
            getDifferenceDisplay(-difference.totalDurationDifference).className
          }`}
        >
          {getDifferenceDisplay(difference.totalDurationDifference).sign}
          {getTimeDisplay(
            difference.totalTimeDaysDifference,
            difference.totalTimeHoursDifference,
            difference.totalTimeMinutesDifference
          )}
        </span>
      </div>
    </div>
  );
};

export default RouteSummary;
