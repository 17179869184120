import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Stepper,
  Step,
  StepContent,
  StepLabel,
} from "@mui/material";
import { DEFAULT_CURRENCY } from "../../../consts/currencies";
import { useTranslation } from "react-i18next";
import {
  ORDER_FILE_CREATE_STEPS,
  OrderInfo,
  OrderOperationFormData,
} from "../../../types/orders";
import SubcontractorOrderFileCreateFirstStep from "./SubcontractorOrderFileCreateFirstStep";
import SubcontractorOrderFileCreateSecondStep from "./SubcontractorOrderFileCreateSecondStep";
import SubcontractorOrderFileCreateThirdStep from "./SubcontractorOrderFileCreateThirdStep";
import CarrierCreate from "../../Orders/CarrierCreate";
import { useToast } from "../../../hooks/useToast";
import { useStrider } from "../../../contexts/StriderContext";
import SubcontractorOrderFileCreateStepFour from "./SubcontractorOrderFileCreateStepFour";
import SubcontractorOrderFileCreateSummary from "./SubcontractorOrderFileCreateSummary";
import { useAuth } from "../../../contexts/AuthContext";
import { hasAccess } from "../../../utils/permissions";

interface OrderCarrierDetailsProps {
  order: OrderInfo;
  loading: boolean;
  onSubmit: (data: BrokerageOrderData) => void;
}

interface BrokerageOrderData {
  subcontractor_cargo_name: string;
  subcontractor_freight_rate: number | null;
  subcontractor_currency: string;
  subcontractor_payment_days: number;
  subcontractor_cargo_remarks: string;
  carrier_id: number;
  truck_id: string;
  operations: OrderOperationFormData[];
  license_plate: string;
  order_file_template_id: number;
  driver_id: number | null;
  driver_first_name: string;
  driver_last_name: string;
  driver_phone: string;
  carrier_assignee_id: number | null;
  carrier_assignee_first_name: string;
  carrier_assignee_last_name: string;
  carrier_assignee_phone: string;
}

const OrderCarrierDetails: React.FC<OrderCarrierDetailsProps> = ({
  order,
  loading,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const handleNext = () => {
    if (isStepValid()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const { addCarrier, carriers } = useStrider();

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { account } = useAuth();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);

  const [activeStep, setActiveStep] = useState(0);
  const translatedSteps = useMemo(
    () =>
      ORDER_FILE_CREATE_STEPS.map((step) => ({
        label: t(`orderFileCreateSteps.${step}`),
        value: step,
      })),
    [t]
  );
  const [carrierModalOpen, setCarrierModalOpen] = useState(false);
  const toast = useToast();
  const [isOpen, setIsModalOpen] = useState(false);
  const handleDialogClose = () => {
    setIsModalOpen(false);
  };
  const { handleSubmit, control, watch, setValue, getValues } =
    useForm<BrokerageOrderData>({
      defaultValues: {
        subcontractor_cargo_name: order.subcontractor_cargo_name || "",
        subcontractor_freight_rate:
          order.subcontractor_freight_rate || order.payment?.price_value
            ? order.payment.price_value * 0.9
            : null,
        subcontractor_currency:
          order.subcontractor_currency || DEFAULT_CURRENCY,
        subcontractor_payment_days: order.subcontractor_payment_days || 30,
        subcontractor_cargo_remarks:
          order.subcontractor_cargo_remarks ||
          order.requirements?.remarks ||
          "",
        operations: order.operations.map((operation) => ({
          operation_type: operation.operation_type,
          time_begin: operation.time_begin,
          time_end: operation.time_end,
          latitude: operation.latitude,
          longitude: operation.longitude,
          country: operation.country,
          address: operation.address || operation.name || operation.locality,
          postal_code: operation.postal_code,
          locality: operation.locality,
        })),
        carrier_id: order.carrier?.id,
        truck_id: order.truck?.license_plate || "",
        license_plate: order.truck?.license_plate || "",
        driver_id: order.driver?.id,
        driver_first_name: order.driver?.first_name || "",
        driver_last_name: order.driver?.last_name || "",
        driver_phone: order.driver?.phone || "",
        carrier_assignee_id: order.carrier_assignee?.id,
        carrier_assignee_first_name: order.carrier_assignee?.first_name || "",
        carrier_assignee_last_name: order.carrier_assignee?.last_name || "",
        carrier_assignee_phone: order.carrier_assignee?.phone || "",
      },
    });
  const [selectedOperationIndex, setSelectedOperationIndex] = useState(0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "operations",
  });
  const carrierIdWatch = watch("carrier_id");
  const truckIdWatch = watch("truck_id");
  const operationsWatch = watch("operations");
  const licensePlateWatch = watch("license_plate");
  const subcontractorFreightRateWatch = watch("subcontractor_freight_rate");
  const subcontractorCurrencyWatch = watch("subcontractor_currency");
  const subcontractorPaymentDaysWatch = watch("subcontractor_payment_days");
  const selectedCountry = watch(`operations.${selectedOperationIndex}.country`);
  const cargoNameWatch = watch("subcontractor_cargo_name");
  const driverIdWatch = watch("driver_id");
  const carrierAssigneeIdWatch = watch("carrier_assignee_id");

  useEffect(() => {
    if (truckIdWatch) {
      setValue("license_plate", truckIdWatch);
    }
  }, [truckIdWatch]);

  useEffect(() => {
    if (driverIdWatch) {
      const carrier = carriers.find((carrier) => carrier.id === carrierIdWatch);
      const drivers = carrier?.drivers || [];
      const selectedDriver =
        drivers.find((driver) => driver.id === driverIdWatch) || null;
      setValue("driver_first_name", selectedDriver?.first_name || "");
      setValue("driver_last_name", selectedDriver?.last_name || "");
      setValue("driver_phone", selectedDriver?.phone || "");
    }
  }, [driverIdWatch, carriers]);

  useEffect(() => {
    if (carrierAssigneeIdWatch) {
      const carrier = carriers.find((carrier) => carrier.id === carrierIdWatch);
      const carrierAssignees = carrier?.employees || [];
      const selectedCarrierAssignee =
        carrierAssignees.find(
          (carrierAssignee) => carrierAssignee.id === carrierAssigneeIdWatch
        ) || null;
      setValue(
        "carrier_assignee_first_name",
        selectedCarrierAssignee?.first_name || ""
      );
      setValue(
        "carrier_assignee_last_name",
        selectedCarrierAssignee?.last_name || ""
      );
      setValue("carrier_assignee_phone", selectedCarrierAssignee?.phone || "");
    }
  }, [carrierAssigneeIdWatch, carriers]);

  useEffect(() => {
    if (carrierIdWatch) {
      if (carrierIdWatch !== order.carrier?.id) {
        setValue("truck_id", "");
        setValue("license_plate", "");
        setValue("driver_id", null);
        setValue("driver_first_name", "");
        setValue("driver_last_name", "");
        setValue("driver_phone", "");
        setValue("carrier_assignee_id", null);
        setValue("carrier_assignee_first_name", "");
        setValue("carrier_assignee_last_name", "");
        setValue("carrier_assignee_phone", "");
      }
    }
  }, [carrierIdWatch]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SubcontractorOrderFileCreateFirstStep
            carrierId={carrierIdWatch}
            control={control}
            setValue={setValue}
            onSetCarrierModalOpen={() => setCarrierModalOpen(true)}
            watch={watch}
          />
        );
      case 1:
        return <SubcontractorOrderFileCreateSecondStep control={control} />;
      case 2:
        return <SubcontractorOrderFileCreateThirdStep control={control} />;
      case 3:
        return (
          <SubcontractorOrderFileCreateStepFour
            control={control}
            fields={fields}
            selectedOperationIndex={selectedOperationIndex}
            selectedCountry={selectedCountry}
            setSelectedOperationIndex={setSelectedOperationIndex}
            append={append}
            remove={remove}
            setValue={setValue}
          />
        );
      case 4:
        return (
          <SubcontractorOrderFileCreateSummary
            control={control}
            fields={fields}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            order={order}
          />
        );
      default:
        return null;
    }
  };

  const validateOperations = () => {
    const errors = operationsWatch.map((operation, index) => {
      let error = "";
      if (!operation.operation_type)
        error += `Operation type is missing for operation ${index + 1}. `;
      if (!operation.time_end)
        error += `End time is missing for operation ${index + 1}. `;
      if (!operation.time_begin)
        error += `Start time is missing for operation ${index + 1}. `;
      if (operation.latitude === null)
        error += `Latitude is missing for operation ${index + 1}. `;
      if (operation.longitude === null)
        error += `Longitude is missing for operation ${index + 1}. `;
      if (!operation.country)
        error += `Country is missing for operation ${index + 1}. `;
      if (!operation.address)
        error += `Address is missing for operation ${index + 1}. `;
      return error;
    });
    return errors.every((error) => error === "");
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return carrierIdWatch && (truckIdWatch || licensePlateWatch);
      case 1:
        return cargoNameWatch;
      case 2:
        return (
          subcontractorFreightRateWatch &&
          subcontractorCurrencyWatch &&
          subcontractorPaymentDaysWatch
        );
      case 3:
        return validateOperations();
      default:
        return true;
    }
  };

  const renderDialogContent = () => {
    return (
      <Stepper activeStep={activeStep} orientation="vertical">
        {translatedSteps.map((step, index) => (
          <Step key={step.value}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {renderStepContent(index)}
              <div className="flex gap-2 mt-4">
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  {t("orders.orderCreate.prev")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleNext}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  disabled={
                    !isStepValid() || activeStep === translatedSteps.length - 1
                  }
                >
                  {t("orders.orderCreate.next")}
                </Button>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  };

  return (
    <>
      {!order.own_order && !order.subcontractor_order_file && hasAccessForUpdate && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
          className="self-start"
        >
          {t("orderDetails.carrier.createOrderFile.create")}
        </Button>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose} maxWidth="xl" fullWidth>
        <DialogTitle>
          {t("orderDetails.carrier.createOrderFile.create")}
        </DialogTitle>
        <DialogContent>
          <form
            className="grid space-y-4 p-4 w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            {renderDialogContent()}

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              {t("orderDetails.carrier.createOrderFile.create")}
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>

        <CarrierCreate
          isCarrierModalOpen={carrierModalOpen}
          onCloseCarrierModal={() => setCarrierModalOpen(false)}
          onCreateCarrier={async (data) => {
            const newCarrier = await addCarrier(data);
            setValue("carrier_id", newCarrier.id);
            setValue("truck_id", "");
            setValue("license_plate", "");
            setValue("driver_id", null);
            setValue("driver_first_name", "");
            setValue("driver_last_name", "");
            setValue("driver_phone", "");
            setValue("carrier_assignee_id", null);
            setValue("carrier_assignee_first_name", "");
            setValue("carrier_assignee_last_name", "");
            setValue("carrier_assignee_phone", "");
            setCarrierModalOpen(false);
            toast({
              message: "Carrier created successfully",
              type: "success",
            });
          }}
        />
      </Dialog>
    </>
  );
};

export default OrderCarrierDetails;
