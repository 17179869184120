import { useEffect, useRef } from "react";
import {
  createMap,
  createOrderOperationMarker,
  createTruckMarker,
  getMarkerIcon,
} from "../utils/maps";
import H from "@here/maps-api-for-javascript";
import { Route } from "../../../types/routes";
import { createSectionsPolylines } from "../utils/polyline";
import SideOverlay from "./MapOverlays/SideOverlay";
import { TruckLocation } from "../../../types/truck";
import { useMap } from "../../../contexts/MapContext";

const MapComponent: React.FC = () => {
  const {
    carrierTruckLocations,
    allMapOrders,
    truckColors,
    allMapRoutes,
    activeRoute,
    hoveredRoute,
    activeSection,
    hoveredSection,
    routeAlternatives,
    selectedTruck,
    selectedOrders,
    ordersToAddToRoute,
    waypoints,
    selectedStartOrder,
    onSetPredefinedTruck,
    onSetSelectedTruck,
    onSetActiveRoute,
    onSetHoveredRoute,
    onSetHoveredSection,
    onSetActiveSection,
  } = useMap();

  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const truckMarkersGroup = useRef(new H.map.Group());
  const orderMarkersGroup = useRef(new H.map.Group());
  const waypointGroup = useRef(new H.map.Group());
  const routePolylinesGroups = useRef<Map<string, H.map.Group>>(new Map());
  const alternativeRoutesGroup = useRef<Map<string, H.map.Group>>(new Map());
  const waypointsGroup = useRef(new H.map.Group());
  const hoveredRouteRef = useRef(hoveredRoute);
  const activeRouteRef = useRef(activeRoute);
  const activeSectionRef = useRef(activeSection);
  const hoveredSectionRef = useRef(hoveredSection);

  useEffect(() => {
    hoveredRouteRef.current = hoveredRoute;
    activeRouteRef.current = activeRoute;
    activeSectionRef.current = activeSection;
    hoveredSectionRef.current = hoveredSection;
  }, [hoveredRoute, activeRoute, activeSection, hoveredSection]);

  useEffect(() => {
    if (!map.current && mapRef && mapRef.current) {
      createMap(platform, mapRef, map);
    }
  }, [map, platform, mapRef]);

  useEffect(() => {
    if (map.current && platform && platform.current) {
      routePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      routePolylinesGroups.current.clear();

      alternativeRoutesGroup.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      alternativeRoutesGroup.current.clear();

      // alternative routes
      routeAlternatives
        .filter((route) => route.id !== activeRoute?.id)
        .forEach((route) => {
          const routeGroup = new H.map.Group({
            volatility: true,
            data: route,
          });
          routeGroup.addEventListener(
            "pointerenter",
            () => {
              document.body.style.cursor = "pointer";
            },
            true
          );
          routeGroup.addEventListener(
            "pointerleave",
            () => {
              document.body.style.cursor = "default";
            },
            true
          );
          const polylines = drawRoutePolylines(route, true);
          polylines.forEach((polyline) => routeGroup.addObject(polyline));
          alternativeRoutesGroup.current.set(route.id, routeGroup);
          map.current?.addObject(routeGroup);
        });

      // main routes
      allMapRoutes
        .filter((route) => route.id !== activeRoute?.id)
        .forEach((route) => {
          const routeGroup = new H.map.Group({
            volatility: true,
            data: route,
          });
          routeGroup.addEventListener(
            "pointerenter",
            () => {
              document.body.style.cursor = "pointer";
            },
            true
          );
          routeGroup.addEventListener(
            "pointerleave",
            () => {
              document.body.style.cursor = "default";
            },
            true
          );
          const polylines = drawRoutePolylines(route, false);
          polylines.forEach((polyline) => routeGroup.addObject(polyline));
          routePolylinesGroups.current.set(route.id, routeGroup);
          map.current?.addObject(routeGroup);
        });

      if (activeRoute) {
        const routeGroup = new H.map.Group();
        const isAlternativeRoute = alternativeRoutesGroup.current.has(
          activeRoute.id
        );
        const polylines = drawRoutePolylines(activeRoute, isAlternativeRoute);
        polylines.forEach((polyline) => routeGroup.addObject(polyline));
        if (isAlternativeRoute) {
          alternativeRoutesGroup.current.set(activeRoute.id, routeGroup);
        } else {
          routePolylinesGroups.current.set(activeRoute.id, routeGroup);
        }
        map.current?.addObject(routeGroup);
      }
    }
  }, [map, allMapRoutes, routeAlternatives, activeRoute]);

  useEffect(() => {
    if (map.current && waypointGroup.current) {
      waypointGroup.current.removeAll();

      if (
        selectedStartOrder &&
        selectedStartOrder.operations &&
        selectedStartOrder.operations.length > 0
      ) {
        const { latitude, longitude } =
          selectedStartOrder.operations[
            selectedStartOrder.operations.length - 1
          ];
        if (latitude && longitude) {
          const marker = new H.map.Marker(
            { lat: latitude, lng: longitude },
            {
              data: selectedStartOrder,
              icon: getMarkerIcon("🚚", "#F67356"),
            }
          );
          waypointGroup.current.addObject(marker);
        }
      }

      waypoints.forEach((waypoint) => {
        if (waypoint.location && typeof waypoint.location !== "string") {
          const { latitude, longitude } = waypoint.location;
          if (latitude && longitude) {
            const typeLabel =
              waypoint.type === "loading"
                ? "▼"
                : waypoint.type === "unloading"
                ? "▲"
                : "⚠";
            const marker = new H.map.Marker(
              { lat: latitude, lng: longitude },
              {
                data: waypoint,
                icon: getMarkerIcon(typeLabel, "#F67356"),
              }
            );
            waypointGroup.current.addObject(marker);
          }
        }
      });

      map.current.addObject(waypointGroup.current);
    }
  }, [map, waypoints, selectedStartOrder]);

  useEffect(() => {
    if (
      map.current &&
      carrierTruckLocations.length > 0 &&
      platform &&
      platform.current &&
      truckColors
    ) {
      truckMarkersGroup.current.removeAll();
      carrierTruckLocations.forEach((truck) => {
        if (selectedTruck && truck.truck !== selectedTruck.truck) {
          return;
        }
        const truckMarker = createTruckMarker(
          truck,
          map,
          truckColors[truck.truck as keyof typeof truckColors]
        );
        truckMarkersGroup.current.addObject(truckMarker);
      });
      map.current.addObject(truckMarkersGroup.current);
    }
  }, [map, carrierTruckLocations, selectedTruck, truckColors]);

  useEffect(() => {
    if (
      map.current &&
      allMapOrders.length > 0 &&
      platform &&
      platform.current &&
      truckColors
    ) {
      orderMarkersGroup.current.removeAll();

      allMapOrders.forEach((order) => {
        const isSelectedOrder = selectedOrders.find(
          (selectedOrder) => selectedOrder.id === order.id
        );
        if (
          selectedTruck &&
          order.truck?.license_plate !== selectedTruck.truck
        ) {
          return;
        }
        order.operations.forEach((operation) => {
          if (isSelectedOrder) {
            return;
          }
          const marker = createOrderOperationMarker(
            operation,
            map,
            order.truck
              ? truckColors[
                  order.truck.license_plate as keyof typeof truckColors
                ]
              : "#444",
            16
          );
          orderMarkersGroup.current.addObject(marker);
        });
      });

      selectedOrders.forEach((order) => {
        order.operations.forEach((operation) => {
          const marker = createOrderOperationMarker(
            operation,
            map,
            truckColors[order.truck?.license_plate as keyof typeof truckColors],
            32
          );
          orderMarkersGroup.current.addObject(marker);
        });
      });

      map.current.addObject(orderMarkersGroup.current);
    }
  }, [
    map,
    allMapOrders,
    selectedOrders,
    selectedTruck,
    truckColors,
    ordersToAddToRoute,
  ]);

  const setHoveredRoute = (route: Route) => {
    if (alternativeRoutesGroup.current.has(route.id)) {
      const routeGroup = alternativeRoutesGroup.current.get(route.id);
      routeGroup?.getObjects().forEach((polyline) => {
        polyline.dispatchEvent("pointerenter");
      });
    }
    onSetHoveredRoute(route);
  };
  
  const onLeaveHoverRoute = (route: Route) => {
    if (alternativeRoutesGroup.current.has(route.id)) {
      const routeGroup = alternativeRoutesGroup.current.get(route.id);
      routeGroup?.getObjects().forEach((polyline) => {
        polyline.dispatchEvent("pointerleave");
      });
    }
    onSetHoveredRoute(null);
  };

  const setActiveRoute = (route: Route | null) => {
    onSetActiveRoute(route);
    if (!route) {
      waypointGroup.current.removeAll();
      waypointsGroup.current.removeAll();
      alternativeRoutesGroup.current.forEach((group) => group.removeAll());
      alternativeRoutesGroup.current.clear();
      routePolylinesGroups.current.forEach((group) => group.removeAll());
      routePolylinesGroups.current.clear();
    }
  };

  const drawRoutePolylines = (route: Route, isAlternativeRoute = false) => {
    if (!truckColors) {
      return [];
    }
    return createSectionsPolylines(
      route,
      activeRoute,
      hoveredRoute,
      activeSection,
      hoveredSection,
      truckColors,
      activeRouteRef,
      activeSectionRef,
      hoveredSectionRef,
      routePolylinesGroups,
      alternativeRoutesGroup,
      isAlternativeRoute,
      onSetHoveredRoute,
      setActiveRoute,
      onSetHoveredSection,
      onSetActiveSection
    );
  };

  const onTruckClick = (truckLocation: TruckLocation | string, isPredefined: boolean) => {
    if (isPredefined) {
      onSetSelectedTruck(null);
      onSetPredefinedTruck(truckLocation as string);
      return;
    }
    const location = truckLocation as TruckLocation;
    if (location.truck === selectedTruck?.truck) {
      onSetSelectedTruck(null);
    } else {
      onSetSelectedTruck(location);
      const center = {
        lat: location.latitude,
        lng: location.longitude,
      };
      map.current?.setZoom(10);
      map.current?.setCenter(center);
    }
  };

  return (
    <div className="min-h-screen max-h-screen relative flex w-full">
      <SideOverlay
        onTruckClick={onTruckClick}
        onUnselectTruck={() => onSetSelectedTruck(null)}
        onSetActiveRoute={setActiveRoute}
        onSetHoveredRoute={setHoveredRoute}
        onLeaveHoverRoute={onLeaveHoverRoute}
      />
      <div className="w-full min-h-screen max-h-screen" ref={mapRef} />
    </div>
  );
};

export default MapComponent;
