import { Route } from "../types/routes";

export const calculateRouteDifference = (
  routeA: Route,
  routeB: Route
) => {
  const timeDifference = routeA.summary.duration - routeB.summary.duration;

  const timeDifferenceDays = Math.floor(timeDifference / 86400);
  const timeDifferenceHours = Math.floor((timeDifference % 86400) / 3600);
  const timeDifferenceMinutes = Math.floor(
    ((timeDifference % 86400) % 3600) / 60
  );


  const difference = {
    totalDistanceDifference: routeA.summary.distance - routeB.summary.distance,
    totalDurationDifference: routeA.summary.duration - routeB.summary.duration,
    totalTimeDaysDifference: timeDifferenceDays,
    totalTimeHoursDifference: timeDifferenceHours,
    totalTimeMinutesDifference: timeDifferenceMinutes,
    totalTollsDifference: routeA.summary.total_tolls - routeB.summary.total_tolls,
  };
  return {
    difference: difference,
  };
};
