import React, { useState } from "react";
import {
  MenuItem,
  TextField,
  IconButton,
  Collapse,
  Checkbox,
  InputLabel,
  Button,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { useMap } from "../../../../contexts/MapContext";
import { TruckLocation } from "../../../../types/truck";
import { useTranslation } from "react-i18next";
import MapTruckOrders from "./MapTruckOrders";
import { IoMdClose } from "react-icons/io";
import { FaCube } from "react-icons/fa6";
import Waypoints from "../../../../components/waypoints/Waypoints";
import { FaCog, FaTimes } from "react-icons/fa";
import { CURRENCIES } from "../../../../consts/currencies";
import { getCurrencySymbol } from "../../../../utils/currencies";
import ExcludeInput from "./ExcludeInput";
import { OrderInfo } from "../../../../types/orders";
import RouteAndAlternatives from "./RouteAndAlternatives";
import { Route } from "../../../../types/routes";
import MapTruckSelect from "../../../../components/mapTruckSelect/MapTruckSelect";
import { getParametersForPredefinedTruck } from "./utils";

interface SideOverlayProps {
  onTruckClick: (truck: TruckLocation | string, isPredefined: boolean) => void;
  onUnselectTruck: () => void;
  onSetActiveRoute: (route: Route) => void;
  onLeaveHoverRoute: (route: Route) => void;
  onSetHoveredRoute: (route: Route) => void;
}

const SideOverlay: React.FC<SideOverlayProps> = ({
  onTruckClick,
  onUnselectTruck,
  onSetActiveRoute,
  onLeaveHoverRoute,
  onSetHoveredRoute,
}) => {
  const { t } = useTranslation();
  const {
    carrierTruckLocations,
    truckColors,
    selectedTruck,
    selectedStartOrder,
    selectedOrders,
    startFromTruck,
    currency,
    freightValue,
    areWaypointsValid,
    waypointMessage,
    selectedOrder,
    allMapOrders,
    routePreviewLoading,
    predefinedTruck,
    onPreviewRoute,
    onSetFreightValue,
    onSetStartFromTruck,
    onSetSelectedStartOrder,
    onSetSelectedOrder,
    onSetSelectedOrders,
    onSetCurrency,
    addOrdersToWaypoints,
    replaceWaypointsWithOrders,
  } = useMap();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<
    "startingOrderSelect" | "fillOperationFromOrderSelect" | "truckSettings"
  >("startingOrderSelect");

  const openDrawer = (content: string) => {
    setDrawerOpen(true);
    setDrawerContent(content as any);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const renderDrawerContent = () => {
    switch (drawerContent) {
      case "startingOrderSelect":
        return (
          <MapTruckOrders
            label={t("map.selectStartingOrder")}
            clickHandler={(order: OrderInfo) => {
              onSetSelectedStartOrder(order);
              closeDrawer();
            }}
          />
        );
      case "fillOperationFromOrderSelect":
        return (
          <div>
            <MapTruckOrders
              label={t("map.fillFromOrderDescription")}
              clickHandler={(order: OrderInfo) => {
                const alreadySelected = selectedOrders.find(
                  (selectedOrder) => selectedOrder.id === order.id
                );
                if (!alreadySelected) {
                  onSetSelectedOrders([...selectedOrders, order]);
                } else {
                  onSetSelectedOrders(
                    selectedOrders.filter(
                      (selectedOrder) => selectedOrder.id !== order.id
                    )
                  );
                }
              }}
            />
            <div className="flex flex-row gap-2">
              <Button
                onClick={() => {
                  addOrdersToWaypoints();
                  onSetSelectedOrders([]);
                }}
                variant="outlined"
                disabled={selectedOrders.length === 0}
              >
                {t("map.addSelectedOrders")}
              </Button>
              <Button
                onClick={() => {
                  replaceWaypointsWithOrders();
                  onSetSelectedOrders([]);
                }}
                variant="outlined"
                disabled={selectedOrders.length === 0}
              >
                {t("map.replaceCurrentOperations")}
              </Button>
            </div>
          </div>
        );
      case "truckSettings":
        return (
          <div>
            {selectedTruck && (
              <div className="grid grid-cols-2 gap-2">
                <TextField
                  label={t("map.vehicleLength")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  disabled
                  value={selectedTruck.parameters.vehicle_length}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.vehicleWidth")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  disabled
                  value={selectedTruck.parameters.vehicle_width}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.vehicleHeightMin")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  disabled
                  value={selectedTruck.parameters.vehicle_height_min}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.vehicleHeightMax")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  disabled
                  value={selectedTruck.parameters.vehicle_height_max}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.vehicleWeight")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  disabled
                  value={selectedTruck.parameters.vehicle_weight}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.axleCount")}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedTruck.parameters.axle_count}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.engineCCM")}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedTruck.parameters.engine_ccm}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.maxSpeed")}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedTruck.parameters.max_speed}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label={t("map.gvm")}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedTruck.parameters.gvm}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            )}
            {!selectedTruck && (
              <div className="grid grid-cols-2 gap-2">
                {(() => {
                  const parameters =
                    getParametersForPredefinedTruck(predefinedTruck as any);
                  return (
                    <>
                      <TextField
                        label={t("map.vehicleLength")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled
                        value={parameters.vehicle_length}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.vehicleWidth")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled
                        value={parameters.vehicle_width}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.vehicleHeightMin")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled
                        value={parameters.vehicle_height_min}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.vehicleHeightMax")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled
                        value={parameters.vehicle_height_max}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.vehicleWeight")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        disabled
                        value={parameters.vehicle_weight}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.axleCount")}
                        variant="outlined"
                        fullWidth
                        disabled
                        value={parameters.axle_count}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.engineCCM")}
                        variant="outlined"
                        fullWidth
                        disabled
                        value={parameters.engine_ccm}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.maxSpeed")}
                        variant="outlined"
                        fullWidth
                        disabled
                        value={parameters.max_speed}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t("map.gvm")}
                        variant="outlined"
                        fullWidth
                        disabled
                        value={parameters.gvm}
                        InputLabelProps={{ shrink: true }}
                      />
                    </>
                  );
                })()}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="z-50 dark:bg-zinc-900 bg-secondary bg-opacity-95 flex flex-row min-h-full relative dark:text-white text-black">
      <div className="w-full flex flex-col gap-3 p-4  overflow-y-auto">
        <div className="py-4 px-1">
          <div className="w-full flex gap-2 flex-col dark:bg-zinc-800 bg-neutral-400 p-4 rounded-3xl">
            <div className="flex flex-row gap-2 items-center">
              <MapTruckSelect
                label={t("map.truck")}
                value={selectedTruck?.truck || predefinedTruck}
                trucks={carrierTruckLocations}
                truckColors={truckColors}
                onChange={onTruckClick}
                onUnselectTruck={onUnselectTruck}
                selectedTruck={selectedTruck}
              />
              <IconButton
                onClick={() => {
                  drawerOpen && drawerContent === "truckSettings"
                    ? closeDrawer()
                    : openDrawer("truckSettings");
                }}
              >
                <FaCog className="text-accent" />
              </IconButton>
            </div>
            <Autocomplete
              size="small"
              value={selectedOrder}
              disabled={!selectedTruck}
              onChange={(e, value) => {
                e.stopPropagation();
                onSetSelectedOrder(value);
              }}
              options={allMapOrders.filter(
                (order) => order.truck?.license_plate === selectedTruck?.truck
              )}
              fullWidth
              getOptionLabel={(option) => option.display}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("map.selectOrder")}
                  variant="outlined"
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={startFromTruck}
                  defaultChecked={startFromTruck}
                  value={startFromTruck}
                  onChange={(e) => onSetStartFromTruck(e.target.checked)}
                  disabled={!selectedTruck}
                />
              }
              labelPlacement="start"
              label={t("map.startFromTruck")}
            />
            {selectedTruck && (
              <div className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  {" "}
                  <InputLabel className="mt-2">
                    {t("map.startFromOrder")}
                  </InputLabel>
                  <IconButton
                    onClick={() =>
                      drawerOpen && drawerContent === "startingOrderSelect"
                        ? closeDrawer()
                        : openDrawer("startingOrderSelect")
                    }
                    style={{ color: "#fff" }}
                  >
                    <FaCube className="text-accent" />
                  </IconButton>
                </div>

                {selectedStartOrder && (
                  <div className="flex items-center gap-2 cursor-pointer ">
                    <div>
                      {selectedStartOrder.ref_number
                        ? selectedStartOrder.ref_number
                        : selectedStartOrder.trans_ref_number}
                    </div>
                    <div>{selectedStartOrder.shipper?.name}</div>
                    <IconButton onClick={() => onSetSelectedStartOrder(null)}>
                      <FaTimes className="cursor-pointer" />
                    </IconButton>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="py-4">
            <Waypoints
              onFillOrderCallback={() =>
                drawerOpen && drawerContent === "fillOperationFromOrderSelect"
                  ? closeDrawer()
                  : openDrawer("fillOperationFromOrderSelect")
              }
            />
          </div>
        </div>
        <div>
          <h2>{t("map.price")}</h2>
          <div className="flex gap-2">
            <div className="w-3/4">
              {" "}
              <TextField
                label={t("map.priceInput")}
                variant="outlined"
                fullWidth
                type="number"
                value={freightValue}
                onChange={(e) => onSetFreightValue(parseInt(e.target.value))}
              />
            </div>
            <div className="w-1/4">
              {" "}
              <TextField
                select
                fullWidth
                label={t("expenseList.fields.currency")}
                variant="outlined"
                value={currency}
                onChange={(e) => onSetCurrency(e.target.value)}
              >
                {CURRENCIES.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    <span className="text-secondary">
                      {getCurrencySymbol(currency)}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <ExcludeInput />
        {!areWaypointsValid && (
          <p className="text-sm text-right text-neutral-500">
            {t(`map.errors.${waypointMessage}`)}
          </p>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={!areWaypointsValid || routePreviewLoading}
          onClick={() => onPreviewRoute()}
        >
          {t("map.calculateRoute")}
        </Button>
      </div>{" "}
      <Collapse
        in={drawerOpen}
        timeout="auto"
        orientation="horizontal"
        sx={{
          position: "absolute",
          top: 0,
          left: "100%",
          overflowY: "auto",
        }}
      >
        <div
          className="p-4 flex flex-col bg-zinc-900"
          style={{ width: "350px" }}
        >
          <div className="self-end">
            <IconButton onClick={closeDrawer} style={{ color: "#fff" }}>
              <IoMdClose />
            </IconButton>
          </div>

          {renderDrawerContent()}
        </div>
      </Collapse>
      <RouteAndAlternatives
        onLeaveHoverRoute={onLeaveHoverRoute}
        setHoveredRoute={onSetHoveredRoute}
        setActiveRoute={onSetActiveRoute}
      />
    </div>
  );
};

export default SideOverlay;
