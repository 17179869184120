import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SubcontractorOrderFileCreateSecondStepProps {
  control: any;
}

const SubcontractorOrderFileCreateSecondStep: React.FC<
  SubcontractorOrderFileCreateSecondStepProps
> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-4 max-w-xl">
      {" "}
      <Controller
        name="subcontractor_cargo_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.cargo")}
            variant="outlined"
            className="w-64"
            required
          />
        )}
      />
      <Controller
        name="ldm"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.ldm")}
            variant="outlined"
            className="w-64"
            type="number"
          />
        )}
      />
      <Controller
        name="weight"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.weight")}
            variant="outlined"
            className="w-64"
            type="number"
            InputProps={{
              endAdornment: <span>t</span>,
            }}
          />
        )}
      />
      <Controller
        name="subcontractor_cargo_remarks"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orderDetails.carrier.createOrderFile.remarks")}
            variant="outlined"
            className="w-64"
            multiline
            rows={4}
          />
        )}
      />
    </div>
  );
};

export default SubcontractorOrderFileCreateSecondStep;
