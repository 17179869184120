import { CorporateDetails } from "./base";
import { Driver } from "./driver";
import { Employee } from "./employees";
import { Subscription } from "./subscription";
import { BaseTruck } from "./truck";
import { User } from "./user";

export type CompanySuggestion = {
  nip: string;
  name: string;
  regon: string;
  address: {
    building: string;
    city: string;
    street: string;
    country: string;
    local: string;
    postal_code: string;
  };
};

export type CompanyUsage = {
  order_file_size_this_month: number;
  trucks_count: number;
  employees_count: number;
  drivers_count: number;
  users_count: number;
  orders_count_this_month: number;
  total_file_storage_mb: number;
};

export type Company = CorporateDetails & {
  trucks: BaseTruck[];
  drivers: Driver[];
  employees: Employee[];
  users: CompanyUser[];
  subscription?: Subscription;
  usage: CompanyUsage;
  invoice_logo: string | null;
};

export type CompanyUser = {
  id: number;
  user: User;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  role: string;
};

export const COMPANY_DOCUMENT_TYPES = [
  "ocp",
  "ocs",
  "ckz",
  "licencja_spedycyjna",
  "licencja_wspolnotowa",
  "zezwolenie_przewoznik",
  "other",
];

export interface BankAccount {
  account_number: string;
  bank_name: string;
  swift: string;
  is_default: boolean;
  currency: string;
}

export interface BankAccountModel extends BankAccount {
  id: number;
}
