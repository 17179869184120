import { FaStore, FaFileInvoice } from "react-icons/fa";
import { FaMoneyBill1Wave, FaSignature, FaUserTie } from "react-icons/fa6";
import { MdOutlineCurrencyExchange, MdAssignment, MdTrackChanges, MdDateRange } from "react-icons/md";
import { Editor } from "slate";
import { LeafProps } from "../../types/templates";

export const getIcon = (variable: string) => {
  switch (variable) {
    case "shipper":
      return <FaStore />;
    case "payment_amount":
      return <FaMoneyBill1Wave />;
    case "payment_currency":
      return <MdOutlineCurrencyExchange />;
    case "order_number":
      return <MdAssignment />;
    case "invoice_number":
      return <FaFileInvoice />;
    case "sender_name":
      return <FaUserTie />;
    case "sender_signature":
      return <FaSignature />;
    case "tracking_link":
      return <MdTrackChanges />;
    case "delivery_date":
      return <MdDateRange />;
    default:
      return null;
  }
};

export const toggleMark = (editor: any, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: any, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as keyof typeof marks] === true : false;
};

export const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
