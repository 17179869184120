import { useEffect, useRef, useState } from "react";

import H from "@here/maps-api-for-javascript";
import {
  createMap,
  createOrderOperationMarker,
  getOrderOperationIcon,
} from "../Map/utils/maps";
import { Route, MatchedRoute } from "../../types/routes";
import { DelegationModel, DelegationStats } from "../../types/delegations";
import useAxios from "../../hooks/useAxios";
import { Button } from "@mui/material";

interface DelegationMapProps {
  delegation: DelegationModel;
  stats: DelegationStats;
}
const DelegationMap: React.FC<DelegationMapProps> = ({ delegation, stats }) => {
  const [routes, setRoutes] = useState<Route[]>([]);
  const [matchedRoutes, setMatchedRoutes] = useState<MatchedRoute[]>([]);
  const axios = useAxios();

  const fetchRoutes = async () => {
    const response = await axios.get(
      `/delegations/${delegation.id}/stats/routes`
    );
    setRoutes(response.data.routes);
    setMatchedRoutes(response.data.matched_routes);
  };

  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const routePolylinesGroups = useRef<Map<string, H.map.Group>>(new Map());
  const orderMarkersGroup = useRef(new H.map.Group());
  const refuelMarkersGroup = useRef(new H.map.Group());
  const matchedRoutePolylinesGroups = useRef<Map<string, H.map.Group>>(
    new Map()
  );

  let uTurn = false;

  useEffect(() => {
    if (!map.current && mapRef && mapRef.current) {
      createMap(platform, mapRef, map);
    }
  }, [map, platform, mapRef]);

  useEffect(() => {
    if (map.current && platform && platform.current && routes.length > 0) {
      routePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      routePolylinesGroups.current.clear();
      const route = routes[0];
      const routeGroup = new H.map.Group({
        volatility: true,
        data: route,
      });
      const polylines = drawRoutePolylines(route);
      polylines.forEach((polyline) => routeGroup.addObject(polyline));
      routePolylinesGroups.current.set(route.id, routeGroup);
      map.current?.addObject(routeGroup);
    }
  }, [map, routes]);

  useEffect(() => {
    if (
      map.current &&
      platform &&
      platform.current &&
      matchedRoutes.length > 0
    ) {
      matchedRoutePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      matchedRoutePolylinesGroups.current.clear();
      matchedRoutes.forEach((matchedRoute, index) => {
        const routeGroup = new H.map.Group({
          volatility: true,
          data: matchedRoute,
        });
        const polylines = drawPolylineForMatchedRoute(matchedRoute);
        console.log(matchedRoutes);
        polylines.forEach((polyline) => routeGroup.addObject(polyline));
        matchedRoutePolylinesGroups.current.set(index.toString(), routeGroup);
        map.current?.addObject(routeGroup);
      });
    }
  }, [map, matchedRoutes]);

  useEffect(() => {
    if (
      map.current &&
      stats.orders.length > 0 &&
      platform &&
      platform.current
    ) {
      orderMarkersGroup.current.removeAll();
      console.log(stats.orders);
      stats.orders.forEach((order) => {
        order.operations.forEach((operation) => {
          const marker = createOrderOperationMarker(operation, map, "#000", 40);
          orderMarkersGroup.current.addObject(marker);
        });
      });
      map.current.addObject(orderMarkersGroup.current);
    }
  }, [map, stats]);

  useEffect(() => {
    if (
      map.current &&
      stats.refuels.length > 0 &&
      platform &&
      platform.current
    ) {
      refuelMarkersGroup.current.removeAll();

      stats.refuels.forEach((refuel) => {
        const marker = new H.map.Marker(
          { lat: refuel.latitude, lng: refuel.longitude },
          {
            data: refuel,
            icon: getOrderOperationIcon("fueling", "#F67356", 20),
          }
        );
        refuelMarkersGroup.current.addObject(marker);
      });
      map.current.addObject(refuelMarkersGroup.current);
    }
  }, [map, stats]);

  const drawRoutePolylines = (route: Route) => {
    const sections = route.sections;
    const polylines: H.map.Polyline[] = [];
    sections.forEach((section) => {
      const lineStrings: H.geo.LineString[] = [];
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const sectionPolyline = new H.map.Polyline(multiLineString, {
        data: section,
        style: {
          lineWidth: 8,
          strokeColor: "rgb(0, 0, 255)",
        },
      });
      polylines.push(sectionPolyline);
    });
    return polylines;
  };

  const drawPolylineForMatchedRoute = (matchedRoute: MatchedRoute) => {
    const links = matchedRoute.leg[0].link;
    let lLinkId = 0;
    const polylines: H.map.Polyline[] = [];
    links.forEach((link) => {
      let coords1 = link.shape;
      const coords2 = new H.geo.LineString();
      let distance = 0;
      if (link.offset && link.offset < 1) {
        if (parseInt(link.linkId) < 0) {
          distance = (1 - link.offset) * link.length;
        } else {
          distance = link.offset * link.length;
        }
        coords1 = getCoordsWithOffset(coords1, distance, 1, links.length);
      }
      if (Math.abs(parseInt(link.linkId)) !== lLinkId) {
        for (let c = 0; c < coords1.length; c += 2) {
          coords2.pushLatLngAlt(coords1[c], coords1[c + 1], undefined);
        }

        lLinkId = Math.abs(parseInt(link.linkId));

        const linkPolyline = new H.map.Polyline(coords2, {
          zIndex: 3,
          style: {
            lineWidth: 8,
            strokeColor: "rgb(0, 0, 255)",
          },
          data: link,
        });
        polylines.push(linkPolyline);
      }
    });
    return polylines;
  };

  const getCoordsWithOffset = (
    coords1: number[],
    distance: number,
    currentLink: number,
    numberOfLinks: number
  ) => {
    const temp = [];
    const prevCoord = [coords1[0], coords1[1]];
    let midPoint = null;
    let midPointIndex = 0;
    for (let c = 0; c < coords1.length; c += 2) {
      const linkLength = getKartesianDistanceInMeter(
        prevCoord[0],
        prevCoord[1],
        coords1[c],
        coords1[c + 1]
      );
      if (distance - linkLength < 0) {
        midPoint = getMidPoint(
          prevCoord[0],
          prevCoord[1],
          coords1[c],
          coords1[c + 1],
          distance
        );
        midPointIndex = c;
        break;
      } else {
        distance = distance - linkLength;
      }
      prevCoord[0] = coords1[c];
      prevCoord[1] = coords1[c + 1];
    }
    if (!midPoint) {
      midPoint = getMidPoint(
        coords1[coords1.length - 4],
        coords1[coords1.length - 3],
        coords1[coords1.length - 2],
        coords1[coords1.length - 1],
        distance
      );
      midPointIndex = coords1.length - 2;
    }
    if (currentLink == 0 || uTurn) {
      if (uTurn) uTurn = false;
      temp.push(midPoint[0]);
      temp.push(midPoint[1]);
      for (let c = midPointIndex; c < coords1.length; c += 1) {
        temp.push(coords1[c]);
      }
    } else {
      if (currentLink != numberOfLinks - 1) uTurn = true;
      for (let c = 0; c < midPointIndex; c += 1) {
        temp.push(coords1[c]);
      }
      temp.push(midPoint[0]);
      temp.push(midPoint[1]);
    }

    return temp;
  };

  const getKartesianDistanceInMeter = function (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const earthRadius = 6371000;
    // convert input parameters from decimal degrees into radians
    const phi1 = (lat1 * Math.PI) / 180;
    const phi2 = (lat2 * Math.PI) / 180;
    const dphi = phi2 - phi1;
    const dl = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dphi / 2) * Math.sin(dphi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(dl / 2) * Math.sin(dl / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
  };

  const getMidPoint = function (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    distance: number
  ) {
    /* var lon = ratio*lon1 + (1.0 - ratio)*lon2;
      var lat = ratio*lat1 + (1.0 - ratio)*lat2;*/

    const heading = getHeading(lat2, lon2, lat1, lon1);
    const shiftedLatLon = shiftLatLon(
      lat1,
      lon1,
      (heading + 180) % 360,
      distance
    ); // only 180 degrees to go into the opposite direction

    return shiftedLatLon;
  };

  function getHeading(lat1: number, lng1: number, lat2: number, lng2: number) {
    const phi1 = lat1 * (Math.PI / 180),
      phi2 = lat2 * (Math.PI / 180),
      dl = (lng2 - lng1) * (Math.PI / 180),
      y = Math.sin(dl) * Math.cos(phi2),
      x =
        Math.cos(phi1) * Math.sin(phi2) -
        Math.sin(phi1) * Math.cos(phi2) * Math.cos(dl),
      t = Math.atan2(y, x);

    return Math.round(((t * 180) / Math.PI + 360) % 360);
  }

  /**
This method shifts the given lat and long along given bearing to the given distance
*/
  function shiftLatLon(
    latDegrees: number,
    lonDegrees: number,
    bearing: number,
    distance: number
  ) {
    const earthRadius = 6371000;
    // convert input parameters from decimal degrees into radians
    const latRad = (latDegrees * Math.PI) / 180;
    const lonRad = (lonDegrees * Math.PI) / 180;

    const bearingRad = (bearing * Math.PI) / 180;
    const distRad = distance / earthRadius;

    const latNewRad = Math.asin(
      Math.sin(latRad) * Math.cos(distRad) +
        Math.cos(latRad) * Math.sin(distRad) * Math.cos(bearingRad)
    );
    const lonNewRad =
      lonRad +
      Math.atan2(
        Math.sin(bearingRad) * Math.sin(distRad) * Math.cos(latRad),
        Math.cos(distRad) - Math.sin(latRad) * Math.sin(latNewRad)
      );

    // convert input parameters from radians into decimal degrees
    const latNewDegrees = (latNewRad * 180) / Math.PI;
    const lonNewDegrees = (lonNewRad * 180) / Math.PI;
    const latLonRet = [];
    latLonRet.push(latNewDegrees);
    latLonRet.push(lonNewDegrees);
    return latLonRet;
  }

  return (
    <div className="space-y-4">
      {" "}
      <Button
        onClick={fetchRoutes}
        variant="contained"
      >
        Fetch Routes
      </Button>
      <div className="min-h-screen max-h-screen relative flex w-full">
        <div className="w-full min-h-screen max-h-screen" ref={mapRef} />
      </div>
    </div>
  );
};

export default DelegationMap;
