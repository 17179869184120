import { useTranslation } from "react-i18next";

interface TemplatePreviewProps {
  htmlPreview: string;
  htmlPreviewSubject?: string;
}

const createIframe = (htmlContent: string) => {
  const iframeContent = `
      <html>
        <head>
          <style>
            /* Add any default styles you want in the iframe */
            body { font-family: Arial, sans-serif; padding: 10px; }
          </style>
        </head>
        <body>
          ${htmlContent}
        </body>
      </html>
    `;
  return iframeContent;
};

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  htmlPreview,
  htmlPreviewSubject,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white text-black p-2">
      {htmlPreviewSubject && (
        <p>
          {t("templates.emailTitle")}:{" "}
          <span className="font-bold">{htmlPreviewSubject}</span>
        </p>
      )}
      <iframe
        title="Preview"
        srcDoc={createIframe(htmlPreview)} // Use srcDoc to inject content directly
        style={{
          width: "100%",
          height: "500px",
          border: "1px solid #ccc",
        }}
      />
    </div>
  );
};

export default TemplatePreview;
