import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddEmployeeForm from "./AddEmployeeForm";
import {
  Employee,
  EMPLOYEE_STATUS_FILTER_OPTIONS,
} from "../../types/employees";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import { useLocalStorage } from "../../hooks/useLocalStorage";

interface EmployeesProps {
  link: string;
  disableStatusFilter?: boolean;
}

const Employees: React.FC<EmployeesProps> = ({
  link,
  disableStatusFilter = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const toast = useToast();

  const [selectedEmployeeStatuses, setSelectedEmployeeStatuses] =
    useLocalStorage("selectedEmployeeStatuses", [
      "active",
      "candidate",
      "fired",
      "resigned",
      "inactive",
    ]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const params = new URLSearchParams();
        if (!disableStatusFilter) {
          params.append("status", selectedEmployeeStatuses.join(","));
        }
        const response = await axios.get(link, { params });
        setEmployees(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmployees();
  }, [link, selectedEmployeeStatuses, disableStatusFilter]);

  const onAddEmployee = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(link, data);
      const newEmployee = response.data;
      setEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
      toast({
        message: "Employee added",
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteEmployee = async (id: number) => {
    try {
      await axios.delete(`${link}/${id}`);
      if (!selectedEmployeeStatuses.includes("inactive")) {
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.id !== id)
        );
      }
      toast({
        message: "Employee deleted",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateEmployeeStatus = async (id: number, status: string) => {
    try {
      await axios.patch(`${link}/${id}/`, { status });
      setEmployees((prevEmployees) =>
        prevEmployees.map((employee) =>
          employee.id === id ? { ...employee, status } : employee
        )
      );
      toast({
        message: "Employee status updated",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddEmployee = async (data: any) => {
    await onAddEmployee(data);
    setIsModalOpen(false);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  const handleSelectChange = (event: any) => {
    const selectedValues = (event.target.value as string[]).map(
      (value: string) => value
    );
    setSelectedEmployeeStatuses(selectedValues);
  };

  const translatedEmployeeStatusOptions = EMPLOYEE_STATUS_FILTER_OPTIONS.map(
    (status) => ({
      value: status,
      label: t(`employeeList.status.${status}`),
    })
  );

  return (
    <div className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center">
        <Typography variant="h5" component="h2" gutterBottom>
          {t("employeeList.title")}
        </Typography>
        <div className="mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <span className="mr-2">+</span>
            {t("employeeList.addEmployee")}
          </Button>
        </div>{" "}
      </div>
      {!disableStatusFilter && (
        <TextField
          select
          label={t("employeeList.employeeStatus")}
          fullWidth
          sx={{ marginBottom: "1rem" }}
          variant="outlined"
          value={selectedEmployeeStatuses}
          SelectProps={{
            multiple: true,
            value: selectedEmployeeStatuses,
            onChange: handleSelectChange,
            renderValue: (selected: any) => (
              <div className="flex flex-wrap">
                {selected.map((value: any) => (
                  <span
                    key={value}
                    className="mr-2 bg-gray-200 dark:bg-gray-700 py-1 px-2 rounded"
                  >
                    {t(`employeeList.status.${value}`)}
                  </span>
                ))}
              </div>
            ),
          }}
        >
          {translatedEmployeeStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} dense>
              {t(`employeeList.status.${option.value}`)}
            </MenuItem>
          ))}
        </TextField>
      )}
      {employees.length > 0 ? (
        <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full">
          <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
            <th className="px-4 py-2 text-left" style={{ width: "45%" }}>
              {t("employeeList.tableHeader.name")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "45%" }}>
              {t("employeeList.tableHeader.email")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "10%" }}></th>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr
                key={employee.id}
                className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
              >
                <td className="px-1 py-3 text-left text-sm">
                  {employee.full_name}
                </td>
                <td className="px-1 py-3 text-left text-sm">
                  {employee.email}
                </td>
                <td className="px-1 py-3 text-left text-sm">
                  <div className="flex justify-center">
                    {!disableStatusFilter && (
                      <TextField
                        select
                        label={t("driversList.driverStatus")}
                        value={employee.status}
                        onChange={(e) =>
                          onUpdateEmployeeStatus(employee.id, e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {translatedEmployeeStatusOptions.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    <Link
                      to={`${link}/${employee.id}`}
                      className="flex items-center justify-center"
                    >
                      <IconButton>
                        <FaEdit />
                      </IconButton>
                    </Link>
                    <IconButton onClick={() => onDeleteEmployee(employee.id)}>
                      <FaTrash />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography variant="body1">{t("employeeList.noEmployees")}</Typography>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("employeeList.addEmployeeModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <AddEmployeeForm onSubmit={handleAddEmployee} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Employees;
