export const GRAPH_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
  "#387908",
  "#e74c3c",
  "#3498db",
  "#9b59b6",
  "#2ecc71",
  "#f1c40f",
  "#e67e22",
  "#16a085",
  "#d35400",
  "#27ae60",
  "#f39c12",
];