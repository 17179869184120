import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ParsedExpenseFormData } from "../../types/expenses";
import { getCurrencySymbol } from "../../utils/currencies";
import { format } from "date-fns-tz";

interface ParsedExpensesDialogProps {
  open: boolean;
  expenses: ParsedExpenseFormData[];
  loading: boolean;
  onClose: () => void;
  onConfirm: (selectedExpenses: ParsedExpenseFormData[]) => void;
}

const ParsedExpensesDialog: React.FC<ParsedExpensesDialogProps> = ({
  open,
  expenses,
  loading,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [selectedExpenses, setSelectedExpenses] = useState<any[]>([]);

  const [amountSelected, setAmountSelected] = useState<Record<string, number>>({
    eur: 0,
  });

  useEffect(() => {
    const newAmountSelected: Record<string, number> = {};
    expenses.forEach((expense) => {
      const netAmountNumber = parseFloat(expense.net_amount as unknown as string);
      if (newAmountSelected[expense.currency]) {
        newAmountSelected[expense.currency] += netAmountNumber;
      } else {
        newAmountSelected[expense.currency] = netAmountNumber;
      }
      newAmountSelected[expense.currency] = parseFloat(
        newAmountSelected[expense.currency].toFixed(2)
      );
    });
    setAmountSelected(newAmountSelected);
  }, [expenses]);

  useEffect(() => {
    setSelectedExpenses(expenses);
  }, [expenses]);

  const handleToggle = (expense: any) => () => {
    const currentIndex = selectedExpenses.indexOf(expense);
    const newChecked = [...selectedExpenses];

    if (currentIndex === -1) {
      newChecked.push(expense);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedExpenses(newChecked);
  };

  const handleConfirm = () => {
    onConfirm(selectedExpenses);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("expenseList.parsedExpensesDialog.title")}</DialogTitle>
      <DialogContent dividers>
        <div>
          <p>
            {" "}
            {t("expenseList.parsedExpensesDialog.totalSelected")}{" "}
            {selectedExpenses.length}
          </p>
          <p>
            {t("expenseList.parsedExpensesDialog.totalAmount")}{" "}
            {Object.keys(amountSelected).map((currency) => (
              <span key={currency}>
                {amountSelected[currency]} {getCurrencySymbol(currency)}
              </span>
            ))}
          </p>
        </div>
        <List>
          {expenses.map((expense, index) => (
            <ListItem
              key={index}
              onClick={handleToggle(expense)}
              sx={{ cursor: "pointer" }}
            >
              <Checkbox
                edge="start"
                checked={selectedExpenses.indexOf(expense) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText
                primary={`${t("expenseList.parsedExpensesDialog.truck")}:
                  ${expense.truck?.license_plate || "N/A"}, ${t(
                  "expenseList.parsedExpensesDialog.net"
                )}: ${expense.net_amount} ${getCurrencySymbol(
                  expense.currency
                )}, ${t("expenseList.parsedExpensesDialog.description")}: ${
                  expense.description
                }, ${t("expenseList.parsedExpensesDialog.country")}: ${t(
                  `countries.${expense.country}`
                )}
                }, ${t("expenseList.parsedExpensesDialog.vatRate")}: ${
                  expense.vat_rate
                }`}
                secondary={`${t(
                  "expenseList.parsedExpensesDialog.transactionDate"
                )}: ${format(new Date(expense.date), "yyyy-MM-dd HH:mm")}, ${t(
                  "expenseList.parsedExpensesDialog.category"
                )}: ${t(`expenseTypes.${expense.category}`)}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={loading}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParsedExpensesDialog;
