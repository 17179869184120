import {
  Autocomplete,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Assignee, OrderInfo } from "../../types/orders";
import { useStrider } from "../../contexts/StriderContext";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { hasAccess } from "../../utils/permissions";

interface OrderShipperProps {
  order: OrderInfo;
  loading: boolean;
  onFormSubmit: (data: any) => void;
}

const OrderShipper: React.FC<OrderShipperProps> = ({
  order,
  loading,
  onFormSubmit,
}) => {
  const { shippers } = useStrider();
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const { t } = useTranslation();
  const axios = useAxios();
  const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      shipper_id: order.shipper?.id || null,
      shipper_assignee_id: order.shipper_assignee?.id || null,
    },
  });

  const { account } = useAuth();
  useEffect(() => {
    const hasAccessForUpdate = hasAccess(account, "orders", "update") || ((order.created_by?.email === account?.email) && !!order.created_by);
    setHasAccessForUpdate(hasAccessForUpdate);

  }, [account, order]);


  const shipperId = watch("shipper_id");

  useEffect(() => {
    const fetchShipperDetail = async () => {
      if (shipperId) {
        const { data } = await axios.get(`/shippers/${shipperId}`);
        setAssignees(data.employees);
      } else {
        setAssignees([]);
      }
      if (order.shipper?.id !== shipperId) {
        setValue("shipper_assignee_id", null);
      }
    };
    fetchShipperDetail();
  }, [shipperId, shippers]);

  return (
    <div className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white flex items-center w-full justify-between">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="w-full max-w-md space-y-4"
      >
        <Controller
          name="shipper_id"
          control={control}
          render={({ field }) => {
            const selectedCarrier =
              shippers.find((shipper) => shipper.id === field.value) || null;
            return (
              <Autocomplete
                {...field}
                value={selectedCarrier}
                disabled={!hasAccessForUpdate}
                options={shippers}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  event.stopPropagation();
                  field.onChange(newValue?.id || null);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("orders.orderCreate.shipper")}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
            );
          }}
        />
        <Controller
          name="shipper_assignee_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.shipper.selectAssignee")}
              fullWidth
              variant="outlined"
              disabled={!hasAccessForUpdate}
            >
              {assignees.map((assignee) => (
                <MenuItem key={assignee.id} value={assignee.id}>
                  {assignee.first_name} {assignee.last_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {
          hasAccessForUpdate && (
            <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {t("common.save")}
          </Button>
          )
        }
      </form>

      <div className="w-1/2">
        <div className="flex items-center justify-between">
          <Typography variant="h5" component="h2" className="font-semibold">
            {t("orderDetails.shipper.title")}
          </Typography>
          <Link to={`/shippers/${order.shipper?.id}`}>
            <IconButton>
              <FaPencilAlt />
            </IconButton>
          </Link>
        </div>
        <Divider className="my-2" />
        <div className="grid grid-cols-1 gap-2">
          <Typography variant="body1">
            <strong className="font-semibold">
              {t("orderDetails.shipper.name")}:
            </strong>{" "}
            {order.shipper.name}
          </Typography>
          <Typography variant="body1">
            <strong className="font-semibold">
              {t("orderDetails.shipper.vatId")}:
            </strong>{" "}
            {order.shipper.vat_id}
          </Typography>
        </div>

        {order.shipper_assignee && (
          <>
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.shipper.assignee.title")}
            </Typography>
            <Divider className="my-2" />
            <div className="grid grid-cols-1 gap-2">
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.lastName")}:
                </strong>{" "}
                {order.shipper_assignee.last_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.firstName")}:
                </strong>{" "}
                {order.shipper_assignee.first_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.phone")}:
                </strong>{" "}
                {order.shipper_assignee.phone}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.email")}:
                </strong>{" "}
                {order.shipper_assignee.email}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderShipper;
