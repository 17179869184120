import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, Navigate, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { useToast } from "../../hooks/useToast";
import { DriverDetails, DriverDocumentFormData } from "../../types/driver";
import { hasAccess } from "../../utils/permissions";
import DriverDocuments from "../../components/Drivers/Documents/DriverDocuments";
import Payments from "./Payments/Payments";
import DriverBaseDetailsForm from "../../components/Drivers/DriverBaseDetailsForm";
import { useAuth } from "../../contexts/AuthContext";

const MyDriverDetailContainer = () => {
  const { t } = useTranslation();
  const { driverId } = useParams();
  const axios = useAxios();
  const [driver, setDriver] = useState<DriverDetails | null>(null);
  const toast = useToast();

  const { account } = useAuth();

  useEffect(() => {
    if (!driverId) return;

    axios
      .get(`/my-fleet/drivers/${driverId}`)
      .then((response) => {
        setDriver(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [driverId]);

  const onSubmit = (data: any) => {
    axios.patch(`/my-fleet/drivers/${driverId}/`, data).then(() => {
      toast({
        message: t("driverDetail.toast.success"),
        type: "success",
      });
    });
  };

  const onUpdateDocument = async (documentId: number, data: any) => {
    const response = await axios.patch(
      `/my-fleet/drivers/${driverId}/documents/${documentId}/`,
      data
    );
    setDriver((prevDriver) => {
      if (!prevDriver) return null;
      return {
        ...prevDriver,
        documents: prevDriver.documents.map((doc) =>
          doc.id === documentId ? response.data : doc
        ),
      };
    });
    toast({
      message: "Document updated",
      type: "success",
    });
  };
  const onAddDocument = async (document: DriverDocumentFormData) => {
    const response = await axios.post(
      `/my-fleet/drivers/${driverId}/documents/`,
      document
    );
    setDriver((prevDriver) => {
      if (!prevDriver) return null;
      return {
        ...prevDriver,
        documents: [...prevDriver.documents, response.data],
      };
    });
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    try {
      const response = await axios.delete(
        `/my-fleet/drivers/${driverId}/documents/${fileId}/`
      );
      if (response.status === 204) {
        setDriver((prevDriver) => {
          if (!prevDriver) return null;
          return {
            ...prevDriver,
            documents: prevDriver.documents.filter((doc) => doc.id !== fileId),
          };
        });
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onUpdateDriverPayment = async (data: any) => {
    const response = await axios.patch(`/my-fleet/drivers/${driverId}/`, {
      payment_payload: data,
    });
    setDriver((prevDriver) => {
      if (!prevDriver) return null;
      return {
        ...prevDriver,
        payments: response.data,
      };
    });
    toast({
      message: "Payment added",
      type: "success",
    });
  };

  const onAddDriverPayment = async (data: any) => {
    const response = await axios.post(
      `/my-fleet/drivers/${driverId}/payments/`,
      data
    );
    setDriver((prevDriver) => {
      if (!prevDriver) return null;

      return {
        ...prevDriver,
        payment_history: [...prevDriver.payment_history, response.data],
      };
    });
    toast({
      message: "Payment added",
      type: "success",
    });
  };

  const onDeleteDriverPayment = async (paymentId: number) => {
    try {
      const response = await axios.delete(
        `/my-fleet/drivers/${driverId}/payments/${paymentId}/`
      );
      if (response.status === 204) {
        setDriver((prevDriver) => {
          if (!prevDriver) return null;
          return {
            ...prevDriver,
            payment_history: prevDriver.payment_history.filter(
              (payment) => payment.id !== paymentId
            ),
          };
        });
        toast({
          message: "Payment deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  if (!account) return <Navigate to="/sign-in" />;

  return driver ? (
    <div className="p-4 text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/my-fleet">{t("breadcrumb.myFleet")}</Link>
        <Typography color="text.primary">
          {t("driverDetail.title")} - {driver.full_name}
        </Typography>
      </Breadcrumbs>
      <Tabs>
        <TabList>
          <Tab>{t("driverDetail.tabs.general")}</Tab>
          {hasAccess(account, "employee_documents", "read") && (
            <>
              <Tab>{t("driverDetail.tabs.documents")}</Tab>
            </>
          )}          
          {hasAccess(account, "employee_payroll", "read") && (
            <>
              <Tab>{t("driverDetail.tabs.payments")}</Tab>
            </>
          )}
        </TabList>

        <TabPanel>
          <DriverBaseDetailsForm driver={driver} onSubmit={onSubmit} />
        </TabPanel>
        {hasAccess(account, "employee_documents", "read") && (
          <>
            <TabPanel>
              <DriverDocuments
                driver={driver}
                onUpdateDocument={onUpdateDocument}
                onAddDocument={onAddDocument}
                onDeleteDocument={onDeleteDocument}
              />
            </TabPanel>
          </>
        )}
        {hasAccess(account, "employee_payroll", "read") && (
          <>
            <TabPanel>
              <Payments
                driver={driver}
                onUpdateDriverPayment={onUpdateDriverPayment}
                onAddDriverPayment={onAddDriverPayment}
                onDeleteDriverPayment={onDeleteDriverPayment}
              />
            </TabPanel>
          </>
        )}
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default MyDriverDetailContainer;
