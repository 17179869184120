import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import EmployeePaymentForm from "./EmployeePaymentForm";
import NoData from "../../../components/NoData";
import EmployeePaymentHistoryCreateForm from "./EmployeePaymentHistoryCreateForm";
import { useState } from "react";
import { FaTrash } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { EmployeeDetails } from "../../../types/employees";

interface PaymentsProps {
  employee: EmployeeDetails;
  onUpdateEmployeePayment: (data: any) => void;
  onAddEmployeePayment: (data: any) => void;
  onDeleteEmployeePayment: (paymentId: number) => void;
}

const EmployeePayments: React.FC<PaymentsProps> = ({
  employee,
  onUpdateEmployeePayment,
  onAddEmployeePayment,
  onDeleteEmployeePayment,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div>
      <Card>
        <CardContent>
          <EmployeePaymentForm
            employee={employee}
            onFormSubmit={onUpdateEmployeePayment}
          />
        </CardContent>
      </Card>
      <div className="mt-4 mb-4 w-full flex justify-end px-4">
        <Button onClick={() => setDialogOpen(true)} variant="contained">
          {t("employeeDetail.payments.addPaymentHistory")}
        </Button>
      </div>
      <TableContainer component={Paper}>
        {employee.payment_history.length > 0 ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("employeeDetail.payments.tableHeader.paymentDate")}
                </TableCell>
                <TableCell>
                  {t("employeeDetail.payments.tableHeader.netPay")}
                </TableCell>
                <TableCell>
                  {t("employeeDetail.payments.tableHeader.grossPay")}
                </TableCell>
                <TableCell>
                  {t("employeeDetail.payments.tableHeader.notes")}
                </TableCell>
                <TableCell>
                  {t("employeeDetail.payments.tableHeader.actions")}
                </TableCell>{" "}
              </TableRow>
            </TableHead>
            <TableBody>
              {employee.payment_history.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>{payment.payment_date}</TableCell>
                  <TableCell>{payment.net_pay}</TableCell>
                  <TableCell>{payment.gross_pay}</TableCell>
                  <TableCell>{payment.notes}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => onDeleteEmployeePayment(payment.id)}
                      color="secondary"
                      aria-label="delete"
                    >
                      <FaTrash />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoData />
        )}
      </TableContainer>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {t("employeeDetail.payments.addPaymentHistoryModal.title")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <EmployeePaymentHistoryCreateForm
            onSubmit={async (data: any) => {
              await onAddEmployeePayment(data);
              setDialogOpen(false);
            }}
            paymentInfo={employee.payment_info}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeePayments;
