import { useTranslation } from "react-i18next";
import successImage from "../../assets/logo_full.webp";

import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const RegisterFirstStepSuccessContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gradient-prime flex items-center justify-center">
      <div className="flex flex-col items-center justify-center bg-white p-8 rounded-3xl backdrop-filter backdrop-blur-lg bg-opacity-40">
        <img
          src={successImage}
          alt="Subscription Successful"
          className="w-1/4 mb-8 rounded-full"
        />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          {t("registerFirstStepSuccess.successful")}
        </h1>
        <p className="text-gray-600 text-lg mb-4">
          {t("registerFirstStepSuccess.thankYou")}
        </p>
        <Button onClick={() => navigate("/")} variant="contained">
          {t("registerFirstStepSuccess.goToSignIn")}
        </Button>
      </div>
    </div>
  );
};

export default RegisterFirstStepSuccessContainer;
