import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  CircularProgress,
  Box,
} from "@mui/material";
import { FaTruckFast } from "react-icons/fa6";
import { getCurrencySymbol } from "../../utils/currencies";
import CostsByCountry from "./CostsByCountry";
import { useTranslation } from "react-i18next";
import { DelegationStats as DelegationStatsType } from "../../types/delegations";

interface DelegationStatsProps {
  stats: DelegationStatsType;
}

const DelegationStats: React.FC<DelegationStatsProps> = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.mileageOverview")}
          </Typography>
          {stats.actual_mileage <
          stats.expected_min_mileage ? (
            <Typography variant="h3" component="div" sx={{ mt: 1 }}>
              {stats.actual_mileage} /{" "}
              {stats.expected_min_mileage} km
            </Typography>
          ) : (
            <Typography variant="h3" component="div" sx={{ mt: 1 }}>
              {stats.actual_mileage} /{" "}
              {stats.expected_max_mileage} km
            </Typography>
          )}
          <LinearProgress
            variant="determinate"
            value={
              stats.actual_mileage <
              stats.expected_min_mileage
                ? (stats.actual_mileage /
                    stats.expected_min_mileage) *
                  100
                : stats.actual_mileage >
                  stats.expected_max_mileage
                ? 100
                : (stats.actual_mileage /
                    stats.expected_max_mileage) *
                  100
            }
            sx={{ mt: 2, height: 10 }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {stats.actual_mileage <
            stats.expected_min_mileage ? (
              <>
                {" "}
                {t("delegation.remainingMileage")}:{" "}
                <span className={`font-extrabold text-xl text-red-500`}>
                  {(
                    stats.expected_min_mileage -
                    stats.actual_mileage
                  ).toFixed(2)}{" "}
                  km
                </span>
              </>
            ) : stats.actual_mileage <
              stats.expected_max_mileage ? (
              <>
                {" "}
                {t("delegation.remainingMileage")}:{" "}
                <span className={`font-extrabold text-xl text-red-500`}>
                  {(
                    stats.actual_mileage -
                    stats.expected_max_mileage
                  ).toFixed(2)}{" "}
                  km
                </span>
              </>
            ) : (
              <>
                {" "}
                {t("delegation.additionalMileage")}:{" "}
                <span className={`font-extrabold text-xl text-green-500`}>
                  {Math.abs(
                    stats.expected_max_mileage -
                      stats.actual_mileage
                  ).toFixed(2)}{" "}
                  km
                </span>
              </>
            )}
            (
            {(
              100 -
              (stats.actual_mileage /
                stats.expected_min_mileage) *
                100
            ).toFixed(2)}
            %)
          </Typography>
          <p className="text-gray-500">
            {t("delegation.plannedMileage")}: {stats.planned_mileage}{" "}
            km
          </p>

          <p className="text-gray-500">
            {t("delegation.expectedMileage")}:{" "}
            {stats.expected_min_mileage} -{" "}
            {stats.expected_max_mileage} km
          </p>
          <p className="text-gray-500">
            {" "}
            {t("delegation.mileagePerDay")}:{" "}
            {stats.actual_mileage_per_day} (
            {stats.planned_mileage_per_day}) km
          </p>
          <p className="text-gray-500">
            {t("delegation.expectedMileagePerDay")}:{" "}
            {stats.expected_min_mileage_per_day} -{" "}
            {stats.expected_max_mileage_per_day} km
          </p>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.revenueOverview")}
          </Typography>
          <Typography variant="h3" component="div" sx={{ mt: 1 }}>
            {stats.planned_revenue} /{" "}
            {stats.expected_revenue} {getCurrencySymbol("eur")}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              (stats.planned_revenue /
                stats.expected_revenue) *
              100
            }
            sx={{ mt: 2, height: 10 }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t("delegation.remainingRevenue")}:{" "}
            <span className="font-extrabold text-xl">
              {" "}
              {(
                stats.expected_revenue -
                stats.planned_revenue
              ).toFixed(2)}{" "}
              {getCurrencySymbol("eur")}
            </span>
            (
            {(
              100 -
              (stats.planned_revenue /
                stats.expected_revenue) *
                100
            ).toFixed(2)}
            %)
          </Typography>
          <p className="text-gray-500">
            {t("delegation.revenuePerKm")}:{" "}
            {stats.actual_revenue_per_km} (
            {stats.planned_revenue_per_km}){" "}
            {getCurrencySymbol("eur")}
          </p>
          <p className="text-gray-500">
            {t("delegation.revenuePerOrder")}:{" "}
            {stats.revenue_per_all_order} {getCurrencySymbol("eur")}
          </p>
          <p className="text-gray-500">
            {t("delegation.revenuePerDay")}:{" "}
            {stats.actual_revenue_per_day !==
            stats.planned_revenue_per_day ? (
              <>
                {stats.actual_revenue_per_day} -{" "}
                {stats.planned_revenue_per_day}{" "}
              </>
            ) : (
              stats.actual_revenue_per_day
            )}{" "}
            {getCurrencySymbol("eur")}
          </p>
          <p className="text-gray-500">
            {t("delegation.expectedRevenuePerDay")}:{" "}
            {stats.expected_revenue_per_day}{" "}
            {getCurrencySymbol("eur")}
          </p>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            {t("delegation.emptyFull")}
          </Typography>
          <div className="flex items-center gap-4">
            <div className="relative">
              <CircularProgress
                variant="determinate"
                value={
                  (stats.actual_full_kilometers /
                    stats.actual_mileage) *
                  100
                }
                size={150}
                thickness={5}
                sx={{ color: "#4caf50" }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography variant="h4" component="div" color="textSecondary">
                  {(
                    (stats.actual_full_kilometers /
                      stats.actual_mileage) *
                    100
                  ).toFixed(1)}{" "}
                  %
                </Typography>
                <FaTruckFast className="text-4xl text-green-500" />
              </Box>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col items-start justify-center">
                <p className="text-xl font-bold">
                  {" "}
                  {stats.actual_full_kilometers} (
                  {stats.planned_full_kilometers}) km
                </p>
                <p>{t("delegation.orderKilometers")}</p>
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="text-red-700 text-xl font-bold">
                  {stats.actual_empty_kilometers} km
                </p>
                <p>{t("delegation.emptyKilometers")}</p>
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="text-xl font-bold">
                  {stats.actual_mileage} (
                  {stats.planned_mileage}) km
                </p>
                <p>{t("delegation.actualMileage")}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.costsBreakdown")}
          </Typography>
          <div className="text-sm flex flex-col">
            <div className="grid grid-cols-4">
              <p className="font-semibold">{t("delegation.category")}</p>
              <p className="font-semibold">{t("delegation.estimated")}</p>
              <p className="font-semibold">{t("delegation.actualEstimated")}</p>
              <p className="text-sm">{t("delegation.actual")}</p>
              <p> {t("delegation.fuel")}:</p>
              <p>
                {stats.estimated_min_fuel_cost} -{" "}
                {stats.estimated_max_fuel_cost}
                {getCurrencySymbol("eur")}{" "}
              </p>
              <div>
                <p className="text-sm">
                  {stats.actual_estimated_fuel_cost}
                  {stats.routes.length > 0 && `(${stats.routes[0].summary.fuel_cost.toFixed(1)})`}
                  {getCurrencySymbol("eur")}{" "}
                </p>
              </div>
              <p>
                {stats.real_fuel_cost}
                {getCurrencySymbol("eur")}
              </p>
              <p className="text-sm">{t("delegation.toll")}:</p>
              <p>
                {stats.estimated_min_toll_cost} -{" "}
                {stats.estimated_max_toll_cost}
                {getCurrencySymbol("eur")}{" "}
              </p>
              <p>
                {stats.actual_estimated_toll_cost}{" "}
                {stats.routes.length && `(${stats.routes[0].summary.total_tolls.toFixed(1)})`}
                {getCurrencySymbol("eur")}{" "}
              </p>
              <p>
                {stats.real_toll_cost} {getCurrencySymbol("eur")}
              </p>
              <p className="text-sm">{t("delegation.driver")}:</p>
              <p>
                {stats.estimated_driver_cost}{" "}
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.actual_driver_cost}({" "}
                {stats.planned_driver_cost} ){" "}
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.real_driver_cost} {getCurrencySymbol("eur")}
              </p>
              <p>{t("delegation.lease")}:</p>
              <p>
                {stats.estimated_lease_cost}{" "}
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.actual_estimated_lease_cost} ({" "}
                {stats.planned_lease_cost} )
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.real_lease_cost} {getCurrencySymbol("eur")}
              </p>
              <p>{t("delegation.other")}:</p>
              <p>
                {stats.estimated_other_costs}{" "}
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.actual_estimated_other_costs} ({" "}
                {stats.planned_other_costs} )
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.real_other_costs} {getCurrencySymbol("eur")}
              </p>
              <p className="font-semibold">{t("delegation.total")}</p>
              <p className="font-semibold">
                {stats.estimated_total_costs}{" "}
                {getCurrencySymbol("eur")}
              </p>
              <p>
                {stats.actual_estimated_total_costs} ({" "}
                {stats.planned_total_costs} )
                {getCurrencySymbol("eur")}
              </p>
              <p className="font-semibold">
                {stats.real_total_costs} {getCurrencySymbol("eur")}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.profit")}:
          </Typography>
          <p className="text-gray-500">
            {t("delegation.actual")}: {stats.actual_estimated_profit}{" "}
            {getCurrencySymbol("eur")}
          </p>
          <p className="text-gray-500">
            {t("delegation.estimated")}: {stats.estimated_profit}{" "}
            {getCurrencySymbol("eur")}
          </p>
          <p className="text-gray-500">
            {t("delegation.expected")}: {stats.expected_profit}{" "}
            {getCurrencySymbol("eur")}
          </p>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.costPerKm")}:
          </Typography>
          <div className="grid grid-cols-2 gap-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.actualEstimatedFuelCostPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.actual_estimated_fuel_cost_per_km}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.actualEstimatedTollCostPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.actual_estimated_toll_cost_per_km}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedLeaseCostPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_lease_cost_per_km}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedOtherCostsPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_other_costs_per_km}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedDriverCostPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_driver_cost_per_km}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedTotalCostsPerKm")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_total_costs_per_km}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.costPerDay")}:
          </Typography>
          <div className="grid grid-cols-2 gap-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.actualEstimatedFuelCostPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.actual_estimated_fuel_cost_per_day}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.actualEstimatedTollCostPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.actual_estimated_toll_cost_per_day}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedLeaseCostPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_lease_cost_per_day}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedOtherCostsPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.real_other_costs_per_day}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedDriverCostPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_driver_cost_per_day}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold text-gray-600">
                {t("delegation.estimatedTotalCostsPerDay")}:
              </span>
              <span className="text-lg font-bold text-gray-800">
                {stats.estimated_total_costs_per_day}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("delegation.rates")}:
          </Typography>
          {stats.allowed_rate_per_km_table.map((rate, index) => (
            <div key={index} className="flex items-center gap-4">
              <p>{rate.kilometers} km</p>
              <p>
                {rate.rate} {getCurrencySymbol("eur")} / km
              </p>
            </div>
          ))}
        </CardContent>
      </Card>
      <div className="col-span-3">
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <div className="flex items-center gap-4">
              <div>
                <Typography variant="h5" component="div">
                  {t("delegation.fuelConsumptionFromTelematics")}:{" "}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {stats.actual_fuel_consumption}{" "}
                  <span className="text-sm">l</span>
                </Typography>
              </div>
              <div>
                <Typography variant="h5" component="div">
                  {t("delegation.refuelsTotal")}:{" "}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {stats.refuels.reduce(
                    (acc, refuel) => acc + refuel.fuel_add,
                    0
                  )}{" "}
                  <span className="text-sm">l</span>
                </Typography>
              </div>
              <div>
                <Typography variant="h5" component="div">
                  {t("delegation.fuelsFromCosts")}:{" "}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {stats.expenses.reduce(
                    (acc, expense) =>
                      acc +
                      (expense?.expense?.category === "fuel"
                        ? expense?.expense?.quantity || 0
                        : 0),
                    0
                  )}{" "}
                  <span className="text-sm">l</span>
                </Typography>
              </div>
            </div>
            <p className="text-gray-500">
              {(stats.actual_consumption_per_km * 100).toFixed(1)}{" "}
              <span className="text-sm">l/100km</span>
            </p>

            <CostsByCountry
              refuels={stats.refuels}
              costs={stats.expenses}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DelegationStats;
