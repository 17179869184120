import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo_text from "../../assets/logo_text.png";

import { useAuth } from "../../contexts/AuthContext";
import {
  Avatar,
  Snackbar,
  Alert,
  IconButton,
  Badge,
  Button,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { IoMdNotifications } from "react-icons/io";
import { CompanyBrowserAlert } from "../../types/alerts";
//import { useChat } from "../../contexts/ChatContext";
//import { HiChatAlt2 } from "react-icons/hi";

const TopNavBar: React.FC = () => {
  const { account, company, logout } = useAuth();
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alerts, setAlerts] = useState<CompanyBrowserAlert[]>([]);
  const axios = useAxios();
  //const { toggleChatDrawerOpen } = useChat();

  //const { notifications } = useChat();

  useEffect(() => {
    if (
      !company ||
      !company.subscription ||
      !company.subscription.is_in_trial ||
      !company.subscription.is_active
    ) {
      return;
    }
    axios.get("/alerts/").then((response) => {
      setAlerts(response.data);
    });
  }, [company]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const acknowledgeAlert = (id: number) => {
    axios.patch(`/alerts/${id}/`, { acknowledged: true }).then((response) => {
      setAlerts((prevAlerts) =>
        prevAlerts.filter((alert) => alert.id !== response.data.id)
      );
    });
  };

  const shouldShowAlertsAndNotifications =
    company &&
    company.subscription &&
    (company.subscription.is_active || company.subscription.is_in_trial);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full p-4 mx-8">
        <div className="flex items-center dark:bg-zinc-200 p-1 rounded-3xl">
          <img src={logo_text} alt="Xtrans Logo" className="h-8 w-40" />
        </div>
        <div className="flex items-center space-x-4 mr-64">
          {shouldShowAlertsAndNotifications && (
            <div className="relative">
              <IconButton onClick={() => setAlertOpen(true)}>
                <Badge
                  badgeContent={alerts.length}
                  color="error"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <IoMdNotifications
                    className={`${
                      alerts.length > 0
                        ? "text-red"
                        : "dark:text-secondary text-black"
                    }`}
                  />
                </Badge>
              </IconButton>
              <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ position: "absolute", top: "40px", right: "0" }}
              >
                <div className="w-96">
                  {alerts.length > 0 ? (
                    alerts.map((alert, index) => (
                      <Alert
                        key={index}
                        onClose={handleAlertClose}
                        severity="warning"
                        action={
                          <Button
                            color="inherit"
                            size="small"
                            onClick={() => acknowledgeAlert(alert.id)}
                          >
                            {t("navbar.acknowledge")}
                          </Button>
                        }
                      >
                        {t(`alerts.${alert.alert_type}`)}
                      </Alert>
                    ))
                  ) : (
                    <Alert severity="info">{t("navbar.noAlerts")}</Alert>
                  )}
                </div>
              </Snackbar>
              {/** <IconButton onClick={toggleChatDrawerOpen}>
                <Badge
                  badgeContent={notifications.length}
                  color="error"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <HiChatAlt2 className="dark:text-secondary text-black" />
                </Badge>
              </IconButton>*/}
            </div>
          )}
          <Avatar src={account?.preferences?.avatar} alt="my avatar" />
          <button
            onClick={() => logout()}
            className="bg-white text-black rounded-md py-1 px-4 flex items-center space-x-2"
          >
            <span className="font-bold text-md">{t("navbar.logout")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
