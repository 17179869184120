import React from "react";
import { Assignee } from "../../../types/orders";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AssigneeDetailsProps {
  assignee: Assignee;
}

const AssigneeDetails: React.FC<AssigneeDetailsProps> = ({ assignee }) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 border border-neutral-300 shadow-lg">
      <p className="text-xl font-bold">
        {t("trackingOrderDetails.shipperAssigneeDetails")}
      </p>{" "}
      <div className="w-full border-b border-neutral-400 my-4 opacity-70" />
      <Typography>
        {t("trackingOrderDetails.shipperAssigneeName")}: {assignee.first_name}{" "}
        {assignee.last_name}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.shipperAssigneeEmail")}: {assignee.email}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.shipperAssigneePhone")}: {assignee.phone}
      </Typography>
    </div>
  );
};

export default AssigneeDetails;
